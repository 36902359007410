import React, { useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../../components/Loader/index';
import { useNavigate } from 'react-router-dom';
import { PAYROLL_SETUP } from '../../navigation/Constant';
import OtpInput from '../../components/OtpInput/OtpInput';
import { AuthAPI } from '../../apis/authAPI';

const logo = require('../../assets/images/payweek.png');

const VerifyOtp = () => {
  const [loading, setLoading] = React.useState(false);
  const [employee, setEmployee] = React.useState<any>(
    localStorage.getItem('regsiteredUser')
      ? JSON.parse(localStorage.getItem('regsiteredUser') || '')
      : null
  );
  const [otpEmail, setOtpEmail] = React.useState('');

  const navigate = useNavigate();

  useEffect(() => {
    // Automatic verification when the OTP is fully entered
    if (otpEmail.length === 4) {
      handleVerify();
    }
  }, [otpEmail]);

  const handleVerify = async () => {
    try {
      setLoading(true);
      const data = await AuthAPI.postVerifyOtp({
        emp_id: employee?.emp_id,
        email_otp: Number(otpEmail),
      });

      if (data.success) {
        toast.success(data.message);
        localStorage.setItem('user', JSON.stringify(data.data.values));
        localStorage.setItem('token', data.data.token.access);
        localStorage.setItem('refreshToken', data.data.token.access);
        navigate(PAYROLL_SETUP);
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message || error?.message || 'something went wrong';
      console.log(errMsg);
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = () => {
    if (!otpEmail) {
      return toast.error('Please fill all the details');
    }

    // Call the same verification logic
    handleVerify();
  };

  return (
    <div className='bg-[#F4F4F496]'>
      <div className='min-h-[100vh] container py-[30px] mx-auto flex flex-col items-center'>
        <ToastContainer />
        {loading && <Loader />}

        <div className='flex w-[100%] items-start sm:items-center justify-center sm:justify-start sm:mb-[60px] mb-[25px]'>
          <img src={logo} alt='' className='sm:w-[180px] w-[140px]' />
        </div>

        <div className='flex flex-col gap-[10px] card w-[40%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#ffffff] py-[50px] px-[20px] rounded-[8px] mx-auto'>
          <h1 className='text-center text-[#5ABC4BE0] text-3xl font-bold'>VERIFY OTP</h1>
          <h3 className='text-center text-xl font-semibold mt-[30px]'>Enter Your OTP</h3>

          <div className='flex flex-col gap-[10px] w-[70%] mt-[30px] mx-auto'>
            <p className='text-semibold text-center text-[#000]/[0.5]'>OTP has been sent </p>

            <div className={'mt-[15px] flex items-center justify-center flex-col'}>
              <label className='text-[#333333] opacity-70 font-semibold text-[16px]'>
                Enter Email OTP
              </label>
              <OtpInput
                value={otpEmail}
                valueLength={4}
                onChange={(value) => setOtpEmail(value)}
              />
            </div>
          </div>

          <button
            className='bg-[#447EC1] text-[#fff] text-xl px-[60px] mx-auto font-semibold rounded-[12px] py-[8px] mt-[30px]'
            onClick={() => {
              handleLogin();
            }}
          >
            Verify
          </button>
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
