import React, { useEffect, useState } from "react";
import Input from "../Input/Input";
import { Select, Checkbox } from "antd"; // Import Checkbox from Ant Design
import { DashboardAPI } from "../../apis/dashboardApi";
import Loader from "../Loader";

interface bankInfoProps {
  data?: any;
  setData?: any;
  masterData?: any;
  formErrors?: any;
  setFormErrors?: any;
  edit?: boolean;
}

const BankInfo = ({
  data,
  setData,
  masterData,
  formErrors,
  setFormErrors,
  edit,
}: bankInfoProps) => {
  const [loading, setLoading] = React.useState(false);
  const [showDiv, setShowDiv] = useState(true);

  const getBankName = async (routing_no: any) => {
    try {
      setLoading(true);
      const bank = await DashboardAPI.postRoutingNumber({ routing_no });
      if (bank) {
        console.log("bank", bank);
        setData((prev: any) => ({
          ...prev,
          bank_name: bank?.data?.values?.bank_name || "",
        }));
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: { error: false, msg: `` } });
    setData({ ...data, [name]: value });
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="flex gap-[24px] mb-4">
        <div className="text-[#333333] opacity-70 font-semibold text-[14px]">
          <Checkbox checked={showDiv} onChange={(e) => setShowDiv(e.target.checked)}>
            Direct Deposit
          </Checkbox>
        </div>
        <div className="text-[#333333] opacity-70 font-semibold text-[14px]">
          <Checkbox checked={!showDiv} onChange={(e) => setShowDiv(!e.target.checked)}>
            Check Employee
          </Checkbox>
        </div>
      </div>
      
      {showDiv && (
        <div className="grid grid-cols-2 gap-[10px]">
          <div className="col-span-1">
            <Input
              type={"number"}
              name={"routing_no"}
              label={"Routing Number"}
              required
              placeholder={"Enter Routing Number (8 - 9 digit)"}
              handleChange={(e: any) => {
                if (e.target.value.length < 10) {
                  handleChange(e);
                }
              }}
              onBlur={(e: any) => {
                {
                  e.target.value?.length < 8
                    ? setFormErrors({
                        ...formErrors,
                        routing_no: {
                          error: true,
                          msg: `Routing number must be (8 - 9) digits`,
                        },
                      })
                    : setFormErrors({
                        ...formErrors,
                        routing_no: { error: false, msg: `` },
                      });
                }
                e.target.value
                  ? getBankName(e.target.value)
                  : console.log("no value");
              }}
              value={data?.routing_no}
              errTxt={formErrors?.routing_no?.msg}
            />
          </div>

          <div className="col-span-1 flex flex-col">
            <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
              Account type <span className="text-[red]">*</span>
            </label>
            <Select
              showSearch
              className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
              placeholder="Select"
              value={data?.account_type}
              options={masterData?.acc_type_list}
              onChange={(e) => {
                setFormErrors({
                  ...formErrors,
                  account_type: { error: false, msg: `` },
                });
                setData((prev: any) => ({ ...prev, account_type: e }));
              }}
            />
            {formErrors?.account_type?.msg && (
              <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                {formErrors?.account_type?.msg}
              </label>
            )}
          </div>

          <div className="col-span-1">
            <Input
              type={"text"}
              name={"bank_name"}
              label={"Bank Name"}
              required
              placeholder={"Enter Bank Name"}
              handleChange={handleChange}
              value={data?.bank_name}
              errTxt={formErrors?.bank_name?.msg}
            />
          </div>

          <div className="col-span-1">
            <Input
              type={"number"}
              name={"account_no"}
              label={"Account Number"}
              required
              placeholder={"Enter Account Number ( 3 - 11 digit)"}
              value={data?.account_no}
              handleChange={(e: any) => {
                if (e.target.value.length < 12) {
                  handleChange(e);
                }
              }}
              onBlur={(e: any) => {
                {
                  e.target.value?.length < 3
                    ? setFormErrors({
                        ...formErrors,
                        account_no: {
                          error: true,
                          msg: `Account number must be between (3-11) digits`,
                        },
                      })
                    : setFormErrors({
                        ...formErrors,
                        account_no: { error: false, msg: `` },
                      });
                }
              }}
              errTxt={formErrors?.account_no?.msg}
            />
          </div>

          {!edit && (
            <div className="col-span-1">
              <Input
                type={"number"}
                name={"confirm_account_no"}
                label={"Re-Enter Account Number"}
                required
                placeholder={"Enter Account Number ( 3 - 11 digit)"}
                handleChange={(e: any) => {
                  if (e.target.value.length < 12) {
                    handleChange(e);
                  }
                }}
                value={data?.confirm_account_no}
                onBlur={(e: any) => {
                  {
                    e.target.value?.length < 3
                      ? setFormErrors({
                          ...formErrors,
                          confirm_account_no: {
                            error: true,
                            msg: `Account number must be between (3-11) digits`,
                          },
                        })
                      : setFormErrors({
                          ...formErrors,
                          account_no: { error: false, msg: `` },
                        });
                  }
                }}
                errTxt={formErrors?.confirm_account_no?.msg}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BankInfo;
