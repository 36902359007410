import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  APPROVED_PAYROLL_DETAILS,
  PAYROLL,
} from "../../../navigation/Constant";
import { useOutletContext } from "react-router-dom";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { toast } from "react-toastify";

import moment from "moment";
import { log } from "console";

const ApprovedPayrolls = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { selectedCompany } = useOutletContext<any>();

  const [data, setData] = useState<any>([]);

  const getData = async () => {
    try {
      setLoading(true);

      const res = await DashboardAPI.getApprovedPayrollsList(selectedCompany);

      console.log(res);

      if (res.success) {
        
        console.log(res.data)
        const aaa= res.data?.emp_list?.sort((a:any,b:any)=>b.pay_id-a.pay_id)
        console.log(aaa);
        
        const arr:any = []
        for (const item of aaa){
          console.log(item);
          
          const obj = {
            pay_id: item?.pay_id,
            start_date: item?.start_date,
            end_date: item?.end_date,
            pay_date: item?.pay_date,
            pay_period:
             moment(item?.start_date, "DD-MM-YYYY").format("DD-MMM-YYYY") +
              " → " +
              moment(item?.end_date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
            payroll_cost: "$ " + Number(item?.payroll_cost)?.toFixed(2),
            payday: moment(item?.pay_date, "DD-MM-YYYY").format("DD-MMM-YYYY"),
          }
          arr.push(obj)
        }

        console.log('here');
        
        console.log('arr',arr);
        setData(arr);
      }
    } catch (err: any) {
      const errMsg = err?.data?.message || "Something went wrong!";

      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCompany) getData();
  }, [selectedCompany]);

  return (
    <div className="container mx-auto py-[20px]">
      <h2 className="font-semibold text-[var(--secondary)] text-[18px]">
        {" "}
        <span
          className="hover:underline cursor-pointer"
          onClick={() => navigate(PAYROLL)}
        >
          Payroll
        </span>{" "}
        / Approved
      </h2>
      <div className="grid grid-cols-4 mt-[30px] border-b-[2px] border-gray-400 py-[10px] px-[10px]">
        <div className="col-span-2">
          <h2 className="text-md  font-semibold">Pay Period</h2>
        </div>
        <div className="col-span-1">
          <h2 className="text-md  font-semibold">Pay Day</h2>
        </div>
        <div className="col-span-1">
          <h2 className="text-md text-right font-semibold">Payroll cost</h2>
        </div>
      </div>

      {data?.length > 0 ? (
        data?.map((item: any, ix: any) => {
          return <TableStructure data={item} key={ix} index={ix} />;
        })
      ) : (
        <p className="py-[60px] text-center font-semibold text-gray-400">
          No Data Availbale
        </p>
      )}
    </div>
  );
};

const TableStructure = ({ data, index }: any) => {
  const navigate = useNavigate();
  return (
    <div className="grid grid-cols-4 border-b-[1px] py-[10px] px-[10px]">
      <div className="col-span-2">
        <a
          onClick={() =>
            navigate("/dashboard/runpayroll/approved/" + data?.pay_id, {
              state: { data,index },
            })
          }
          className="text-sm  font-semibold text-blue-700 hover:underline cursor-pointer"
        >
          {data?.pay_period}
        </a>
      </div>
      <div className="col-span-1">
        <p className="text-sm  font-[500] text-gray-600">{data?.payday}</p>
      </div>
      <div className="col-span-1">
        <p className="text-sm  font-[500] text-gray-600 text-right">
          {data?.payroll_cost}
        </p>
      </div>
    </div>
  );
};

export default ApprovedPayrolls;
