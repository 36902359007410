import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import Input from "../../../components/Input/Input";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader/index";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ADD_EMPLOYEE, LOGIN, PAYROLL_SETUP, VERIFY_OTP } from "../../../navigation/Constant";
import { EMPLOYEE } from "../../../navigation/Constant";
import Sel from "../../../components/Select/Select";

import { Select } from "antd";
import { CommonApi } from "../../../apis/commonApi";
import ImageUpload from "../../../components/ImageUpload/ImageUpload";

import { Radio } from "antd";
import { parse } from "path";

import moment from "moment";

const initialErrorState = {
  error: false,
  msg: "",
};

const initialData = {
  company_name: "",
  country: "",
  state: "",
  city: "",
  zip_code: "",
  business_currency: "",
  org_type: "",
  business_type: "",
  address: "",
  fei_no: "",
  uia_no: "",
  company_logo: "",
  company_type: "",
  company_website: "",
  payroll_freq: "",
  period_start: "",
  payday: "",
  fedral_dependent_credit: 0,
  fedral_other_dependent_credit: 0,
  percentage_credit: 0,
  medicare_tax: 0,
  social_security_tax: 0,
};

const EditCompany = () => {
  const [master, setMaster] = React.useState<any>({});
  const [submit, setSubmit] = React.useState(false)
  const [city, setCity] = React.useState<any>([]);
  const [state, setState] = React.useState<any>([]);

  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState<any>(initialData);
  const [data, setData] = useState<any>({}); // for edit

  const [startDateEdit, setStartDateEdit] = React.useState<any>(false);

  const [paySchedule, setPaySchedule] = React.useState<any>({
    start_date: "",
    end_date: "",
    pay_date: "",
  });
  const [startDay, setStartDay] = useState(0)
  const [showSelect, setSelect] = useState(true);

  const dayArraySelect = [
    {
      value: "Sunday",
      label: "Sunday"
    },
    {
      value: "Monday",
      label: "Monday"
    },
    {
      value: "Tuesday",
      label: "Tuesday"
    },
    {
      value: "Wednesday",
      label: "Wednesday"
    },
    {
      value: "Thursday",
      label: "Thursday"
    },
    {
      value: "Friday",
      label: "Friday"
    },
    {
      value: "Saturday",
      label: "Saturday"
    },
  ]

  const [week, setWeek] = useState<any>({});
  const [weekUtc, setWeekUtc] = useState<any>({});

  const nextWeek = () => {
    if (startDay < 0 || startDay > 6) {
      console.error('Invalid start day. Please provide a value between 0 (Sunday) and 6 (Saturday).');
      return;
    }
  
    var curr = new Date(week.lastday);
    var newDate = new Date(curr.getTime() + 24 * 60 * 60 * 1000);
  
    // Calculate the next week's starting day
    var diff = startDay - newDate.getDay();
    diff = diff < 0 ? 7 + diff : diff;
  
    var first = newDate.getDate() + diff;
    var last = first + 6;
  
    var firstday = new Date(newDate.setDate(first));
  
    const millisecondsInDay = 24 * 60 * 60 * 1000;
  
    // Calculate the time for the nth previous date
    const targetTime = firstday.getTime() + 6 * millisecondsInDay;
  
    // Create a new Date object with the target time
    const lastday = new Date(targetTime);
  
    var firstdayUtc = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      first
    ).toUTCString();
    var lastdayUtc = new Date(
      newDate.getFullYear(),
      newDate.getMonth(),
      last
    ).toUTCString();
  
    console.log(firstday, lastday);
  
    setWeek({
      firstday: firstday.toISOString().substring(0, 10),
      lastday: lastday.toISOString().substring(0, 10),
    });
    setWeekUtc({ firstday: firstdayUtc, lastday: lastdayUtc });
  };

  const [formErrors, setFormErrors] = useState<any>(initialErrorState);

  const params = useParams();

  const navigate = useNavigate();

  const handlecomp = async () => {
    if (!formData.company_name) {
      return toast.error("Company Name is required");
    }

    if (validateSubmit()) {
      return toast.error("Please fill all the details correctly");
    }

    const obj = { ...formData };

    for (let key in data) {
      if (obj[key] == null) {
        delete obj[key];
      } else if (data[key] == obj[key]) {
        delete obj[key];
      }
    }

    const newFormData = new FormData();

    for (const key in obj) {
      newFormData.append(key, formData[key as keyof typeof formData]);
    }

    try {
      setLoading(true);
      const data = await DashboardAPI.updateCompanyById(
        params?.id,
        newFormData
      );
      
      if (data.success) {
        toast.success("Company updated successfully");
        setTimeout(() => {
          navigate(PAYROLL_SETUP);
        }, 1000);
        localStorage.setItem(
          "registeredCompany",
          JSON.stringify(data.data.values)
        );
      }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        // autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }

  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const [formErr, setFormErr] = useState({
    company_name: initialErrorState,
    address: initialErrorState,
    country: initialErrorState,
    state: initialErrorState,
    city: initialErrorState,
    zip_code: initialErrorState,
    business_currency: initialErrorState,
    org_type: initialErrorState,
    company_type: initialErrorState,
    uia_no : initialErrorState
  });

  const validate = (e: any) => {
    const { name, value } = e.target;

    if (name === "company_name") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          company_name: {
            error: true,
            msg: "Please enter company name",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          company_name: initialErrorState,
        }));
      }
    } else if (name === "address") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          address: {
            error: true,
            msg: "Please enter address",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, address: initialErrorState }));
      }
    } else if (name === "country") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          country: {
            error: true,
            msg: "Please enter country",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, country: initialErrorState }));
      }
    } else if (name === "state") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          state: {
            error: true,
            msg: "Please enter  state",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, state: initialErrorState }));
      }
    } else if (name === "city") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          city: {
            error: true,
            msg: "Please enter city",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, city: initialErrorState }));
      }
    } else if (name === "zip_code") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          zip_code: {
            error: true,
            msg: "Please enter zipcode",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, zip_code: initialErrorState }));
      }
    } else if (name === "business_currency") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          business_currency: {
            error: true,
            msg: "Please enter business currency",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          business_currency: initialErrorState,
        }));
      }
    } else if (name === "org_type") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          type_of_org: {
            error: true,
            msg: "Please enter type of organisation",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          type_of_org: initialErrorState,
        }));
      }
    } else if (name === "company_type") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          company_type: {
            error: true,
            msg: "Please enter type of business",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          company_type: initialErrorState,
        }));
      }
    } else if (name === "uia_no") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          uia_no: {
            error: true,
            msg: "Please enter Uia no",
          },
        }));
      } else if (value?.length < 10) {
        setFormErr((prev: any) => ({
          ...prev,
          uia_no: {
            error: true,
            msg: "Uia no must be 10 digits",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          uia_no: initialErrorState,
        }));
      }
    }
  };

  const validateSubmit = () => {
    if (
      formErr.company_name.error ||
      formErr.address.error ||
      formErr.city.error ||
      formErr.state.error ||
      formErr.zip_code.error ||
      formErr.country.error ||
      formErr.business_currency.error ||
      formErr.uia_no.error ||
      formErr.org_type.error ||
      formErr.company_type.error 
    ) {
      return true;
    }
  };

  const getCityList = async (state: any) => {
    try {
      setLoading(true);
      const dataum = await CommonApi.getCityList({ state: formData?.state });
      if (dataum.success) {
        setCity(
          dataum?.data?.city_list.map((item: any) => ({
            label: item,
            value: item,
          }))
        );
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const getStateList = async () => {
    try {
      setLoading(true);
      const dataum = await CommonApi.getStateList();
      if (dataum.success) {
        setState(
          dataum?.data?.state_list.map((item: any) => ({
            label: item,
            value: item,
          }))
        );
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const getDetails = async () => {
    try {
      setLoading(true);
      const dataum = await DashboardAPI.getDetails();
      if (dataum.success) {
        setMaster(dataum.data.form_details);
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const getCompanyData = async () => {
    try {
      setLoading(true);
      const dataum = await DashboardAPI.getCompanyById(params?.id);
      setData(dataum?.data?.values);
      setFormData(dataum?.data?.values);
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getCompanyData();
    }
  }, [params]);

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (formData?.state) {
      getCityList(formData?.state);
    }
  }, [formData?.state]);

  const [editImg, setEditImg] = useState(false);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (
      formData?.payroll_freq == "every week" ||
      formData?.payroll_freq == "every other week"
    ) {
      var currentDate = new Date();

      // Get the current day of the week (0-6, where 0 is Sunday)
      var currentDayOfWeek = currentDate.getDay();

      // Calculate the number of days to subtract to get to the first day of the week
      var daysToSubtract = (currentDayOfWeek + 6) % 7;

      // Subtract the days from the current date to get the first day of the week
      var firstDayOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - daysToSubtract
      )?.toISOString();

      var lastDayOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - daysToSubtract + 6
      )?.toISOString();

      var lasDaySecondWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - daysToSubtract + 13
      )?.toISOString();

      setPaySchedule({
        start_date: firstDayOfWeek,
        end_date:
          formData?.payroll_freq == "every week"
            ? lastDayOfWeek
            : lasDaySecondWeek,
        pay_date:
          formData?.payroll_freq == "every week"
            ? lastDayOfWeek
            : lasDaySecondWeek,
      });
    } else if (formData?.payroll_freq == "twice a month" || "once a month") {
      var currentDate = new Date();

      // Set the date to the first day of the month

      var firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        2
      )?.toISOString();
      var halfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        16
      )?.toISOString();

      var lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      )?.toISOString();

      setPaySchedule({
        start_date: firstDayOfMonth,
        end_date:
          formData?.payroll_freq == "twice a month"
            ? halfMonth
            : lastDayOfMonth,
        pay_date:
          formData?.payroll_freq == "twice a month"
            ? halfMonth
            : lastDayOfMonth,
      });
    }

    if (paySchedule?.start_date && paySchedule?.end_date && formData?.payday) {
      const endDate = new Date(paySchedule?.end_date);
      const payDate = new Date(
        endDate.getFullYear(),
        endDate.getMonth(),
        Number(endDate.getDate()) + Number(formData?.payday)
      )?.toISOString();

      setPaySchedule({
        ...paySchedule,
        pay_date: payDate,
      });
    }
  }, [formData?.payroll_freq, formData?.period_start, formData?.payday]);

  const setDate = (startDate: any) => {
    // if (
    //   formData?.payroll_freq == "every week" ||
    //   formData?.payroll_freq == "every other week"
    // ) {
    //   const endDateWeek = moment(startDate).add(6, "days").format("YYYY-MM-DD");
    //   const endDateTwoWeek = moment(startDate)
    //     .add(12, "days")
    //     .format("YYYY-MM-DD");

    //   setPaySchedule({
    //     ...paySchedule,
    //     start_date: startDate,
    //     end_date:
    //       formData?.payroll_freq == "every week" ? endDateWeek : endDateTwoWeek,
    //     pay_date:
    //       formData?.payroll_freq == "every week"
    //         ? moment(endDateWeek)
    //             .add(formData?.payday, "days")
    //             .format("YYYY-MM-DD")
    //         : endDateTwoWeek,
    //   });
    // } else if (formData?.payroll_freq == "twice a month" || "once a month") {
    //   const endDateMonth = moment(startDate)
    //     .endOf("month")
    //     .format("YYYY-MM-DD");
    //   const endDateHalfMonth = moment(startDate)
    //     .add(14, "days")
    //     .format("YYYY-MM-DD");

    //   setPaySchedule({
    //     ...paySchedule,
    //     start_date: startDate,
    //     end_date:
    //       formData?.payroll_freq == "twice a month"
    //         ? endDateHalfMonth
    //         : endDateMonth,
    //     pay_date:
    //       formData?.payroll_freq == "twice a month"
    //         ? endDateHalfMonth
    //         : endDateMonth,
    //   });
    // }

    setPaySchedule({
      ...paySchedule,
      start_date: startDate,
    });


    setStartDateEdit(false);
  };

  return (
    <div className="container mx-auto pt-[15px] pb-[200px]">
      <ToastContainer />
      {loading && <Loader />}
      <h2 className="font-semibold text-[var(--secondary)]">Business / Edit</h2>

      <div className="steps mt-[20px] hidden md:flex flex-row justify-start gap-[20px] items-center">
        <div
          className={
            " flex items-center gap-[10px] cursor-pointer justify-center px-[10px] py-[5px] "
          }
          onClick={() => setStep(1)}
        >
          <p
            className={
              "font-semibold m-0" +
              (step == 1
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Basic Info
          </p>
        </div>
        {/* <div
          className={
            "  rounded-[15px] flex cursor-pointer items-center gap-[10px] justify-center px-[10px] py-[5px] "
          }
          onClick={() => setStep(2)}
        >
          <p
            className={
              "font-semibold m-0" +
              (step == 2
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Pay Schedule
          </p>
        </div> */}

        <div
          className={
            "  rounded-[15px] flex cursor-pointer items-center gap-[10px] justify-center px-[10px] py-[5px] "
          }
          onClick={() => setStep(3)}
        >
          <p
            className={
              "font-semibold m-0" +
              (step == 3
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Fixed Taxes
          </p>
        </div>
      </div>

      {/* BASIC INFO SECTION START */}

      {step == 1 && (
        <div>
          <div className="grid grid-cols-2 gap-[10px] mt-[30px] w-[100%]">
            <div className="col-span-2">
              <div className="grid grid-cols-3 gap-[20px]">
                <div className="col-span-2 flex flex-col gap-[10px]">
                  <div className="col-span-1">
                    <Input
                      required
                      type={"text"}
                      name={"company_name"}
                      label={"Company Name"}
                      placeholder={"Enter Company Name"}
                      handleChange={handleChange}
                      value={formData?.company_name}
                      onBlur={validate}
                    />

                  </div>
                  <div className="col-span-1 flex flex-col">
                    <label
                      className="text-[#333333] opacity-70 font-semibold text-[14px]">
                      Type of Organization<span className="text-[red]">*</span>
                    </label>
                    <Select
                      showSearch
                      className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                      placeholder="Search to Select"
                      options={master?.organisation_type}
                      value={formData?.org_type}
                      onChange={(e) => {
                        setFormData((prev: any) => ({ ...prev, org_type: e }));
                      }}
                    />
                  </div>

                  <div className="col-span-1 flex flex-col">
                    <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                      Type of Business<span className="text-[red]">*</span>
                    </label>
                    <Select
                      showSearch
                      className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                      placeholder="Search to Select"
                      value={formData?.business_type}
                      options={master?.business_type}
                      onChange={(e) => {
                        setFormData((prev: any) => ({
                          ...prev,
                          business_type: e,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="cols-span-1">
                  <div className="col-span-2 flex flex-col gap-[10px]">
                    <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                      Logo
                    </label>
                    {!editImg ? (
                      <>
                        <img
                          src={
                            formData?.photo_url ||
                            "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=1480&t=st=1682599573~exp=1682600173~hmac=70167dd96663e16bc7d1b9ea915c69f452455a2db0362cb2b6eff25ae440acbd"
                          }
                          alt="profile"
                          className="w-[160px] border-[1px] rounded-[8px] h-[160px] object-cover"
                        />
                        <p
                          className="text-[12px] w-[fit-content] hover:underline cursor-pointer "
                          onClick={() => setEditImg(true)}
                        >
                          Edit
                        </p>
                      </>
                    ) : (
                      <>
                        <ImageUpload
                          setData={setFormData}
                          data={formData}
                          key={"photo"}
                        />
                        <p
                          className="text-[12px] w-[fit-content] hover:underline cursor-pointer "
                          onClick={() => setEditImg(false)}
                        >
                          Cancel
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <Input
                required
                type={"text"}
                name={"address"}
                label={"Street Address"}
                placeholder={"Enter Street Address"}
                value={formData?.address}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1 flex flex-col">
              <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                Country<span className="text-[red]">*</span>
              </label>
              <Select
                showSearch
                className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                placeholder="Search to Select"
                options={[
                  {
                    label: "US",
                    value: "US",
                  },
                ]}
                onChange={(e) => {
                  setFormData((prev: any) => ({ ...prev, country: e }));
                }}
                value={formData?.country}
                defaultValue={formData?.country}
              />
            </div>

            <div className="col-span-1 flex flex-col">
              <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                State<span className="text-[red]">*</span>
              </label>
              <Select
                showSearch
                className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                placeholder="Search to Select"
                options={state}
                onChange={(e) => {
                  setFormData((prev: any) => ({ ...prev, state: e }));
                }}
                value={formData?.state}
              />
            </div>
            <div className="col-span-1 flex flex-col">
              <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                City<span className="text-[red]">*</span>
              </label>
              <Select
                showSearch
                className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                placeholder="Search to Select"
                options={city}
                onChange={(e) => {
                  setFormData((prev: any) => ({ ...prev, city: e }));
                }}
                value={formData?.city}
              />
            </div>

            <div className="col-span-1">
              <Input
                required
                type={"number"}
                name={"zip_code"}
                label={"Zipcode"}
                placeholder={"Enter Zipcode"}
                value={formData?.zip_code}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1 flex flex-col">
              <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                Business Currency<span className="text-[red]">*</span>
              </label>
              <Select
                showSearch
                className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                placeholder="Search to Select"
                value={formData?.business_currency}
                options={[
                  {
                    label: "USD",
                    value: "US Doller",
                  },
                ]}
                onChange={(e) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    business_currency: e,
                  }));
                }}
              />
            </div>

            <div className="col-span-1">
              <Input
                required
                type={"number"}
                name={"fei_no"}
                label={"FEI Number"}
                placeholder={"Enter FEI Number"}
                value={formData?.fei_no}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                required
                type={"number"}
                name={"uia_no"}
                label={"UIA Employer Number"}
                placeholder={"Enter UIA Employer Number"}
                value={formData?.uia_no}
                handleChange={(e: any) => {
                  e.target.value?.length <= 10 && handleChange(e);
                }}
                onBlur={validate}
                errTxt={formErr?.uia_no?.msg}
              />
            </div>
          </div>

          <div className="relative ">
            <div className="footer bg-[#e5e5e5] border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md  right-[0px] ">
              <div className="flex justify-between gap-[10px] p-[10px]">
                {/* <button className="  text-black rounded-[8px] px-[20px] py-[10px]" onClick={(e) => {
                                // setStep(step - 1)
                            }}>Back</button> */}

                <button
                  className="bg-[var(--primary)] ml-auto text-[#fff] rounded-[8px] px-[20px] py-[10px]"
                  // onClick={(e) => {
                  //     setStep(step + 1)
                  // }}
                  onClick={() => {
                    handlecomp();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* BASIC INFO SECTION END */}

      {/* PAY SCHEDULE SECTION START */}

      {/* {step === 2 && (
        <div>
          <div className="grid grid-cols-2 gap-[10px] mt-[30px] w-[100%]">
            <div className="col-span-2 flex gap-[15px] flex-col">
              <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                Choose Payroll Frequency
              </label>
              <Radio.Group
                value={formData?.payroll_freq}
                onChange={(e) => {
                  setFormData((prev: any) => ({
                    ...prev,
                    payroll_freq: e.target.value,
                  }));

                  if (e.target.value === "every week" || e.target.value === "every other week") {
                    setSelect(true);
                  } else {
                    setSelect(false);
                  }
                }}
              >
                <Radio value={"every week"}>Every Week</Radio>
                <Radio value={"every other week"}>Every Other Week</Radio>
                <Radio value={"twice a month"}>Twice a Month</Radio>
                <Radio value={"once a month"}>Once a Month</Radio>
              </Radio.Group>
            </div>
            {showSelect ? (
              <div className="py-5">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Day of the week your payroll period starts
                </label>
                <Sel
                  options={dayArraySelect}
                  handleChange={(e: any) => {
                    const ind = dayArraySelect.findIndex(s => s.value == e.target.value)
                    console.log(ind);
                    setStartDay(ind)
                  }}
                  value={dayArraySelect[startDay].value}
                />
              </div>
            ) : null}
            <div className="col-span-2 flex gap-[15px] flex-col my-[10px]">
              <p className="flex items-center text-[#333333] opacity-70 font-semibold text-[14px] ">
                Default Payday to
                <div className="flex flex-col mx-[10px]">
                  <input
                    value={formData?.payday}
                    className="border-[1px] w-[50px]"
                    type="number"
                    onChange={(e) => {
                      setFormData((prev: any) => ({
                        ...prev,
                        payday: e.target.value,
                      }));
                    }}
                  />
                  <span className="text-[#000]/[0.5] text-[10px] mb-[-15px]">
                    31 days Max
                  </span>
                </div>
                Days after payroll period ends
              </p>
            </div>

            <div className="col-span-1"></div>

            <hr className="col-span-2 my-[20px] h-[2px] bg-[#000]/[0.3]" />

            <div className="col-span-2 flex gap-[15px] flex-col my-[10px]">
              <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                Your Payroll Schedule will look based on the settings above
              </label>
              <div className="grid grid-cols-4 gap-[10px]">
                <div className="col-span-1">

                  {!startDateEdit ? (
                    <div className="rounded-[4px] border-[1px]">
                      <div className="bg-green-500 py-[5px] text-center">
                        <h2 className="tetx-sm text-white">Start Date</h2>
                      </div>
                      <div className="py-[20px] text-center">
                        <h2 className="whitespace-wrap">
                          {moment(paySchedule?.start_date).format(
                            "ddd, DD MMM YYYY"
                          )}
                        </h2>
                      </div>
                      <div className="flex w-[100%] text-center justify-center">
                        <a
                          href={"javascript:void(0)"}
                          onClick={() => {
                            setStartDateEdit(true);
                          }}
                          className="hover:undeline text-center cursor-pointer text-blue-500"
                        >
                          Edit
                        </a>
                      </div>
                    </div>
                  ) : (
                    <Input
                      type="date"
                      name={"start_date"}
                      label={"Start Date"}
                      handleChange={(e: any) => {
                        setDate(e.target.value);
                      }}
                      value={paySchedule?.start_date?.split("T")[0] || ""}
                      errTxt={formErrors?.start_date?.msg}
                    />
                  )}
                </div>

                <div className="col-span-1">
                  <div className="rounded-[4px] border-[1px]  h-[100%]">
                    <div className="bg-blue-500 py-[5px] text-center">
                      <h2 className="tetx-sm text-white">End Date</h2>
                    </div>
                    <div className="py-[20px] text-center">
                      <h2 className="whitespace-wrap">
                        {moment(paySchedule?.end_date).format("ddd, DD MMM YYYY")}
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="col-span-1">
                  <div className="rounded-[4px] border-[1px] h-[100%]">
                    <div className="bg-blue-500 py-[5px] text-center">
                      <h2 className="tetx-sm text-white">Pay Day</h2>
                    </div>
                    <div className="py-[20px] text-center">
                      <h2 className="whitespace-wrap">
                        {moment(paySchedule?.pay_date).format("ddd, DD MMM YYYY")}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="relative ">
            <div className="footer bg-[#e5e5e5] border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md  right-[0px] ">
              <div className="flex justify-between gap-[10px] p-[10px]">
              

                <button
                  className="bg-[var(--primary)] ml-auto text-[#fff] rounded-[8px] px-[20px] py-[10px]"
                  onClick={() => {
                    handlecomp();
                  }}
                >
                  Submit

                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {step === 3 && (
        <div>
          {/* <div className="grid grid-cols-2 gap-[10px] mt-[30px] w-[100%]">
            <div className="col-span-1">
              <Input
                type={"number"}
                name={"fedral_dependent_credit"}
                label={"Fedral dependent credit  ( $ ) "}
                placeholder={"Fedral dependent credit  ( $ ) "}
                value={parseFloat(formData?.fedral_dependent_credit)?.toFixed(
                  4
                )}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"fedral_other_dependent_credit"}
                label={"Fedral other dependent credit ( $ )"}
                placeholder={"Fedral other dependent credit ( $ )"}
                value={parseFloat(
                  formData?.fedral_other_dependent_credit
                )?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"percentage_credit"}
                label={"Percentage credit (%)"}
                placeholder={"Enter Percentage credit (%)"}
                value={parseFloat(formData?.percentage_credit)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"medicare_tax"}
                label={"Medicare tax (%)"}
                placeholder={"Enter Medicare tax (%)"}
                value={parseFloat(formData?.medicare_tax)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"social_security_tax"}
                label={"Social security tax (%)"}
                placeholder={"Enter Social security tax (%)"}
                value={parseFloat(formData?.social_security_tax)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"suta_limit"}
                label={"SUTA limit (state unemployment limit)"}
                placeholder={"Enter SUTA limit ( $ )"}
                value={parseFloat(formData?.suta_limit)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"futa_limit"}
                label={"FUTA limit (Federal unemployment limit)"}
                placeholder={"Enter FUTA limit ( $ )"}
                value={parseFloat(formData?.futa_limit)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"suta_percent"}
                label={"SUTA percent (state unemployment percent)"}
                placeholder={"Enter SUTA percent ( % )"}
                value={parseFloat(formData?.suta_percent)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"futa_percent"}
                label={"FUTA percent (Federal unemployment percent)"}
                placeholder={"Enter FUTA percent ( % )"}
                value={0.0060?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
                disabled
              />
            </div>
          </div> */}
          <hr className="bg-gray my-[20px]" />

          <div className="grid grid-cols-2 gap-[10px] mt-[30px] w-[100%]">
            <h2 className="text-md col-span-2 font-semibold text-[#333333] opacity-70">
              Quaterly Taxes
            </h2>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"oa_rate"}
                label={"Obligation Assessment (OA) Rate"}
                placeholder={"Obligation Assessment (OA) Rate"}
                value={parseFloat(formData?.oa_rate)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>

            <div className="col-span-1">
              <Input
                type={"number"}
                name={"ui_rate"}
                label={"UI Tax Rate"}
                placeholder={"UI Tax Rate"}
                value={parseFloat(formData?.ui_rate)?.toFixed(4)}
                handleChange={handleChange}
                onBlur={validate}
              />
            </div>
          </div>

          <div className="relative ">
            <div className="footer bg-[#e5e5e5] border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md  right-[0px] ">
              <div className="flex justify-between gap-[10px] p-[10px]">
                {/* <button className="  text-black rounded-[8px] px-[20px] py-[10px]" onClick={(e) => {
                                // setStep(step - 1)
                            }}>Back</button> */}

                <button
                  className="bg-[var(--primary)] ml-auto text-[#fff] rounded-[8px] px-[20px] py-[10px]"
                  // onClick={(e) => {
                  //     setStep(step + 1)
                  // }}
                  onClick={() => {
                    handlecomp();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* PAY SCHEDULE SECTION END */}
    </div>
  );
};

export default EditCompany;
