import React, { useEffect, useRef, useState } from "react";
import { Checkbox, Table } from "antd";
import type { TableRowSelection } from "antd/lib/table/interface";
import { useReactToPrint } from 'react-to-print';
import { useLocation, useOutletContext } from "react-router-dom";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { saveAs } from 'file-saver';
import { BASE_URL } from "../../../constants/apiConstant";



type Props = {
    company: any
};

const PrintSlip = (props: Props) => {
    const location = useLocation()
    const companydata = location.state
    console.log(companydata)

    const [data, setData] = useState<any>([])
    const { selectedCompany } = useOutletContext<any>();


    const getData = async () => {
        try {
            const res = await DashboardAPI.postPaychecks(selectedCompany,companydata)
            console.log(res);
            if (res.success) {
                const arr: any = []
                for (const i in res.data.result) {
                    const obj = {
                        emp_id: i,
                        name: res.data.result[i].name,
                        gross_wages: res.data.result[i].gross_wages
                    }
                    arr.push(obj)
                }
                setData(arr)
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    // const handlePrint = async (emp_id: any) => {
    //     try {
    //         const res = await DashboardAPI.postGeneratepaychecks(selectedCompany,{ ...companydata, emp_id })
    //         console.log(res);
    //         if (res) {
    //             var binaryData = [];
    //             binaryData.push(res);
    //             const url: any = window.URL.createObjectURL(
    //                 new Blob(binaryData, { type: "application/pdf" })
    //             );
    //             var link = document.createElement("a");
    //             link.download = "Pay Slip";
    //             link.href = url;
    //             document.body.appendChild(link);
    //             link.click();
    //             document.body.removeChild(link);
    //         }
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // };

    const handlePrint = async (emp_id: any) => {
        const url = BASE_URL + `payroll/print_payslip/${selectedCompany}?start_date=${companydata.start_date}&end_date=${companydata.end_date}&pay_date=${companydata.pay_date}&emp_id=${emp_id}`;
        window.open(url, '_blank');
    };

    useEffect(() => {
        getData();

    }, []);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);


    const columns = [
        {
            key: '1',
            title: "Name",
            dataIndex: "name",
        },
        {
            key: '2',
            title: "Amount",
            dataIndex: "gross_wages",
        },
        {
            key: '3',
            title: "Create a check?",
            dataIndex: "emp_id",
            render: ((v: any) => {
                return <button
                    onClick={() => handlePrint(v)}
                    className="rounded-[4px] text-[14px] bg-[var(--primary)] text-white ml-auto md:ml-[10px] py-[7px] px-[20px]"
                >
                    Print
                </button>
            })
        },
    ];

    const componentRef = useRef(null);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection: TableRowSelection<any> = {
        type: 'checkbox',
        selectedRowKeys,
        onChange: onSelectChange,
        fixed: 'right',
    };

    return (
        <div className='mt-[20px] text-[18px] font-semibold text-secondary'>
            <h3>Print checks</h3>
            <div>
                <Table
                    ref={componentRef}
                    className="text-center"
                    columns={columns}
                    dataSource={data}
                />
            </div>
            {/* <div>
                <button
                    onClick={handlePrint}
                    className="rounded-[4px] text-[14px] bg-[var(--primary)] text-white ml-auto md:ml-[10px] py-[7px] px-[20px]"
                >
                    Print
                </button>
            </div> */}
        </div>
    );
};

export default PrintSlip;
