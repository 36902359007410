import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import PersonalInfo from "../../../components/Employee/PersonalInfo";
import BankInfo from "../../../components/Employee/BankInfo";
import WorkInfo from "../../../components/Employee/WorkInfo";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/Loader";
import { EMPLOYEE, PAYROLL_SETUP } from "../../../navigation/Constant";
import { useNavigate, useOutletContext } from "react-router-dom";
import WithAccess from "../../../hoc/DashboardHoc";
import TaxInfo from "../../../components/Employee/TaxInfo";

const personalInfoInitial = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  extension: "+1",
  mobile_no: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  ssn: "",
  hire_date: "",
  driver_license: "",
  marital_status: "",
  company_id: [],
};

const bankInfoInitial = {
  routing_no: "",
  bank_name: "",
  account_no: "",
  confirm_account_no: "",
};

const workInfoInitial = {
  emp_code: "",
  access_id: "",
  emp_type: "",
  hrs_per_week: 0,
  amount: "",
  work_loc: "",
};

const taxInfoInitial = {
  deduct: 0,
  dependent_amt: 0,
  filing_status: "",
  nra_amt: 0,
  other_income: 0,
  multiple_jobs: false,
  federal_inc_tax: false,
  soc_security: false,
  michigan_state_tax: false,
  add_michigan_tax: 0,
  total_allowance: 0,
};

const requiredFields = {
  personalInfo: [
    "first_name",
    "last_name",
    "email",
    "mobile_no",
    "address",
    "city",
    "state",
    "zip_code",
    "hire_date",
  ],
  bankInfo: ["routing_no", "bank_name", "account_no", "confirm_account_no"],
  workInfo: ["emp_code", "access_id", "emp_type", "amount", "work_loc"],
  taxInfo: [
    "deduct",
    "dependent_amt",
    "filing_status",
    "nra_amt",
    "multiple_jobs",
  ],
};

type requiredFieldsKeyTypeAll =
  | "first_name"
  | "last_name"
  | "email"
  | "mobile_no"
  | "address"
  | "city"
  | "state"
  | "zip_code"
  | "hire_date"
  | "routing_no"
  | "bank_name"
  | "account_no"
  | "confirm_account_no"
  | "emp_code"
  | "access_id"
  | "emp_type"
  | "amount"
  | "work_loc";

type requiredFieldsKeyType =
  | "personalInfo"
  | "bankInfo"
  | "workInfo"
  | "taxInfo";

const initialErrorState = {
  error: false,
  msg: "",
};

const labelKeyMap = {
  first_name: "First Name",
  last_name: "Last Name",
  middle_name: "Middle Initial",
  company_id: "Company",
  email: "Email",
  extension: "Extension",
  mobile_no: "Mobile Number",
  address: "Street Address",
  city: "City",
  state: "State",
  zip_code: "Zip Code",
  ssn: "SSN",
  hire_date: "Hire Date",
  driver_license: "Driver License",
  marital_status: "Marital Status",
  routing_no: "Routing Number",
  bank_name: "Bank Name",
  account_no: "Account Number",
  confirm_account_no: "Re-Enter Account Number",
  emp_code: "Employee Code",
  access_id: "Access ID",
  emp_type: "Employee Type",
  hrs_per_week: "Hours Per Week",
  amount: "Amount",
  work_loc: "Work Location",
  deduct: "Deduct",
  dependent_amt: "Dependent Amount",
  filing_status: "Filing Status",
  nra_amt: "NRA Amount",
  other_income: "Other Income",
  multiple_jobs: "Multiple Jobs",
  federal_inc_tax: "Federal Income Tax",
  soc_security: "Social Security",
  michigan_state_tax: "Michigan State Tax",
  add_michigan_tax: "Additional Michigan Tax",
  total_allowance: "Total Allowance",
};

const AddEmployee = ({ masterData }: any) => {
  // const [masterData, setMasterData] = React.useState<any>({})

  const navigate = useNavigate();

  const [step, setStep] = React.useState(1);

  const [selectedCompany, setSelectedCompany] = useState('')

  const contextData = useOutletContext<any>()

  useEffect(() => {
      setSelectedCompany(contextData.selectedCompany)
  }, [contextData.selectedCompany])

  const [info, setInfo] = React.useState({
    ...personalInfoInitial,
    ...bankInfoInitial,
    ...workInfoInitial,
    ...taxInfoInitial,
  });

  const [formErr, setFormErr] = useState<any>({
    ...Object.keys(labelKeyMap).map((key: string) => ({
      [key]: initialErrorState,
    })),
  });

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!validate(step)) return;

    const formData = new FormData();

    const infoKeys = Object.keys(info);

    for (let key of infoKeys) {
      if (info[key as requiredFieldsKeyTypeAll] !== "company_id") {
        formData.append(key, info[key as requiredFieldsKeyTypeAll]);
      }
    }

    const empArray = []
    empArray.push(selectedCompany)
    console.log('empArray', empArray);
    
    formData.append("company_id", JSON.stringify(empArray));

    console.log('formData', formData);

    try {
      setLoading(true);

      const data = await DashboardAPI.postAddEmployee(formData);

      if (data.success) {
        toast.success("Employee Added Successfully");
        setTimeout(function () {
          navigate(-1);
        }, 1000);
      }
    } catch (err: any) {
      console.log("some error occred :: ", err);
      const errMsg =
        err?.response?.data?.message ||
        err?.message ||
        JSON.stringify(err?.message) ||
        "something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const validate = (step: number) => {
    let isValid = true;
  
    const stepKey = Object.keys(requiredFields)[step - 1];
  
    const requiredFieldsArr = requiredFields[stepKey as requiredFieldsKeyType];
  
    for (let i = 0; i < requiredFieldsArr.length; i++) {
      const key = requiredFieldsArr[i];
  
      if (step !== 2 && !String(info[key as keyof typeof labelKeyMap])) {
        // Exclude bank info fields (step 2) from validation
        setFormErr((prevState: any) => ({
          ...prevState,
          [key as keyof typeof labelKeyMap]: {
            error: true,
            msg: `${labelKeyMap[key as keyof typeof labelKeyMap]} is required`,
          },
        }));
        isValid = false;
      } else if (
        key === "confirm_account_no" &&
        info[key as keyof typeof labelKeyMap] !== info["account_no"]
      ) {
        setFormErr((prevState: any) => ({
          ...prevState,
          confirm_account_no: {
            error: true,
            msg: `Account number and confirm account number should be same`,
          },
        }));
        isValid = false;
      } else {
        setFormErr((prevState: any) => ({
          ...prevState,
          [key as keyof typeof labelKeyMap]: { error: false, msg: "" },
        }));
      }
    }
  
    return isValid;
  };

  const nextStep = () => {
    if (validate(step)) {
      setStep(step + 1);
    }
  };

  const resetForm = () => {
    setInfo({
      ...personalInfoInitial,
      ...bankInfoInitial,
      ...workInfoInitial,
      ...taxInfoInitial,
    });
    setFormErr({
      ...Object.keys(labelKeyMap).map((key: string) => ({
        [key]: initialErrorState,
      })),
    });
    setStep(1);
  };

  return (
    <div className="container mx-auto">
      <ToastContainer />
      {loading && <Loader />}

      <div className="flex justify-between">
        <h2 className="font-semibold text-[var(--secondary)] text-[18px]">
          {" "}
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate(EMPLOYEE)}
          >
            Employees
          </span>{" "}
          / New
        </h2>
        <i
          className="bi font-semibold bi-arrow-counterclockwise cursor-pointer text-[20px]"
          title="Discard"
          onClick={() => {
            resetForm();
          }}
        ></i>
      </div>

      <div className="steps mt-[20px] hidden md:flex flex-row justify-start gap-[20px] items-center">
        <div
          className={
            " flex items-center gap-[10px] justify-center px-[10px] py-[5px] "
          }
        >

          <p className="font-semibold text-[#000] border-b-[2px] border-[var(--primary)] m-0">
            Basic Info
          </p>
        </div>
        <div
          className={
            "  rounded-[15px] flex items-center gap-[10px] justify-center px-[10px] py-[5px] "
          }
        >
          <p
            className={
              "font-semibold m-0" +
              (step > 1
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Bank Info
          </p>
        </div>
        <div
          className={
            "  rounded-[15px] flex items-center gap-[10px] justify-center px-[10px] py-[5px] "
          }
        >
          {/* <div className={"circle flex min-w-[20px] h-[20px] items-center justify-center rounded-[50%] " + (step > 2 ? ' bg-[#000]' : 'bg-[#7C888D]/[0.5]')}>
                            <p className="text-white m-0">3</p>
                        </div> */}
          <p
            className={
              "font-semibold m-0 " +
              (step > 2
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5] ")
            }
          >
            Work Info
          </p>
        </div>

        <div
          className={
            "  rounded-[15px] flex items-center gap-[10px] justify-center px-[10px] py-[5px] "
          }
        >
          {/* <div className={"circle flex min-w-[20px] h-[20px] items-center justify-center rounded-[50%] " + (step > 3 ? ' bg-[#000]' : 'bg-[#7C888D]/[0.5]')}>
                            <p className="text-white m-0">4</p>
                        </div> */}
          <p
            className={
              "font-semibold m-0 " +
              (step > 3
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5] ")
            }
          >
            Tax Info
          </p>
        </div>
      </div>

      <div className="mt-[30px] mb-[150px]">
        {step == 1 && (
          <PersonalInfo
            data={info}
            setData={setInfo}
            masterData={masterData}
            formErrors={formErr}
            setFormErrors={setFormErr}
          />
        )}
        {step == 2 && (
          <BankInfo
            data={info}
            setData={setInfo}
            masterData={masterData}
            formErrors={formErr}
            setFormErrors={setFormErr}
          />
        )}

        {step == 3 && (
          <WorkInfo
            data={info}
            setData={setInfo}
            masterData={masterData}
            formErrors={formErr}
            setFormErrors={setFormErr}
          />
        )}

        {step == 4 && (
          <TaxInfo
            data={info}
            setData={setInfo}
            masterData={masterData}
            formErrors={formErr}
            setFormErrors={setFormErr}
          />
        )}
      </div>

      <div className="relative ">
        <div className="footer border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md bg-[#e5e5e5] right-[0px] ">
          <div className="flex justify-between gap-[10px] p-[5px]">
            {step !== 1 && (
              <button
                className=" text-black font-semibold rounded-[8px] px-[20px] py-[5px]"
                onClick={(e) => {
                  setStep(step - 1);
                }}
              >
                {"<<" + " Back"}
              </button>
            )}
            {step < 4 && (
              <button
                className="text-[var(--primary)] ml-auto font-semibold rounded-[8px] px-[20px] py-[5px]"
                onClick={(e) => {
                  nextStep();
                }}
              >
                {"Next" + " >>"}
              </button>
            )}

            {step === 4 && (
              <button
                className="bg-[var(--primary)] ml-auto text-[#fff] font-semibold rounded-[8px] px-[20px] py-[5px]"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEmployee;
