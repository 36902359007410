import React, { useEffect } from "react";
import Dashboard from "../../../components/Dashboard/DashboardLayout";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import Loader from "../../../components/Loader";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { Empty, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { ADD_EMPLOYEE, ADD_INVOICE } from "../../../navigation/Constant";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "antd";
import { MenuProps, Switch } from "antd";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import Input from "../../../components/Input/Input";

import { Radio } from "antd";
import { BASE_URL } from "../../../constants/apiConstant";

type task = "pdf" | "show_pdf" | "email";

const Invoice = () => {
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<any>({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [selectedCompany, setSelectedCompany] = React.useState<any>(
    localStorage.getItem("company_id")
  );

  const [search, setSearch] = React.useState<any>("");

  const [employees, setEmployees] = React.useState<any>([]);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          href={"javascript:void(0)"}
          onClick={() =>
            navigate("/dashboard/invoice/edit/" + selectedUser?.invoice_no)
          }
        >
          Edit
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href={"javascript:void(0)"}
          onClick={() => actionInvoice(selectedUser?.invoice_no, "email")}
        >
          Send Mail
        </a>
      ),
    },
  ];

  const items2: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          href={"javascript:void(0)"}
          onClick={() => actionInvoice(selectedUser?.invoice_no, "pdf")}
        >
          Download PDF
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href={"javascript:void(0)"}
          onClick={() => actionInvoice(selectedUser?.invoice_no, "show_pdf")}
        >
          View PDF
        </a>
      ),
    },
  ];

  const [selectedUser, setSelectedUser] = React.useState<any>({});

  const columns = [
    {
      title: "Invoice Number",
      dataIndex: "invoice_no",
      key: "invoice_no",
      render: (text: any, data: any) => (
        <a
          onClick={() =>
            navigate("/dashboard/invoice/edit/" + data?.invoice_no)
          }
        >
          {data?.invoice_no}
        </a>
      ),
      sorter: (a: any, b: any) => a.email.length - b.email.length,
    },
    {
      title: "Invoice Date ",
      dataIndex: "invoice_date",
      key: "invoice_date",
      sorter: (a: any, b: any) => a.invoice_date.length - b.invoice_date.length,
    },
    {
      title: "Payment Due Date",
      dataIndex: "due_date",
      key: "due_date",
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
    },

    {
      title: "Action",
      render: (text: any) => {
        return (
          <div className="flex gap-[10px] items-center">
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
              <button
                className="border-[1px] border-[#2E3147] rounded-[4px] w-[30px] h-[30px] flex items-center justify-center"
                onMouseEnter={() => setSelectedUser(text)}
              >
                <i className="bi bi-three-dots-vertical"></i>
              </button>
            </Dropdown>
            <Dropdown menu={{ items: items2 }} placement="bottomRight" arrow>
              <button
                className="border-[1px] border-[#2E3147] rounded-[4px] w-[30px] h-[30px] flex items-center justify-center"
                onMouseEnter={() => setSelectedUser(text)}
              >
                <i className="bi bi-filetype-pdf text-[20px] font-semibold"></i>
              </button>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const getEmployees = async () => {
    try {
      setLoading(true);

      const data = await DashboardAPI.getInvoice();

      if (data.success) {
        setEmployees(data?.data?.values?.invoice);
      }
    } catch (err: any) {
      console.log("some error occred :: ", err);
      const errMsg =
        err?.response?.data?.message || err?.message || "something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const actionInvoice = async (id: Number, task: task) => {
    try {
      setLoading(true);

      const data = await DashboardAPI.postInvoiceAction({
        invoice_no: id,
        task,
      });

      if (data.success) {
        toast.success(data?.message);
      }
      if (task === "pdf" || task === "show_pdf") {
        console.log(data);
        //  const blob =  data?.blob()
        var binaryData = [];
        binaryData.push(data);
        const url: any = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        );

        if (task === "show_pdf") {
          window.open(url);
        } else {
          var link = document.createElement("a");
          link.download = "Invoice";
          link.href = url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        console.log(url);
      }
    } catch (err: any) {
      console.log("some error occred :: ", err);
      const errMsg =
        err?.response?.data?.message || err?.message || "something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, []);

  const locale = {
    emptyText: (
      <Empty
        style={{
          marginTop: "100px",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: 60 }}
        description={<span>No Invoice Found</span>}
      >
        <button
          className=" text-white rounded-[4px] text-[14px] bg-[var(--primary)] ml-auto md:ml-[10px] py-[7px] px-[20px]"
          onClick={(e) => {
            navigate(ADD_INVOICE);
          }}
        >
          New Invoice
        </button>
        {/* <Button type="primary">Create Now</Button> */}
      </Empty>
    ),
  };

  //   useEffect(() => {
  //     if(search.length > 2 || search.length === 0)
  //       getEmployees();
  //   }, [search]);

  return (
    <div className=" w-[100%] max-h-[100vh] pb-[100px]">
      <ToastContainer />
      {loading ? <Loader /> : null}
      <div className="mt-[20px] flex gap-[15px]">
        <p className="font-semibold text-[18px] text-[var(--secondary)]">
          Invoice
        </p>
        <div className="flex items-center ml-auto justify-center hidden md:block border-[1px] border-[#e5e5e5] px-[10px]">
          {/* <img src="./assets/search.png" className="object-contain mr-[10px] " alt="" />  */}
          <input
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            className="focus:border-0 outline-0 h-[100%] "
            placeholder={"Search"}
          />
        </div>

        <button
          className=" text-white rounded-[4px] text-[14px] bg-[var(--primary)] ml-auto md:ml-[10px] py-[7px] px-[20px]"
          onClick={(e) => {
            navigate(ADD_INVOICE);
          }}
        >
          New
        </button>
      </div>

      <div className="mt-[30px] w-[100%] overflow-x-scroll md:overflow-hidden">
        <Table
          locale={locale}
          columns={columns}
          dataSource={employees}
          pagination={false}
        />
        <div className="w-[100%] mt-[30px] items-end justify-end flex">
          {employees > 0 ? (
            <CustomPagination
              total={pagination?.total}
              onChange={(page: any, pageSize: any) => {
                setCurrentPage(page === 0 ? 1 : page);
                setPageSize(pageSize);
              }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Invoice;
