import React, { useEffect, useState } from 'react'
import Loader from "../../../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Select, Space, DatePicker } from 'antd';
import Input from '../../../components/Input/Input';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { useOutletContext } from 'react-router-dom';
import { BASE_URL } from '../../../constants/apiConstant';
import { FORM, FORM_1028, FORM_940, WAGES_TAX } from '../../../navigation/Constant';
import { useNavigate } from 'react-router-dom'

type Props = {}

const Reports = ({ }: Props) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false);

    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <div>

                <div className="mt-[20px] items-cetner flex">
                    <p className="font-semibold text-[18px] text-[var(--secondary)]">
                        Reports
                    </p>
                </div>

                <div className='border rounded-[4px] p-[24px] grid grid-cols-6 my-[26px] justify-center sm:gap-[42px] gap-4 shadow'>
                    <div className='col-span-4'>
                        <h3 className='font-semibold text-[20px] text-[var(--secondary)]'>Payroll</h3>
                        <p className='text-[14px]'>Track payroll costs, including employee wages and benefits/deductions, and your payroll tax liabilities.</p>
                    </div>
                    <div className='sm:col-span-2 col-span-4'>
                        <a href="" className='font-semibold text-[18px] text-[var(--primary)] underline'
                        onClick={() => navigate(WAGES_TAX)}
                        >Payroll Wage & Tax Report</a>
                        <p className='text-[14px]'>A breakdown of employee wage and payroll tax amounts.</p>
                    </div>
                </div>

                <div className='border rounded-[4px] p-[24px] grid grid-cols-6 justify-center sm:gap-[42px] gap-4 shadow'>
                    <div className='col-span-4'>
                        <h3 className='font-semibold text-[20px] text-[var(--secondary)]'>Taxes</h3>
                        <p className='text-[14px]'>A detailed look at the taxes you owe, based on sales taxes collected and paid, and transactions processed.</p>
                    </div>
                    <div className='sm:col-span-2 col-span-4'>
                        <a href="" className='font-semibold text-[18px] text-[var(--primary)] underline'
                        onClick={() => navigate(FORM)}
                        >FORM 941</a>
                        <p className='text-[14px]'>A breakdown of employee wage and payroll tax amounts.</p>
                    </div>
                {/* </div>

                <div className='border rounded-[4px] mt-[26px] p-[24px] grid grid-cols-6 justify-center sm:gap-[42px] gap-4 shadow'>*/}
                    <div className='col-span-4'>
                        <h3 className='font-semibold text-[20px] text-[var(--secondary)]'></h3>
                        <p className='text-[14px]'></p>
                    </div> 
                    <div className='sm:col-span-2 col-span-4'>
                        <a href="" className='font-semibold text-[18px] text-[var(--primary)] underline'
                        onClick={() => navigate(FORM_1028)}
                        >FORM 1028</a>
                        <p className='text-[14px]'>A breakdown of employee wage and payroll tax amounts.</p>
                    </div>
                {/* </div>

                <div className='border rounded-[4px] mt-[26px] p-[24px] grid grid-cols-6 justify-center sm:gap-[42px] gap-4 shadow'>*/}
                    <div className='col-span-4'>
                        <h3 className='font-semibold text-[20px] text-[var(--secondary)]'></h3>
                        <p className='text-[14px]'></p>
                    </div> 
                    <div className='sm:col-span-2 col-span-4'>
                        <a href="" className='font-semibold text-[18px] text-[var(--primary)] underline'
                        onClick={() => navigate(FORM_940)}
                        >FORM 940</a>
                        <p className='text-[14px]'>A breakdown of employee wage and payroll tax amounts.</p>
                    </div>
                </div>
            </div>

        </>

    )
};

export default Reports;