import React, { useEffect, useState } from 'react'
import { TeamOutlined, CheckCircleFilled } from '@ant-design/icons'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ADD_COMPANY, ADD_EMPLOYEE, EDIT_COMPANY, EDIT_EMPLOYEE, EMPLOYEE, PAYSCHEDULE, PRIOR_PAYROLLS, RUN_PAYROLL } from '../../../navigation/Constant'
import EditCompany from '../Company/EditCompany'
import { Checkbox, Progress } from 'antd'
import { toast } from "react-toastify";
import PriorPayrolls from './PriorPayrolls'
import type { CheckboxProps } from 'antd';
import { DashboardAPI } from '../../../apis/dashboardApi'
import { AuthAPI } from '../../../apis/authAPI'
import { ToastContainer } from 'react-toastify'
import Loader from '../../../components/Loader'
type Props = {
    children?: JSX.Element;
    company?: any;
};

export default function PayrollSetup({ }: Props) {
    const [isNewBusiness, setIsNewBusiness] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isCompanyCreate, setIsCompanyCreate] = useState(false);
    const [isEmoloyeeCreated, setIsEmployeeCreadted] = useState(false)
    const [isPayscheduleCreated, setIsPayscheduleCreadted] = useState(false)
    const [isPriorPayrollCreated, setIsPriorPayrollCreadted] = useState(false)
    const [isDetailsFilled, setIsDetailsFilled] = useState(false);
    const [companyDetails, setCompanyDetails] = useState({
        company_name: "",
        address: "",
        employee_number: 0,
        payroll_frequency: "",
        last_pay_date: ""
    });

    const [selectedCompany, setSelectedCompany] = useState()
    const [step, setStep] = useState(1);

    const navigate = useNavigate()

    const getData = async () => {
        console.log('selectedCompany', selectedCompany);
        let user: any = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            if (selectedCompany && user?.emp_id) {
                setLoading(true);
                try {
                    const response = await DashboardAPI.getInitialSetup(user?.emp_id, selectedCompany);
                    console.log('response', response);
                    setLoading(false);
                    let count = 0;
                    if (response?.data?.values?.is_company_create) count++;
                    if (response?.data?.values?.is_employee_create) count++;
                    if (response?.data?.values?.is_payschedule) count++;
                    if (response?.data?.values?.is_prior || response?.data?.values?.is_new_business) count++;
                    setProgress(Math.round((count / 4) * 100))
                    setIsCompanyCreate(response?.data?.values?.is_company_create || false);
                    setIsEmployeeCreadted(response?.data?.values?.is_employee_create || false);
                    setIsPayscheduleCreadted(response?.data?.values?.is_payschedule || false)
                    setIsPriorPayrollCreadted(response?.data?.values?.is_prior || false)
                    setIsNewBusiness(response?.data?.values?.is_new_business || false)
                    setCompanyDetails(response?.data?.values);
                } catch (error) {
                    console.error('Error sending data:', error);
                    setLoading(false);
                    throw error;
                }
            }
        }

    }
    useEffect(() => {
        getData()
    }, [selectedCompany])

    const contextData = useOutletContext<any>()

    useEffect(() => {
        setSelectedCompany(contextData.selectedCompany)
    }, [contextData.selectedCompany])


    const getCheck = async (val: any) => {
        let user: any = localStorage.getItem('user');
        if (user) {
            user = JSON.parse(user);
            if (selectedCompany) {
                setLoading(true);
                try {
                    const response = await DashboardAPI.patchInitialSetup(user?.emp_id, selectedCompany, { is_new_business: val });
                    setLoading(false);
                    if (response) {
                        window.location.reload();
                        getData(); // Fetch updated data after checkbox state change
                    }
                } catch (error) {
                    console.error('Error sending data:', error);
                    setLoading(false);
                    throw error;
                }
            }
        }
    };

    const onChange: CheckboxProps['onChange'] = (e) => {
        const isChecked = e.target.checked;
        setIsNewBusiness(isChecked);
        getCheck(isChecked);
    };
    useEffect(() => {
        localStorage.setItem('isNewBusiness', JSON.stringify(isNewBusiness));
    }, [isNewBusiness])

    const checkDetailsFilled = () => {
        if (progress === 100 && isCompanyCreate && isEmoloyeeCreated && isPayscheduleCreated && (isPriorPayrollCreated || isNewBusiness)) {
            setIsDetailsFilled(true);
        } else {
            setIsDetailsFilled(false);
        }
    };

    useEffect(() => {
        checkDetailsFilled();
    }, [progress, isCompanyCreate, isEmoloyeeCreated, isPayscheduleCreated, isPriorPayrollCreated, isNewBusiness]);


    const showStage = async () => {
        try {
            const response = await DashboardAPI.getShowStage(selectedCompany);
            if (response.success) {
                console.log(response)
                setStep(response.data)
            } else {
                toast.error("Failed to fetch data from the API")
            }
        } catch (error: any) {
            toast.error('API request error: ' + error.message);
        }
    }


    return (
        <div className='mb-[100px]'>
            <ToastContainer />
            {loading && <Loader />}
            <div className='w-[100%] max-h-[100vh]'>
                <div className='my-[24px] mx-[20px] grid grid-cols-6 sm:gap-[62px] gap-[22px]  items-center'>
                    <div className="col-span-4">
                        <p className="font-semibold text-[18px] text-[var(--secondary)]">Payroll setup</p>
                        <Progress className={`min-w-[100%] ${progress < 70
                            ? "bg-error_light text-error  "
                            : progress >= 70 && progress <= 90
                                ? "bg-secondary_light text-secondary "
                                : " text-primary bg-primary_light"
                            }`} strokeColor={'#4784CA'} percent={progress} />

                        <p className="sm:text-[14px] text-[12px]">Ready to preview! Get the most out of payroll by completing the remaining steps.</p>
                    </div>
                    <button
                        className={`border-2 font-semibold border-[var(--primary)] rounded-full py-[6px] px-4 whitespace-nowrap sm:text-[14px] text-[12px] text-[#fff] bg-[var(--primary)] ${!isDetailsFilled ? 'cursor-not-allowed opacity-50' : ''}`}
                        disabled={!isDetailsFilled}
                        onClick={() => navigate(RUN_PAYROLL)}
                    >
                        Preview payroll
                    </button>
                </div>
                <div className="border rounded-md m-[20px] p-[24px] shadow">
                    <div className="grid grid-cols-6 items-center justify-between">
                        <div className='col-span-4'>
                            {companyDetails ? (
                                <div className="flex gap-4">
                                    {/* <div>
                                        <CheckCircleFilled className="text-[var(--primary)] text-[24px]" />
                                    </div> */}
                                    <div>
                                        <p className="text-[18px] font-semibold">Company</p>
                                        <p className="text-[14px]"><span className="font-semibold">{companyDetails?.company_name}</span> - {companyDetails?.address}</p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className="text-[18px] font-semibold">Company</p>
                                    <p className="text-[14px]">No company added</p>
                                </div>
                            )}

                        </div>
                        <div className='sm:col-span-2 col-span-4'>
                            <button
                                className="border-2 border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] mt-[8px] sm:mt-[0px]"
                                onClick={() => navigate(isCompanyCreate ? "/dashboard/company/edit/" + selectedCompany : ADD_COMPANY)}
                            >
                                {isCompanyCreate ? 'Edit company' : 'Add company'}
                            </button>

                        </div>
                    </div>
                    <div className="mt-[20px] grid grid-cols-6 items-center justify-between">
                        <div className='col-span-4'>
                            {companyDetails ? (
                                <div className="flex gap-4">
                                    {/* <div>
                                        <CheckCircleFilled className="text-[var(--primary)] text-[24px]" />
                                    </div> */}
                                    <div>
                                        <p className="text-[18px] font-semibold">Employees</p>
                                        <p className="text-[14px] whitespace-nowrap">{companyDetails?.employee_number} employee on payroll</p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className="text-[18px] font-semibold">Employees</p>
                                    <p className="text-[14px]">No employee added</p>
                                </div>
                            )}
                        </div>
                        <div className='sm:col-span-2 col-span-4'>
                            <button
                                className={`border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px] ${!isCompanyCreate ? 'opacity-50 cursor-not-allowed' : ''}`}
                                // onClick={() => {
                                //     let user: any = localStorage.getItem('user')
                                //     if (user && isCompanyCreate) {
                                //         user = JSON.parse(user)
                                //         console.log(user);
                                //         if (user?.emp_id) {
                                //             navigate(isCompanyCreate ? "/dashboard/employee/edit/" + user?.emp_id : ADD_EMPLOYEE)
                                //         }
                                //     }
                                // }}
                                onClick={() => navigate(ADD_EMPLOYEE)}
                            >
                                 Add employee
                            </button>
                        </div>
                    </div>
                    <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                        <div className="col-span-4">
                            {companyDetails ? (
                                <div className="flex gap-4">
                                    {/* <div>
                                        <CheckCircleFilled className="text-[var(--primary)] text-[24px]" />
                                    </div> */}
                                    <div>
                                        <p className="text-[18px] font-semibold">Pay schedule</p>
                                        <p className="text-[14px] whitespace-nowrap">Pay schedule: <span className="font-semibold">{companyDetails?.payroll_frequency}</span></p>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p className="text-[18px] font-semibold">Pay schedule</p>
                                    <p className="text-[14px] ">No pay schedule added</p>
                                </div>
                            )}
                        </div>
                        <div className='sm:col-span-2 col-span-4'>
                            <button
                                className={`border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px] ${!(isCompanyCreate && isEmoloyeeCreated) ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={() => {
                                    if (isCompanyCreate && isEmoloyeeCreated) navigate(isPayscheduleCreated ? PAYSCHEDULE : PAYSCHEDULE)
                                }}
                            >
                                {isPayscheduleCreated ? 'View payschedule' : 'Add payschedule'}
                            </button>
                        </div>
                    </div>
                    <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                        <div className="col-span-4">
                            <p className="text-[18px] font-semibold">Prior payrolls</p>

                            <Checkbox disabled={!isCompanyCreate || !isPayscheduleCreated} onChange={onChange} checked={isNewBusiness} className='text-[15px] font-semibold'>New business</Checkbox>
                            {isNewBusiness ? (
                                <p className="text-[14px]">No prior payrolls added</p>
                            ) : (
                                <p className="text-[14px]">Last pay period date: <span className="font-semibold">
                                     {companyDetails ? (companyDetails?.last_pay_date) : ''}
                                    </span> </p>
                            )
                            }
                        </div>
                        <div className='sm:col-span-2 col-span-4'>
                            <button
                                className={`border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px] ${!(isCompanyCreate && isEmoloyeeCreated && isPayscheduleCreated) || isNewBusiness ? 'opacity-50 cursor-not-allowed' : ''}`}
                                onClick={() => {
                                    showStage();
                                    if ((isCompanyCreate && isPayscheduleCreated) && !isNewBusiness) navigate(isPriorPayrollCreated ? PRIOR_PAYROLLS : PRIOR_PAYROLLS)
                                }}
                            >
                                {isPriorPayrollCreated ? 'View prior payroll' : 'Add prior payroll'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}