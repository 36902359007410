import React, { useEffect } from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import { Select } from "antd";
import Input from "../../../components/Input/Input";
import { useNavigate, useParams } from "react-router-dom";
import { CUSTOMER } from "../../../navigation/Constant";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ToastContainer, toast } from "react-toastify";
import { CommonApi } from "../../../apis/commonApi";

const initialData = {
  company_id: "",
  name: "",
  email: "",
  mobile_no: "",
  contact_name: "",
  currency: "",
  bill_address: "",
  bill_city: "",
  bill_state: "",
  bill_zipcode: "",
  bill_country: "",
  ship_address: "",
  ship_city: "",
  ship_state: "",
  ship_zipcode: "",
  ship_country: "",
  account_no: "",
};

const AddCustomer = () => {
  const [data, setData] = React.useState<any>({ ...initialData });
  const [previousData, setProviousData] = React.useState<any>({}); // for preventing data
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setData((prev: any) => ({ ...prev, [name]: value }));
  };

  const navigate = useNavigate();

  const resetForm = () => {
    setData({ ...initialData });
  };

  const [company, setCompany] = React.useState<any>([]);
  const [state, setState] = React.useState<any>([]);
  const [city, setCity] = React.useState<any>({});

  const getCityList = async (state: any) => {
    try {
      setLoading(true);
      const dataum = await CommonApi.getCityList({ state: data[state] });
      if (dataum.success) {
        setCity((prev: any) => ({
          ...prev,
          [state]: dataum?.data?.city_list.map((item: any) => ({
            label: item,
            value: item,
          })),
        }));
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.state) {
      getCityList(data?.state);
    }
  }, [data?.state]);

  const getMasterData = async () => {
    try {
      const [data1, data2] = await Promise.all([
        DashboardAPI.getCompany().catch((err) => {
          console.log(err);
          return null;
        }),
        CommonApi.getStateList().catch((err) => {
          console.log(err);
          return null;
        }),
      ]);

      setCompany(
        data1?.data?.values?.map((item: any) => {
          return {
            label: item?.company_name,
            value: item?.company_id,
          };
        }) || []
      );

      setState(
        data2?.data?.state_list.map((item: any) => ({
          label: item,
          value: item,
        })) || []
      );
    } catch (err: any) {
      console.log(err);
      // const errMsg = err?.response?.data?.message || 'Something went wrong'
      //     toast.error(errMsg)
    } finally {
      // setLoading(false)
    }
  };

  useEffect(() => {
    getMasterData();
  }, []);

  const submitForm = async () => {
    if (
      !data?.company_id ||
      !data?.name ||
      !data?.contact_name ||
      !data?.email ||
      !data?.mobile_no ||
      !data?.currency ||
      !data?.account_no
    ) {
      toast.error("Please Fill All Details");
      return;
    }

    const payload = {
      ...data}
    
      if(edit){
        Object.keys(payload).forEach((key) => {
          if (payload[key] === previousData[key]) {
            delete payload[key];
          }
        });
      }

    try {
      setLoading(true);
      const res = !edit
        ? await DashboardAPI.postAddCustomer(data)
        : await DashboardAPI.postUpdateCustomer(id, payload);
      if (res?.success) {
        toast.success(res?.message);
        resetForm();
        setTimeout(function () {
          navigate(CUSTOMER);
        }, 1000);
        // navigate(CUSTOMER);
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  // EDIT PART

  const [edit, setEdit] = React.useState<boolean>(false);
  const { id } = useParams();

  const getCustomerById = async () => {
    try {
      setLoading(true);
      const dataum = await DashboardAPI.getCustomerById(id);
      if (dataum.success) {
        console.log(dataum.data);
        setData((prev: any) => ({
          ...prev,
          ...dataum.data.values,
        }));
        setProviousData((prev: any) => ({
          ...prev,
          ...dataum.data.values,
        }));
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setEdit(true);
      getCustomerById();
    }
  }, []);

  return (
    <div className="mt-[20px] pb-[150px]">
      <ToastContainer />
      <div className="flex justify-between">
        <h2 className="font-semibold text-[var(--secondary)] text-[18px]">
          {" "}
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate(CUSTOMER)}
          >
            Customers
          </span>{" "}
          / {edit ? "Edit" : "New"}
        </h2>
        <i
          className="bi font-semibold bi-arrow-counterclockwise cursor-pointer text-[20px]"
          title="Discard"
          onClick={() => {
            resetForm();
          }}
        ></i>
      </div>

      <div className="content mt-[20px]  border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
        <div className="content_box">
          <div className="header p-[10px] bg-[#E6F4FF]">
            <p className="font-semibold m-0 text-[#000]/[0.6] uppercase">
              Contact
            </p>
          </div>
          <div className="body p-[10px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Select Company <span className="text-[red]">*</span>
                </label>
                <div className="flex gap-[10px] mt-[5px]">
                  <Select
                    defaultValue={data?.company_id}
                    className="border-[1px] text-[14px] w-[100%] h-[40px]  rounded-[4px] p-[0] "
                    options={company}
                    value={data?.company_id}
                    onChange={(e) => {
                      setData((prev: any) => ({ ...prev, company_id: e }));
                    }}
                  />
                </div>
              </div>

              <div className="col-span-1">
                <Input
                  label="Name"
                  required
                  placeholder="Enter Name"
                  value={data?.name}
                  name="name"
                  handleChange={handleChange}
                />
              </div>
              <div className="col-span-1">
                <Input
                  label="Email"
                  type={"email"}
                  required
                  placeholder="Enter Email"
                  value={data?.email}
                  name="email"
                  handleChange={handleChange}
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Phone No. <span className="text-[red]">*</span>
                </label>
                <div className="flex gap-[10px] mt-[5px]">
                  <input
                    name={"mobile_no"}
                    type={"number"}
                    className={
                      " flex-[0.9] border-[1px] text-[14px] rounded-[4px] p-[10px]   rounded-[12px]"
                    }
                    placeholder={"Enter your Mobile Number"}
                    value={data?.mobile_no}
                    onChange={(e: any) => {
                      if (e.target.value.length < 11) {
                        handleChange(e);
                      }
                    }}
                    onBlur={(e: any) => {
                      {
                        e.target.value?.length < 10
                          ? setFormErrors({
                              ...formErrors,
                              mobile_no: {
                                error: true,
                                msg: `Phone number must 10 digits`,
                              },
                            })
                          : setFormErrors({
                              ...formErrors,
                              mobile_no: { error: false, msg: `` },
                            });
                      }
                    }}
                  />
                </div>
                {formErrors?.mobile_no?.msg && (
                  <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                    {formErrors?.mobile_no?.msg}{" "}
                  </label>
                )}
              </div>

              <div className="col-span-1">
                <Input
                  label="Contact Name"
                  required
                  placeholder="Enter Contact Name"
                  value={data?.contact_name}
                  name="contact_name"
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
        <div className="content_box">
          <div className="header p-[10px] bg-[#E6F4FF]">
            <p className="font-semibold m-0 text-[#000]/[0.6] uppercase">
              Billing
            </p>
          </div>
          <div className="body p-[10px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Currency <span className="text-[red]">*</span>
                </label>
                <div className="flex gap-[10px] mt-[5px]">
                  <Select
                    defaultValue={data?.currency}
                    className="border-[1px] text-[14px] w-[100%] h-[40px]  rounded-[4px] p-[0] "
                    options={[
                      {
                        label: "USD",
                        value: "US Doller",
                      },
                    ]}
                    value={data?.currency}
                    onChange={(e) => {
                      setData((prev: any) => ({ ...prev, currency: e }));
                    }}
                  />
                </div>
              </div>

              <div className="col-span-1">
                <Input
                  label="Bill Address"
                  placeholder="Enter Bill Address"
                  value={data?.bill_address}
                  name="bill_address"
                  handleChange={handleChange}
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  State <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[0]"
                  placeholder="Search to Select"
                  value={data?.bill_state}
                  options={state}
                  onChange={(e) => {
                    setData((prev: any) => ({
                      ...prev,
                      bill_state: e,
                      state: "bill_state",
                    }));
                  }}
                />
                {formErrors?.bill_state?.msg && (
                  <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                    {formErrors?.bill_state?.msg}{" "}
                  </label>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  City <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                  placeholder="Search to Select"
                  value={data?.bill_city}
                  options={city["bill_state"]}
                  onChange={(e) => {
                    setData((prev: any) => ({ ...prev, bill_city: e }));
                  }}
                />
                {formErrors?.bill_city?.msg && (
                  <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                    {formErrors?.bill_city?.msg}{" "}
                  </label>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Country <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                  placeholder="Search to Select"
                  value={data?.bill_country}
                  options={[
                    {
                      label: "US",
                      value: "US",
                    },
                  ]}
                  onChange={(e) => {
                    setData((prev: any) => ({ ...prev, bill_country: e }));
                  }}
                />
              </div>

              <div className="col-span-1">
                <Input
                  type={"number"}
                  name={"bill_zipcode"}
                  label={"Zipcode ( 5 digits )"}
                  required
                  placeholder={"Enter Zipcode"}
                  value={data?.bill_zipcode}
                  handleChange={(e: any) => {
                    if (e.target.value.length < 6) {
                      handleChange(e);
                    }
                  }}
                  onBlur={(e: any) => {
                    {
                      e.target.value?.length < 5
                        ? setFormErrors({
                            ...formErrors,
                            bill_zipcode: {
                              error: true,
                              msg: `Zipcode must be 5 digit`,
                            },
                          })
                        : setFormErrors({
                            ...formErrors,
                            bill_zipcode: { error: false, msg: `` },
                          });
                    }
                  }}
                  errTxt={formErrors?.bill_zipcode?.msg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
        <div className="content_box">
          <div className="header p-[10px] bg-[#E6F4FF]">
            <p className="font-semibold m-0 text-[#000]/[0.6] uppercase">
              Shipping
            </p>
          </div>
          <div className="body p-[10px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
              <div className="col-span-1">
                <Input
                  label="Shipping Address"
                  placeholder="Enter Bill Address"
                  value={data?.ship_address}
                  name="ship_address"
                  handleChange={handleChange}
                />
              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  State <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[0]"
                  placeholder="Search to Select"
                  value={data?.ship_state}
                  options={state}
                  onChange={(e) => {
                    setData((prev: any) => ({
                      ...prev,
                      ship_state: e,
                      state: "ship_state",
                    }));
                  }}
                />
                {formErrors?.ship_state?.msg && (
                  <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                    {formErrors?.ship_state?.msg}{" "}
                  </label>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  City <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                  placeholder="Search to Select"
                  value={data?.ship_city}
                  options={city["ship_state"]}
                  onChange={(e) => {
                    setData((prev: any) => ({ ...prev, ship_city: e }));
                  }}
                />
                {formErrors?.ship_city?.msg && (
                  <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                    {formErrors?.ship_city?.msg}{" "}
                  </label>
                )}
              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Country <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                  placeholder="Search to Select"
                  options={[
                    {
                      label: "US",
                      value: "US",
                    },
                  ]}
                  onChange={(e) => {
                    setData((prev: any) => ({ ...prev, ship_country: e }));
                  }}
                />
              </div>

              <div className="col-span-1">
                <Input
                  type={"number"}
                  name={"ship_zipcode"}
                  label={"Zipcode ( 5 digits )"}
                  required
                  placeholder={"Enter Zipcode"}
                  value={data?.ship_zipcode}
                  handleChange={(e: any) => {
                    if (e.target.value.length < 6) {
                      handleChange(e);
                    }
                  }}
                  onBlur={(e: any) => {
                    {
                      e.target.value?.length < 5
                        ? setFormErrors({
                            ...formErrors,
                            ship_zipcode: {
                              error: true,
                              msg: `Zipcode must be 5 digit`,
                            },
                          })
                        : setFormErrors({
                            ...formErrors,
                            ship_zipcode: { error: false, msg: `` },
                          });
                    }
                  }}
                  errTxt={formErrors?.ship_zipcode?.msg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
        <div className="content_box">
          <div className="header p-[10px] bg-[#E6F4FF]">
            <p className="font-semibold m-0 text-[#000]/[0.6] uppercase">
              More
            </p>
          </div>
          <div className="body p-[10px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[20px]">
              <div className="col-span-1">
                <Input
                  label="Account Number"
                  required
                  placeholder="Enter Account Number"
                  value={data?.account_no}
                  name="account_no"
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative ">
        <div className="footer bg-[#e5e5e5] border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md  right-[0px] ">
          <div className="flex justify-between gap-[10px] p-[10px]">
            <button
              className="  text-black rounded-[8px] px-[20px] py-[10px]"
              onClick={(e) => {
                // setStep(step - 1)
              }}
            >
              Cancel
            </button>

            <button
              className="bg-[var(--primary)] ml-auto text-[#fff] rounded-[8px] px-[20px] py-[10px]"
              // onClick={(e) => {
              //     setStep(step + 1)
              // }}
              onClick={() => {
                submitForm();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCustomer;
