import React, { useEffect } from "react";
import Dashboard from "../../../components/Dashboard/DashboardLayout";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import Loader from "../../../components/Loader";
import CustomPagination from "../../../components/Pagination/CustomPagination";
import { Table } from "antd";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ADD_EMPLOYEE } from "../../../navigation/Constant";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ToastContainer, toast } from "react-toastify";
import { Dropdown } from "antd";
import { MenuProps, Switch } from "antd";
import ModalContainer from "../../../components/ModalContainer/ModalContainer";
import Input from "../../../components/Input/Input";
import type { TableColumnsType } from 'antd';

import { Radio } from "antd";
import { BASE_URL } from "../../../constants/apiConstant";
import { get } from "http";

const errorLabelMap = {
  email_mobile_both_null: "Email & phone number are  required  for  sr.no",
  email_null: "Email is required  for  sr.no",
  mobile_null: "Phone number is required for  sr.no",
  first_name_null: "First name is required for  sr.no",
  last_name_null: "Last name is required for  sr.no :-",
  company_id_invalid: "Company ID is invalid for  sr.no :-",
  access_id_invalid: "Access ID is  invalid for  sr.no :-",
  email_invalid: "Email ID is invalid for  sr.no :-",
  mobile_invalid: "Phone number is invalid for  sr.no :-",
  extension_invalid: "Extension is invalid for  sr.no :-",
  ssn_invalid: "SSN number is invalid for  sr.no :-",
  hire_data_invalid: "Hiring date is invalid  for  sr.no :-",
  email_repeat: "Repeated email ID are :-",
  mobile_repeat: "Repeated phone number are :-",
  email_id_exist_database: "Email id is already registered are :-",
  mobile_no_exist_database: "Phone number is already registered are :-",
};

const Employee = () => {
  const [loading, setLoading] = React.useState(false);
  const [pagination, setPagination] = React.useState<any>({});
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const { selectedCompany } = useOutletContext<any>();

  console.log('selected', selectedCompany);
  

  const [search, setSearch] = React.useState<any>("");

  const [employees, setEmployees] = React.useState<any>([]);

  const [importData, setImportData] = React.useState<any>({
    myfile: null,
    record_size: 0,
  });

  const [importFile, setImportFile] = React.useState<any>(false);
  const [importFileError, setImportFileError] = React.useState<any>(null);

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          href={"javascript:void(0)"}
          onClick={() =>
            navigate("/dashboard/employee/edit/" + selectedUser?.emp_id)
          }
        >
          Edit
        </a>
      ),
    },
  ];

  const [selectedUser, setSelectedUser] = React.useState<any>({});

  const updateEmployee = async (data: any, id: number) => {
    console.log(data);
    try {
      setLoading(true);
      const obj = { ...data };
  
      delete obj?.photo_url;
      delete obj?.access_id;
  
      if (id) {
        const dataum = await DashboardAPI.updateEmployeeById(id, obj);
  
        if (dataum?.success) {
          getEmployees();
          toast.success("Employee updated successfully");
        }
      } else {
        console.error("Employee ID is undefined");
      }
    } catch (err: any) {
      console.log(err);
      const errMsg =
        err?.response?.data?.message?.toString() ||
        err?.message ||
        "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      render: (text: any, data: any) => (
        <Switch
          checked={text}
          onChange={(e) =>
            updateEmployee({ ...data, is_active: !text }, data.emp_id)
          }
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text: any, data: any) => (
        <a onClick={() => navigate("/dashboard/employee/edit/" + data?.emp_id)}>
          {data?.first_name + " " + data?.last_name}
        </a>
      ),
      sorter: (a: any, b: any) => a.email.length - b.email.length,
    },
    {
      title: "Email ",
      dataIndex: "email",
      key: "email",
      sorter: (a: any, b: any) => a.email.length - b.email.length,
    },
    {
      title: "Phone",
      dataIndex: "mobile_no",
      key: "mobile_no",
    },
    {
      title: "Employee Code",
      dataIndex: "emp_code",
      key: "emp_code",
    },
    {
      title: "SSN",
      dataIndex: "ssn",
      key: "ssn",
    },
    {
      title: "Action",
      render: (text: any) => {
        return (
          <Dropdown menu={{ items }} placement="bottomRight" arrow>
            <button
              className="border-[1px] border-[#2E3147] rounded-[4px] w-[30px] h-[30px] flex items-center justify-center"
              onMouseEnter={() => setSelectedUser(text)}
            >
              <i className="bi bi-three-dots-vertical"></i>
            </button>
          </Dropdown>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const getEmployees = async () => {
    try {
      setLoading(true);

      const data = await DashboardAPI.getAllEmployee({
        company: selectedCompany
      });

      if (data.success) {
        console.log("data :: ", data);
        setEmployees(data?.data?.values?.results);
        setPagination({
          total: data?.data?.values?.total,
          pageSize: data?.data?.values?.limit,
          // current: data?.data?.current_page,
        });
      }
    } catch (err: any) {
      console.log("some error occred :: ", err);
      const errMsg =
        err?.response?.data?.message || err?.message || "something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    selectedCompany && getEmployees();
  }, [selectedCompany]);

  useEffect(() => {
    if (search.length > 2 || search.length === 0) getEmployees();
  }, [search]);

  const exportEmployee = async () => {
    try {
      setLoading(true);
      const data = await DashboardAPI.postExportEmployee({
        company_id: Number(selectedCompany),
        filetype: fileType,
        emp_id: [...employees.map((item: any) => item.emp_id)],
      });

      if (data.success) {
        toast.success("Exported successfully");
        window.open(BASE_URL + data?.message?.split("pms/")[1], "_blank");
        // setExportLink(BASE_URL + data?.message?.split("pms/")[1]);
      }
    } catch (err: any) {
      console.log("some error occred :: ", err);
      const errMsg =
        err?.response?.data?.message || err?.message || "something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const importEmployeeCheck = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("myfile", importData.myfile);
      formData.append("company_id", selectedCompany);
      formData.append("record_size", importData.record_size);

      const data = await DashboardAPI.postImportEmployeeCheck(formData);

      if (data.success) {
        toast.success("File is valid");
        setImportFile(true);
        setImportFileError(null);
      }
    } catch (err: any) {
      console.log("some error occred :: ", err);
      // console.log("err :: ", err?.response?.data?.data?.file_error);
      let errTxt = "";
      if (err?.response?.data?.data?.file_error) {
        Object.keys(err?.response?.data?.data?.file_error).map((item: any) => {
          if (err?.response?.data?.data?.file_error[item].length > 0) {
            errTxt +=
              errorLabelMap[item as keyof typeof errorLabelMap] +
              " : " +
              err?.response?.data?.data?.file_error[item].join(", ") +
              "\n";
          }
        });
      }
      setImportFileError(errTxt);
      const errMsg =
        err?.response?.data?.message || err?.message || "something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const importEmployee = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("myfile", importData.myfile);
      formData.append("company_id", selectedCompany);
      formData.append(
        "emp_code",
        JSON.parse(localStorage.getItem("user") || "{}")?.emp_id
      );

      const data = await DashboardAPI.postImportEmployee(formData);

      if (data.success) {
        toast.success("Imported successfully");
        setImportModalOpen(false);
        getEmployees();
      }
    } catch (err: any) {
      console.log("some error occred :: ", err);
      const errMsg =
        err?.response?.data?.message || err?.message || "something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const [modalOpen, setModalOpen] = React.useState(false);
  const [importModalOpen, setImportModalOpen] = React.useState(false);

  const [locationName, setLocationName] = React.useState("");

  const [fileType, setFileType] = React.useState("xlsx");
  const [exportLink, setExportLink] = React.useState("");

  const itemsInfo: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <p className="max-w-[450px]">
          Excel file should only have this column are in any format :- sr.no,
          first_name, middle_name, last_name, email_id, mobile_no, ssn,
          hire_data, company_id, access_id, extension.
          <br className="py-[10px]" />
          1) sr.no is mandatory. it a number of record eg :- 1,2,3.
          <br />
          2) first_name is mandatory. eg :- Sanket,Rahim ,Siddique.
          <br />
          3) middle_name & last_name is not mandatory. eg :- Shanvi, Abdul,
          khan.
          <br />
          4) email_id is mandatory. eg :- rahimabdul@gmail.com, new@hotmail.com
          <br />
          5) mobile_no is mandatory. must have 10 digit eg :- 8454954599,
          9685743151.
          <br />
          6) ssn is mandatory. must have 9 digit eg :- 252525361, 965874231.
          <br />
          7) hire_data is mandatory. must in format :- 02/09/2023, 21/10/2002.
          <br />
          6) company_id is mandatory must be registered company_id.
          <br />
          8) access_id is not mandatory & default is 4. if you provide it it
          must be eg :- 2 = HR, 3=accountant , 4=employee
          <br />
          7) extension is mandatory. eg :- +1,+91,1,91
          <br />
        </p>
      ),
    },
  ];

  const itemsSample: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a href="/assets/sample.csv" download className="max-w-[450px]">
          Download CSV
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a href="/assets/sample.xlsx" download className="max-w-[450px]">
          Download XLSX
        </a>
      ),
    },
  ];

  return (
    <div className="w-[100%] max-h-[100vh] pb-[100px]">
      <ToastContainer />
      {loading ? <Loader /> : null}
      <div className="mt-[20px] flex gap-[15px]">
        <p className="font-semibold text-[18px] text-[var(--secondary)]">
          Employees
        </p>
        <div className="flex items-center ml-auto justify-center md:block border-[1px] border-[#e5e5e5] px-[10px]">
          {/* <img src="./assets/search.png" className="object-contain mr-[10px] " alt="" />  */}
          <input
            type="text"
            onChange={(e) => setSearch(e.target.value)}
            className="focus:border-0 outline-0 h-[100%] "
            placeholder={"Search"}
          />
        </div>

        <button
          title="Import All"
          className="border-[1px] border-[#000]/[0.2] rounded-[4px]  p-[5px] text-[14px] flex items-center justify-center"
          onClick={() => setImportModalOpen(true)}
        >
          <i
            title="Import All"
            className="bi bi-file-earmark-arrow-up text-[#000]/[0.5] text-[20px] font-semibold"
          ></i>
        </button>
        <button
          title="Export All"
          className="border-[1px] border-[#000]/[0.2] rounded-[4px]  p-[5px] text-[14px] flex items-center justify-center"
          onClick={() => setModalOpen(true)}
        >
          <i
            title="Export All"
            className="bi bi-file-earmark-arrow-down-fill text-[#000]/[0.5] text-[20px] font-semibold"
          ></i>
        </button>
        <button
          className=" text-white rounded-[4px] text-[14px] bg-[var(--primary)] ml-auto md:ml-[10px] py-[7px] px-[20px]"
          onClick={(e) => {
            navigate(ADD_EMPLOYEE);
          }}
        >
          New
        </button>
      </div>

      <div className="mt-[30px] w-[100%] overflow-x-scroll md:overflow-hidden">
        <Table columns={columns} dataSource={employees} pagination={false} />
        <div className="w-[100%] mt-[30px] items-end justify-end flex">
          <CustomPagination
            total={pagination?.total}
            current={currentPage}
            onChange={(page: any, pageSize: any) => {
              setCurrentPage(page === 0 ? 1 : page);
              setPageSize(pageSize);
            }}
          />
        </div>
      </div>

      <ModalContainer
        title="Export Employee Data"
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        closeModal={() => setModalOpen(false)}
        width={40}
      >
        <div>
          <div className="grid grid-cols-2 gap-[10px]">
            <div className="flex flex-col col-span-2">
              <label className="text-[#333333] mb-[10px] opacity-70 font-semibold text-[14px]">
                Select File Type <span className="text-[red]">*</span>
              </label>
              <Radio.Group
                value={fileType}
                buttonStyle="solid"
                className="flex bg-[#F5F5F5] rounded-[4px] p-[10px]"
                onChange={(e) => {
                  setFileType(e.target.value);
                }}
              >
                <Radio.Button value="xlsx">XLSX</Radio.Button>
                <Radio.Button value="csv">CSV</Radio.Button>
                <Radio.Button value="xls">XLS</Radio.Button>
              </Radio.Group>
            </div>

            <div className="col-span-2">
              {exportLink && (
                <p className="text-[12px] my-[10px] text-[green]">
                  File Exported successfully, please click download button to
                  download file.
                  {/* <span className="text-[blue] opacity-50 font-medium text-[14px] cursor-pointer hover:font-bold"
                    onClick={() => {
                      
                    }}
                    >
                      Download File
                    </span> */}
                </p>
              )}
            </div>
          </div>

          <div className="flex items-end justify-end gap-[15px] mt-[30px]">
            <button
              className="border-[#2E3147] border-[1px] font-semibold py-[8px] px-[16px] rounded-[4px] w-[fit-content] text-[#2E3147] flex gap-[10px] text-[14px] mr-[10px]"
              onClick={() => setModalOpen(false)}
            >
              Cancel
            </button>
            {exportLink ? (
              <button
                className="bg-[#2E3147] animate-shake-fast font-semibold py-[8px] px-[16px] rounded-[4px] w-[fit-content] text-white flex gap-[10px] text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  setModalOpen(false);
                  setExportLink("");
                  window.open(exportLink, "_blank");
                }}
              >
                Download
              </button>
            ) : (
              <button
                className="bg-[#2E3147] font-semibold py-[8px] px-[16px] rounded-[4px] w-[fit-content] text-white flex gap-[10px] text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  exportEmployee();
                }}
              >
                Export
              </button>
            )}
          </div>
        </div>
      </ModalContainer>

      {/* IMPORT MODAL */}
      <ModalContainer
        title="Import Employee Data"
        modalOpen={importModalOpen}
        setModalOpen={setImportModalOpen}
        closeModal={() => {
          setImportFileError("");
          setImportModalOpen(false);
        }}
      >
        <div>
          <div className="relative mb-[30px] mt-[-50px] flex gap-[20px] items-center justify-end">
            <Dropdown
              menu={{ items: itemsInfo }}
              className="cursor-pointer"
              placement="bottomRight"
            >
              <i className="bi bi-question-circle-fill  bottom-[0px] right-[0px]"></i>
            </Dropdown>

            <Dropdown
              menu={{ items: itemsSample }}
              className="cursor-pointer"
              placement="bottom"
            >
              <button className="border-[#2E3147] border-[1px] font-semibold py-[8px] px-[16px] rounded-[4px] w-[fit-content] text-[#2E3147] flex gap-[10px] text-[14px] mr-[10px]">
                Sample File
              </button>
            </Dropdown>
          </div>
          <div className="grid grid-cols-2 gap-[10px]">
            <div className="flex flex-col col-span-2">
              <label className="text-[#333333] mb-[5px] opacity-70 font-semibold text-[14px]">
                Select File <span className="text-[red]">*</span>
              </label>
              <input
                type="file"
                className="border-[1px] text-[14px] rounded-[4px] p-[10px] mt-[5px]"
                onChange={(e: any) => {
                  console.log(e.target.files[0]);
                  setImportData((prev: any) => ({
                    ...prev,
                    myfile: e.target.files[0],
                  }));
                }}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
            </div>

            <div className="flex flex-col col-span-2">
              <label className="text-[#333333] mb-[5px] opacity-70 font-semibold text-[14px]">
                Record Size <span className="text-[red]">*</span>
              </label>
              <Input
                type="number"
                handleChange={(e: any) =>
                  setImportData((prev: any) => ({
                    ...prev,
                    record_size: e.target.value,
                  }))
                }
                placeholder={"Enter Record Size"}
              />
            </div>

            <div className="flex col-span-2">
              {importFileError && (
                <p className="text-[red] text-[12px]">{importFileError}</p>
              )}
            </div>
          </div>

          <div className="flex items-end justify-end gap-[15px] mt-[30px]">
            <button
              className="border-[#2E3147] border-[1px] font-semibold py-[8px] px-[16px] rounded-[4px] w-[fit-content] text-[#2E3147] flex gap-[10px] text-[14px] mr-[10px]"
              onClick={() => setImportModalOpen(false)}
            >
              Cancel
            </button>
            {importFile ? (
              <button
                className="bg-[#2E3147] animate-shake-fast font-semibold py-[8px] px-[16px] rounded-[4px] w-[fit-content] text-white flex gap-[10px] text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  importEmployee();
                }}
              >
                Import
              </button>
            ) : (
              <button
                className="bg-[#2E3147] font-semibold py-[8px] px-[16px] rounded-[4px] w-[fit-content] text-white flex gap-[10px] text-[14px]"
                onClick={(e) => {
                  e.preventDefault();
                  importEmployeeCheck();
                }}
              >
                Check File
              </button>
            )}
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default Employee;
