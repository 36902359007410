import React, { FC, useEffect, useState } from "react";
import Topbar from "../Topbar/Topbar";
import Sidebar from "../Sidebar/Sidebar";
import { DashboardAPI } from "../../apis/dashboardApi";
import { Outlet } from "react-router-dom";
type Props = {
  children?: JSX.Element;
  company?: any;
};

const DashboardLayout: FC<Props> = ({ company }) => {
  const [toggle, setToggle] = useState(false);

  const [slectedCompany, setSlectedCompany] = useState<any>(null);
  const [companyList, setCompanyList] = useState<any>([]);
  const [selected, setSelected] = React.useState("Employees");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCompanyCreate, setIsCompanyCreate] = useState(false);
  const [isEmployeeCreated, setIsEmployeeCreadted] = useState(false)
  const [isPayscheduleCreated, setIsPayscheduleCreadted] = useState(false)
  const [isPriorPayrollCreated, setIsPriorPayrollCreadted] = useState(false)
  const [isNewBusiness, setIsNewBusiness] = useState(false);


  const getCompanies = async () => {
    try {
      const data = await DashboardAPI?.getCompany();
      console.log(data)
      setCompanyList(data?.data?.values || []);
      if (!localStorage.getItem("company_id") && data?.data?.values?.length > 0) {
        setSlectedCompany(data?.data?.values[0]?.company_id);
        localStorage.setItem("company_id", data?.data?.values[0]?.company_id);
      } else if(data?.data?.values?.length > 0) {
        setSlectedCompany(localStorage.getItem("company_id"));
      }
      else{
        setSlectedCompany(null)
      }

    } catch (err: any) {
      console.log(err);
    } finally {
      // setLoading(false)
    }
  };

  useEffect(() => {
    getCompanies();
  }, [company]);

  const getData = async () => {
    let user: any = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
      if (slectedCompany && user?.emp_id) {
        setLoading(true);
        try {
          const response = await DashboardAPI.getInitialSetup(user?.emp_id, slectedCompany);
          setLoading(false);
          let count = 0;
          if (response?.data?.values?.is_company_create) count++;
          if (response?.data?.values?.is_employee_create) count++;
          if (response?.data?.values?.is_payschedule) count++;
          if (response?.data?.values?.is_prior || response?.data?.values?.is_new_business) count++;
          setProgress(Math.round((count / 4) * 100))
          setIsCompanyCreate(response?.data?.values?.is_company_create || false);
          setIsEmployeeCreadted(response?.data?.values?.is_employee_create || false);
          setIsPayscheduleCreadted(response?.data?.values?.is_payschedule || false)
          setIsPriorPayrollCreadted(response?.data?.values?.is_prior || false)
          setIsNewBusiness(response?.data?.values?.is_new_business || false)
        } catch (error) {
          console.error('Error sending data:', error);
          setLoading(false);
          throw error;
        }
      }
    }

  }
  useEffect(() => {
    getData()
  }, [slectedCompany])


  return (
    <div className="min-h-[100vh] max-h-[100vh] overflow-y-hidden h-[100vh] flex flex-col">
      <div
        className={`fixed top-0 left-0 w-full h-full bg-[grey] opacity-20 z-50 sm:hidden backdrop-blur-md ${toggle ? 'hidden' : 'block'}`}
        onClick={() => setToggle(false)}
      ></div>
      <Topbar setToggle={setToggle} />

      <div className="flex min-h-[100%]">
        <div
          className={
            "sm:w-[20%] w-[50%] sm:hidden md:block h-[100%] bg-[#fff] sm:min-h-[100%] menubar" +
            (toggle ? " translate-x-[-100%] absolute z-[500] h-[100%]" : " ")
          }
        >
          <Sidebar
            toggle={toggle}
            setToggle={setToggle}
            company={companyList}
            selectedCompany={slectedCompany}
            setSlectedCompany={setSlectedCompany}
            isEmployeeCreated={isEmployeeCreated}
            isPayscheduleCreated={isPayscheduleCreated}
            isPriorPayrollCreated={isPriorPayrollCreated}
            isNewBusiness={isNewBusiness}
          />
        </div>
        <div
          className={
            " p-[10px] sm:p-[15px]  mx-auto sm:hidden md:block sm:overflow-y-scroll overflow-scroll min-h-[100%] " +
            (toggle ? "w-[100%]" : "w-[80%]")
          }
        >
          <Outlet context={{ selectedCompany: slectedCompany, setSlectedCompany:setSlectedCompany }} />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
