import React, { useState } from 'react'
import Input from '../../components/Input/Input'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/index'
import { useNavigate } from 'react-router-dom'
import { REGISTER } from '../../navigation/Constant'
import { LOGIN } from '../../navigation/Constant'
import { EMAIL_REGEX } from '../../constants/regex'
import OtpInput from '../../components/OtpInput/OtpInput'
import { AuthAPI } from '../../apis/authAPI'
import { RESET } from '../../navigation/Constant'
const logo = require('../../assets/images/payweek.png')

const initialErrorState = {
    error: false,
    msg: ''
}

const ForgotPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    const [otp, setOtp] = useState('')

    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: any) => {

        if (!email) {
            return toast.error('Please fill all the details', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        }

        try {
            setLoading(true)
            const data = await AuthAPI.postForgotPassword({ email })

            if (data.success) {
                setEmailSent(true);
                toast.success(data.message);
            }

        } catch (error: any) {
            console.log('here')
            const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
            console.log(errMsg)
            return toast.error(errMsg, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        } finally {
            setLoading(false)
        }
    }


    const submitOtp = async (e: any) => {

        if (!otp) {
            return toast.error('Please fill all the details', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        }

        try {
            setLoading(true)
            const data = await AuthAPI.postResetPasswordEmailOTP({ email, email_otp: Number(otp) })

            if (data.success) {
                toast.success(data.message);
                sessionStorage.setItem('passwordReset', email)
                navigate(RESET)
            }

        } catch (error: any) {
            console.log('here')
            const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
            console.log(errMsg)
            return toast.error(errMsg, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='bg-[#F4F4F496]'>
            <ToastContainer />
            {
                loading && <Loader />
            }
            <div className='min-h-[100vh] container py-[30px]  mx-auto flex flex-col items-center'>

                <div className='flex w-[100%] items-start sm:items-center justify-center sm:justify-start sm:mb-[60px] mb-[25px]'>
                    <img src={logo} alt="" className='sm:w-[180px] w-[140px]' />
                </div>
                <div className='flex flex-col gap-[10px] card w-[80%] md:w-[40%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#ffffff] py-[30px] px-[20px] rounded-[8px]  mx-auto'>
                    <h1 className='text-center text-[#5ABC4BE0] sm:text-3xl text-2xl font-bold'>Reset your Password</h1>
                    <h3 className='text-center text-2xl font-bold'>
                        Your email
                    </h3>
                    <div className='flex flex-col gap-[10px] w-[70%] mt-[30px] mx-auto'>
                        <Input
                            disabled={emailSent}
                            label="Email" name="email" type="email" placeholder="Enter your email" handleChange={(e: any) => setEmail(e.target.value)} />
                        {
                            emailSent ? <>
                                < OtpInput value={otp} valueLength={4} onChange={(value) => setOtp(value)} />
                            </> : null
                        }
                        <p className='text-right hover:underline cursor-pointer' onClick={() => { navigate(LOGIN) }} >Back to Login</p>
                    </div>{
                        !emailSent ? <button className="bg-[#447EC1] text-[#fff] text px-[60px] mx-auto font-semibold rounded-[12px]  py-[8px] mt-[15px]" onClick={(e: any) => handleSubmit(e)}
                        >Reset Password</button> : <button className="bg-[#447EC1] text-[#fff] text px-[60px] mx-auto font-semibold rounded-[12px]  py-[8px] mt-[15px]" onClick={(e: any) => submitOtp(e)}
                        >Submit OTP</button>
                    }
                </div>

            </div>
        </div>

    )
}

export default ForgotPassword 