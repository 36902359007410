import React, { useEffect, useState } from "react";
import { DashboardAPI } from "../apis/dashboardApi";

const withDashboard = (WrappedComponent: any) => {
  const WithAccess = () => {
    const [access, setAccess] = React.useState(false);
    const [role, setRole] = React.useState("");
    const [masterData, setMasterData] = useState<any>({});

    const getMasterData = async () => {
      try {
        const data = await DashboardAPI.getDetails();
        if (data.success) {
          setMasterData(data.data?.form_details);
          localStorage.setItem(
            "masterData",
            JSON.stringify(data.data?.form_details)
          );
          return data.data?.form_details;
        }
      } catch (err: any) {
        console.log("some error occred :: ", err);
        const errMsg =
          err?.response?.data?.message ||
          err?.message ||
          "something went wrong";
        // toast.error(errMsg)
        return {};
      } finally {
        // setLoading(false)
      }
    };

    useEffect(() => {
      const masterData = localStorage.getItem("masterData")
        ? JSON.parse(localStorage.getItem("masterData") || "{}")
        : getMasterData();

      const data = JSON.parse(localStorage.getItem("admin_access") || "{}");
      const role = JSON.parse(localStorage.getItem("admin_role") || "null");
      if (data) {
        setAccess(data);
        setRole(role);
        setMasterData(masterData);
      }
    }, []);
    return (
      <WrappedComponent access={access} role={role} masterData={masterData} />
    );
  };

  return WithAccess;
};

export default withDashboard;
