import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";

export const DashboardAPI = {
  postRoutingNumber: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/routing_number",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  postAddEmployee: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/emp",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getAllEmployee: async (query, cancel = false) => {
    const response = await api.request({
      url: "payroll/emp",
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      params: query,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getEmployeeById: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/emp/" + id,
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  updateEmployeeById: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/emp/" + id,
      method: "PUT",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postImportEmployeeCheck: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/check_excel_xlxs_csv",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "multipart/form-data",
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postImportEmployee: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/excel_data_upload_xlxs_csv",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postExportEmployee: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/export_emp_detail",
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  postEmployeeCompensation: async (emp_id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/compensation/" + emp_id,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getEmployeeCompensation: async (emp_id, cancel = false) => {
    const response = await api.request({
      url: "payroll/compensation/" + emp_id,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  putEmployeeCompensation: async (id, emp_id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/compensation/" + emp_id + "/" + id,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postEmployeeBenefits: async (emp_id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/benefitdeduct/" + emp_id,
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getEmployeeBenefits: async (emp_id, cancel = false) => {
    const response = await api.request({
      url: "payroll/benefitdeduct/" + emp_id,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  putEmployeeBenefits: async (id, emp_id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/benefitdeduct/" + emp_id + "/" + id,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel 
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postAddComp: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/comp",
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  updateCompanyById: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/comp/" + id,
      method: "PUT",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getDetails: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/form_details",
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getCompany: async (cancel = false) => {
    const response = await api.request({
      url: "payroll/comp",
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getCompanyById: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/comp/" + id,
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postAddCustomer: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/customer",
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getCustomer: async (cancel = false) => {
    const response = await api.request({
      url: "payroll/customer",
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postUpdateCustomer: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/customer/" + id,
      method: "PUT",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getCustomerById: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/customer/" + id,
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postAddInvoice: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/add_invoice",
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getInvoice: async (cancel = false) => {
    const response = await api.request({
      url: "payroll/add_invoice",
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postUpdateInvoiceById: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/update_invoice/" + id,
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getInvoiceById: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/update_invoice/" + id,
      method: "GET",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postSalesTax: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/saletax",
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postInvoiceAction: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/pdf_invoice",
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  postDailyTimeSheet: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/update_daily_timesheet/" + id,
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  postWeeklyTimeSheet: async (company_id, data, cancel = false) => {
    const response = await api.request({
      url: `payroll/update_weekly_timesheet/${company_id}`,
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },
  getDailyTimesheet: async (id, queries, cancel = false) => {
    const response = await api.request({
      url: "payroll/update_daily_timesheet/" + id,
      method: "GET",
      params: queries,
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  postRunPayroll: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/emp_pay",
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  postApprovePayroll: async (data, cancel = false) => {
    const response = await api.request({
      url: "payroll/save_emp_pay",
      method: "POST",
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getPayrollDates: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/next_payroll_dates/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  getApprovedPayrollsList: async (id = "", query, cancel = false) => {
    const response = await api.request({
      url: "payroll/payrolls_list/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: query,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  postApprovedPayrolls: async (data, cancel = false) => {
    console.log("approved payrolls");
    const response = await api.request({
      url: "payroll/approved_payrolls",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postPaychecks: async (id, data, cancel = false) => {
    console.log("approved payrolls");
    const response = await api.request({
      url: "payroll/print_checks/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postGeneratepaychecks: async (id, data, cancel = false) => {
    console.log("approved payrolls");
    const response = await api.request({
      url: "payroll/print_payslip/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postpdfpaysleep: async (id, data, cancel = false) => {
    console.log("approved payrolls");
    const response = await api.request({
      url: "payroll/pdf_payslip/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postExportcsv: async (id, data, cancel = false) => {
    console.log("export csv");
    const response = await api.request({
      url: "payroll/export_csv/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postDeletepayroll: async (id, data, cancel = false) => {
    console.log("delete payroll");
    const response = await api.request({
      url: "payroll/delete_payroll/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postPayrollDates: async (id, data, cancel = false) => {
    // console.log("delete payroll")
    const response = await api.request({
      url: "payroll/prior_payroll_stage1/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postPayrollEmpList: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/prior_payroll_emplist_stage2/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postPayrollRestart: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/prior_payroll_restart/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postPayrollStage2Save: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/prior_payroll_stage2_save/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getShowStage: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/prior_payroll_show_stage/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  postPriorPayroll: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/prior_payroll/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getPayschedule: async (id, cancel = false) => {
    const response = await api.request({
      url: "payroll/payschedule/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  patchPayschedule: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/payschedule/" + id,
      method: "PATCH",
      data: data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  getInitialSetup: async (emp_id, id, cancel = false) => {
    const response = await api.request({
      url: "payroll/initalpayroll/" + emp_id + "/" + id,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
  patchInitialSetup: async (emp_id, id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/initalpayroll/" + emp_id + "/" + id,
      method: "PATCH",
      data,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      // data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  postWagesTaxEmpList: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/wages_tax_emplist/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  postWagesTaxEmpSummary: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/wages_tax_emp_summary/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },

  postWagesTaxEmpData: async (id, data, cancel = false) => {
    const response = await api.request({
      url: "payroll/wages_tax_emp_data/" + id,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: data,
      signal: cancel
        ? cancelApiObject[this.get.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(DashboardAPI);
