import React, { useEffect, useState } from "react";
import Input from "../../../components/Input/Input";
import { Progress, Select } from "antd";
import { DashboardAPI } from "../../../apis/dashboardApi";
import Loader from "../../../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { APPROVED_PAYROLLS, PAYROLL_SETUP } from "../../../navigation/Constant";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Modal } from "antd";
import moment from "moment";

const RunPayroll = () => {
  const navigate = useNavigate();

  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedCompany } = useOutletContext<any>();

  const [formErrors, setFormErrors] = useState<any>({});

  const [company, setCompany] = useState<any>([]);
  const [reviewed, setReviewed] = useState<any>(false);

  const [payrollData, setPayrollData] = useState<any>([]);
  const [nextWeekSelected, setNextWeekSelected] = useState(false);

  const [isApprovePayrollEnabled, setIsApprovePayrollEnabled] =
    useState<boolean>(false);

  const getCompanies = async () => {
    try {
      setLoading(true);
      const data = await DashboardAPI?.getCompany();

      setCompany(
        data?.data?.values?.map((item: any) => {
          return {
            label: item?.company_name,
            value: item?.company_id,
          };
        }) || []
      );
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const updatePayroll = async () => {
    if (!data?.start_date) {
      setFormErrors((prev: any) => ({
        ...prev,
        start_date: { error: true, msg: `Start Date is required` },
      }));
      return;
    }

    if (!data?.end_date) {
      setFormErrors((prev: any) => ({
        ...prev,
        end_date: { error: true, msg: `End Date is required` },
      }));
      return;
    }

    if (!data?.pay_date) {
      setFormErrors((prev: any) => ({
        ...prev,
        pay_date: { error: true, msg: `Pay Date is required` },
      }));
      return;
    }

    if (!selectedCompany) {
      toast.error("Please select company");
      return;
    }

    try {
      setLoading(true);
      const res = await DashboardAPI?.postRunPayroll({
        ...data,
        pay_date: moment(data?.pay_date, "DD-MM-YYYY").format("DD-MM-YYYY"),
        start_date: moment(data?.start_date, "DD-MM-YYYY").format("DD-MM-YYYY"),
        end_date: moment(data?.end_date, "DD-MM-YYYY").format("DD-MM-YYYY"),
        company_id: selectedCompany,
      });

      if (res.success) {
        setReviewed(true);
        toast.success(res.message);
        const arr: any = [];
        Object.keys(res?.data?.form_details).filter((item: any) => {
          if (res?.data?.form_details[item]?.emp_name) {
            arr.push({ ...res?.data?.form_details[item], emp_id: item });
          }
        });

        arr.forEach((element: any) => {
          element.allState = false;
        });
        setPayrollData(arr);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const approvePayroll = async () => {
    if (!data?.start_date) {
      setFormErrors((prev: any) => ({
        ...prev,
        start_date: { error: true, msg: `Start Date is required` },
      }));
      return;
    }

    if (!data?.end_date) {
      setFormErrors((prev: any) => ({
        ...prev,
        end_date: { error: true, msg: `End Date is required` },
      }));
      return;
    }

    if (!data?.pay_date) {
      setFormErrors((prev: any) => ({
        ...prev,
        pay_date: { error: true, msg: `Pay Date is required` },
      }));
      return;
    }

    if (!selectedCompany) {
      toast.error("Please select company");
      return;
    }

    if (!reviewed) {
      toast.error("Please review payroll first");
      return;
    }

    const postData = payrollData.map((item: any) => {
      return { [item?.emp_id]: { ...item } };
    });

    const arr = Object.assign({}, ...postData);

    try {
      setLoading(true);
      const res = await DashboardAPI?.postApprovePayroll({
        emp_data: {
          ...data,
          pay_date: data?.pay_date,
          start_date: data?.start_date,
          end_date: data?.end_date,
          company_id: selectedCompany,
          ...arr,
        },
      });

      if (res.success) {
        // console.log(res);
        // console.log(res?.data?.result)
        // console.log(moment(res?.data?.result?.end_date).format())
        // console.log(moment(res?.data?.result?.pay_date).format())
        toast.success("Approval Successful");
        // getPayrollDates();
        setData((prev: any) => ({
          ...prev,
          start_date: res?.data?.result?.start_date,
          end_date: res?.data?.result?.end_date,
          pay_date: res?.data?.result?.pay_date,
        }));
        setIsApprovePayrollEnabled(
          moment(res?.data?.result?.end_date, "DD-MM-YYYY").unix() < moment().unix()
        );
        setIsModalOpen(false);

        setNextWeekSelected(true);
        const arr: any = [];
        if (res?.data?.form_details) {
          Object.keys(res?.data?.form_details).filter((item: any) => {
            if (res?.data?.form_details[item]?.emp_name) {
              arr.push(res?.data?.form_details[item]);
            }
          });
        }

        arr.forEach((element: any) => {
          element.allState = false;
        });
        setPayrollData(arr);
        // const { startDate, endDate } = getNextWeekDates();
        // setData((prev: any) => ({
        //   ...prev,
        //   start_date: startDate,
        //   end_date: endDate,
        // }));
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // const getPayrollDates = async () => {
  //   if (!selectedCompany) {
  //     toast.error("Please select company");
  //     return;
  //   }

  //   try {
  //     setLoading(true);
  //     const res = await DashboardAPI?.getPayrollDates({
  //       company: Number(selectedCompany),
  //     });

  //     if (res.success) {
  //       console.log(res);
  //       let { start_date, end_date, pay_date } = res?.data?.pay_slip;

  //       start_date = start_date?.split("-").reverse().join("-");
  //       end_date = end_date?.split("-").reverse().join("-");
  //       pay_date = pay_date?.split("-").reverse().join("-");

  //       if (!start_date || !end_date || !pay_date) {
  //         return;
  //       }

  //       setData((prev: any) => ({
  //         ...prev,
  //         start_date,
  //         end_date,
  //         pay_date,
  //       }));
  //     }
  //   } catch (err: any) {
  //     console.log(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const getPayrollDates = async () => {
    // if (!selectedCompany) {
    //   toast.error("Please select company");
    //   return;
    // }

    try {
      setLoading(true);
      const res = await DashboardAPI?.getPayrollDates(selectedCompany);

      if (res.success) {
        const { start_date, end_date, pay_date } = res?.data?.pay_slip;
        setData({
          start_date,
          end_date,
          pay_date,
        });
        console.log({
          start_date,
          end_date,
          pay_date,
        })
        setIsApprovePayrollEnabled(
          moment(end_date, "DD-MM-YYYY").unix() < moment().unix()
        );
        console.log("end Date ::: ", moment(end_date, "DD-MM-YYYY").unix(), "today ::: ", moment().unix(),
          (moment(end_date, "DD-MM-YYYY").unix() < moment().unix()).toString()
        );
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getNextWeekDates = () => {
    const today = new Date();
    const nextWeek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
    const startDate = nextWeek.toISOString().split("T")?.[0];
    const endDate = new Date(nextWeek.getTime() + 6 * 24 * 60 * 60 * 1000)
      .toISOString()
      .split("T")?.[0];
    return { startDate, endDate };
  };

  const [nextWeekDates, setNextWeekDates] = useState();

  useEffect(() => {
    getPayrollDates();
    getCompanies();
  }, []);

  useEffect(() => {
    getPayrollDates();
  }, [selectedCompany]);
  

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <div className="mt-[20px]  items-cetner justify-between flex gap-[15px]">
        <p className="font-semibold text-[18px] text-[var(--secondary)]">
          Run Payroll
        </p>

        <div className="flex gap-[20px]">
          <button
            className="  rounded-[4px] text-[14px] border-[1px] border-[var(--primary)] text-[var(--primary)] ml-auto md:ml-[10px] py-[7px] px-[20px]"
            onClick={(e) => {
              navigate(PAYROLL_SETUP);
            }}
          >
            Payroll Setup
          </button>
          <button
            className="  rounded-[4px] text-[14px] border-[1px] border-[var(--primary)] text-[var(--primary)] ml-auto md:ml-[10px] py-[7px] px-[20px]"
            onClick={(e) => {
              navigate(APPROVED_PAYROLLS);
            }}
          >
            Approved Payrolls
          </button>
        </div>
      </div>
      <div className="mt-[20px]">
        <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
          <div className="content_box">
            <div className="header flex justify-between items-center p-[10px] bg-[#E6F4FF]">
              <p className="font-semibold m-0 text-[#000]/[0.6]"> </p>
              {/* <button
                className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                onClick={(e) => {
                  //   setAddCompensation(true);
                }}
              >
                Change Salary
              </button> */}
            </div>

            <div className="body p-[10px]">
              <div className="grid grid-cols-4 gap-[10px] ">
                <div className="col-span-1">
                  <Input
                    type="date"
                    name={"start_date"}
                    label={"Start Date"}
                    disabled
                    required
                    handleChange={(e: any) => {
                      setFormErrors({
                        ...formErrors,
                        start_date: { error: false, msg: `` },
                      });
                      setData((prev: any) => ({
                        ...prev,
                        start_date: e.target.value,
                      }));
                    }}
                    value={
                      moment(data?.start_date, "DD-MM-YYYY").format(
                        "YYYY-MM-DD"
                      ) || ""
                    }
                    errTxt={formErrors?.start_date?.msg}
                  />
                </div>

                <div className="col-span-1">
                  <Input
                    type="date"
                    name={"end_date"}
                    label={"End Date"}
                    disabled
                    required
                    handleChange={(e: any) => {
                      setFormErrors({
                        ...formErrors,
                        end_date: { error: false, msg: `` },
                      });
                      setData((prev: any) => ({
                        ...prev,
                        end_date: e.target.value,
                      }));
                    }}
                    value={
                      moment(data?.end_date, "DD-MM-YYYY").format(
                        "YYYY-MM-DD"
                      ) || ""
                    }
                    errTxt={formErrors?.end_date?.msg}
                  />
                </div>

                <div className="col-span-1">
                  <Input
                    type="date"
                    name={"pay_date"}
                    label={"Pay Date"}
                    required
                    handleChange={(e: any) => {
                      setFormErrors({
                        ...formErrors,
                        pay_date: { error: false, msg: `` },
                      });
                      setData((prev: any) => ({
                        ...prev,
                        pay_date: e.target.value,
                      }));
                    }}
                    value={
                      moment(data?.pay_date, "DD-MM-YYYY").format(
                        "YYYY-MM-DD"
                      ) || ""
                    }
                    errTxt={formErrors?.pay_date?.msg}
                  />
                </div>

                {/* <div className="col-span-1 flex flex-col">
                  <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                    Company <span className="text-[red]">*</span>
                  </label>
                  <Select
                    showSearch
                    className="border-[1px] h-[45px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                    placeholder="Search to Select"
                    options={company}
                    value={slectedCompany || null}
                    onChange={(e) => {
                      setFormErrors({
                        ...formErrors,
                        company: { error: false, msg: `` },
                      });
                      setSlectedCompany(e);
                    }}
                  />
                </div> */}
              </div>

              <div className="flex-col flex justify-start gap-[20px] mt-[30px]">
                <div>
                  <h6 className="text-[var(--secondary)] text-[14px] font-medium">
                    Everything looks good
                  </h6>
                  <p className="text-[#000]/[0.7] font-normal text-[12px]">
                    Payroll ready for approval
                  </p>
                </div>
                <span>
                  <button
                    className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[15px] py-[10px] w-[fit-content] text-[14px]"
                    onClick={(e) => {
                      updatePayroll();
                    }}
                  >
                    Review this payroll
                  </button>
                  {isApprovePayrollEnabled && (
                    <button
                      className="bg-[var(--primary)] text-[#fff] font-semibold rounded-[8px] px-[25px] py-[10px] w-[fit-content] text-[14px] ml-[10px]"
                      onClick={showModal}
                    >
                      Approve payroll
                    </button>
                  )}
                  <Modal
                    className="mt-48 "
                    title="Approve this payroll?"
                    open={isModalOpen}
                    onOk={(e) => {
                      approvePayroll();
                    }}
                    okText="Approve"
                    onCancel={handleCancel}
                  >
                    <p>Are you sure you want to approve this payroll?</p>
                  </Modal>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {payrollData?.length > 0 && (
        <div className="grid grid-cols-4 mt-[45px] pb-[200px]">
          <div className="col-span-4 mb-[8px]">
            <h2 className="text-[22px] font-bold">Pay Statements</h2>
          </div>
          {/* <div className="col-span-4 grid grid-cols-4 bg-[#000]/[0.1] p-[10px]">
          <div className="col-span-2">
            <p className="font-bold">Amount</p>
          </div>
          <div className="col-span-2">
            <p className="font-bold text-right">Effective Date</p>
          </div>
        </div> */}
          <div className="col-span-4 grid grid-cols-4 border-[1px] px-[10px] rounded-[20px]">
            {payrollData?.map((item: any, index: number) => {
              return (
                <div
                  className="col-span-4 cursor-pointer grid grid-cols-12 border-b-[1px] py-[20px] px-[15px]"
                  onClick={(e) => {
                    e.preventDefault();
                    const newArr = [...payrollData];
                    newArr[index]["allState"] = !newArr[index]["allState"];
                    setPayrollData(newArr);
                  }}
                  key={index}
                >
                  <div className="col-span-2">
                    <p className="font-bold capitalize">{item?.emp_name}</p>
                  </div>
                  <div className="col-span-8"></div>
                  {/* <div className="col-span-1">
                    <p className="font-normal text-right">
                      {item?.emp_type}
                    </p>
                  </div> */}
                  <div className="col-span-2 w-[100%] flex">
                    <p className="font-normal text-[#000]/[0.5] text-right">
                      {item?.emp_type}
                    </p>
                  </div>

                  {/* <div className="col-span-1"></div> */}
                  <div className="col-span-4">
                    <p className="font-normal text-[14px] capitalize">
                      {(() => {
                        if (item?.regular) {
                          return (
                            <span className=" rounded">
                              Regular{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.regular}
                              </span>
                            </span>
                          );
                        } else if (item?.overtime) {
                          return (
                            <span className=" rounded">
                              Overtime{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.overtime}
                              </span>
                            </span>
                          );
                        } else if (item?.doubletime) {
                          return (
                            <span className=" rounded">
                              Doubletime{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.doubletime}
                              </span>
                            </span>
                          );
                        } else if (item?.vacation) {
                          return (
                            <span className=" rounded">
                              Vacation{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.vacation}
                              </span>
                            </span>
                          );
                        } else if (item?.sicktime) {
                          return (
                            <span className=" rounded">
                              Sicktime{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.sicktime}
                              </span>
                            </span>
                          );
                        } else {
                          return (
                            <span className=" rounded">
                              Regular{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.regular}
                              </span>
                            </span>
                          );
                        }
                      })()}
                    </p>
                  </div>
                  <div className="col-span-4"></div>
                  <div className="col-span-3">
                    <p className="font-normal text-right">
                      $ {item?.net_wages?.[0]?.toFixed(2)}
                    </p>
                  </div>
                  <div className="col-span-1 w-[100%] flex">
                    {!item?.allState ? (
                      <DownOutlined className="ml-auto text-[var(--primary)] cursor-pointer font-bold text-[18px] " />
                    ) : (
                      <UpOutlined className=" ml-auto text-[var(--primary)] cursor-pointer font-bold text-[18px] " />
                    )}
                  </div>

                  {item?.allState && (
                    <div className="col-span-12 px-[5px] py-[10px] grid grid-cols-12  bg-[#e5e5e5]/[0.3] mt-[10px]">
                      <div className="col-span-5">
                        <p className="font-bold"></p>
                      </div>
                      <div className="col-span-3">
                        <p className="font-semibold text-[14px] text-right">
                          Rate
                        </p>
                      </div>
                      <div className="col-span-2">
                        <p className="font-semibold text-[14px] text-right">
                          Amount
                        </p>
                      </div>
                      <div className="col-span-2">
                        <p className="font-semibold text-[14px] text-right">
                          Year to date
                        </p>
                      </div>

                      <hr className="bg-[#000]/[0.6] h-[2px] col-span-12 my-[10px]" />

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Gross Wages
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right">-</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.gross_wages?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.gross_wages?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Regular Pay
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            $ {item?.rate} / hr
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.regular_pay?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.regular_pay?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Previous Bonuses
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                      </div>
                      {/* Outer section END */}

                      {/* Outer Section START */}

                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Taxes
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right">-</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.taxes?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.taxes?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Federal Income Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.fedral_income_tax?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.fedral_income_tax?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Medicare
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.medicare?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.medicare?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            State Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.state_tax?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.state_tax?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Emp City Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.emp_city_tax?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.emp_city_tax?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Comp City Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.comp_city_tax?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.comp_city_tax?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Social Security
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.social_security?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.social_security?.[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        {item?.city_tax1 && (
                          <>
                            <div className="col-span-5">
                              <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                                City Tax 1
                              </p>
                            </div>
                            <div className="col-span-3">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                            </div>
                            <div className="col-span-2">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right">
                                {item?.city_tax1 && item?.city_tax1?.[0]}
                              </p>
                            </div>
                            <div className="col-span-2">
                              <p className="text-[14px] text-[#000]/[0.5] text-right">
                                {/* {item?.city_tax1?.[1]} */}
                                {item?.city_tax1 &&
                                  item?.city_tax1?.[1].toFixed(2)}
                              </p>
                            </div>
                          </>
                        )}

                        {/* inner section */}
                        {item?.city_tax2 && (
                          <>
                            <div className="col-span-5">
                              <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                                City Tax 2
                              </p>
                            </div>
                            <div className="col-span-3">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                            </div>
                            <div className="col-span-2">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right">
                                {/* {item?.city_tax2?.[0]} */}
                                {item?.city_tax2 &&
                                  item?.city_tax2?.[0].toFixed(2)}
                              </p>
                            </div>
                            <div className="col-span-2">
                              <p className="text-[14px] text-[#000]/[0.5] text-right">
                                {/* {item?.city_tax2?.[1]} */}
                                {item?.city_tax2 &&
                                  item?.city_tax2?.[1].toFixed(2)}
                              </p>
                            </div>
                          </>
                        )}

                        {/* inner section */}

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                        {/* Outer section END */}
                      </div>

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Non-taxable items
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right">-</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Indirect Tips
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                      </div>
                      {/* Outer section END */}

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-semibold text-[14px] ml-[10px]">
                            Net wages
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.net_wages?.[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.net_wages?.[1].toFixed(2)}
                          </p>
                        </div>

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                      </div>
                      {/* Outer section END */}

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Non-taxable non-cash benefits
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>

                        <div className="col-span-12">
                          <p className="text-[14px] mt-[20px]">
                            Available vacation:{" "}
                            <span className="font-semibold">0 hours</span>
                          </p>
                        </div>
                      </div>
                      {/* Outer section END */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default RunPayroll;
