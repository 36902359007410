import React, { useState } from 'react'
// import { TeamOutlined, CheckCircleFilled } from '@ant-design/icons'
// import { useNavigate } from 'react-router-dom'
// import { EDIT_COMPANY, EMPLOYEE, PAYSCHEDULE, PRIOR_PAYROLLS } from '../../../navigation/Constant'
// import EditCompany from '../Company/EditCompany'
// import { Progress } from 'antd'
// import { Checkbox } from 'antd';
// import type { CheckboxProps } from 'antd';
// import PaySchedule from './PaySchedule'
// import PriorPayrolls from './PriorPayrolls'
// import { DashboardAPI } from '../../../apis/dashboardApi'


const DashboardHome = () => {
  // const [isNewBusiness, setIsNewBusiness] = useState(false);

  // const navigate = useNavigate()

  // const onChange: CheckboxProps['onChange'] = (e) => {
  //   setIsNewBusiness(e.target.checked);
  // };

  

  // const profilePercentage = () => {
  //   let count = 0;
  //   // if (DashboardAPI?.patchPayschedule) count++;
  //   if (<PriorPayrolls />) count++;
  //   return Math.round((count / 2) * 100);
  // };

  return (
    <div className='mb-[100px] min-h-[100vh]'>
      <div className='w-[100%]'>
        {/* <div className='my-[24px] mx-[20px] grid grid-cols-6 sm:gap-[62px] gap-[22px]  items-center'>
          <div className="col-span-4">
            <p className="font-semibold text-[18px] text-[var(--secondary)]">Payroll setup</p>
            <Progress className={`min-w-[100%] ${
                profilePercentage() < 70
                  ? "bg-error_light text-error  "
                  : profilePercentage() >= 70 && profilePercentage() <= 90
                  ? "bg-secondary_light text-secondary "
                  : " text-primary bg-primary_light"
              }`} strokeColor={'#4784CA'} percent={profilePercentage()}  />
            <p className="sm:text-[14px] text-[12px]">Ready to preview! Get the most out of payroll by completing the remaining steps.</p>
          </div>
          <div className="sm:col-span-2 col-span-4">
            <button className="border-2 font-semibold border-[var(--primary)] rounded-full py-[6px] px-4 whitespace-nowrap sm:text-[14px] text-[12px] text-[#fff] bg-[var(--primary)]"
            // onClick={() => navigate(PAYROLL_SETUP)}
            >Preview payroll</button>
          </div>
        </div> */}
        {/* <div className='mb-[100px]'>
          <div className='w-[100%] max-h-[100vh]'>
            <div className="border rounded-md m-[20px] p-[24px] shadow">
              <div className="flex items-center gap-2">
                <TeamOutlined className="text-[var(--primary)] text-[24px]" />
                <p className="text-[18px] font-semibold">Team</p>
              </div>
              <div className="mt-[20px] grid grid-cols-6 items-center justify-between">
                <div className='col-span-4'>
                  <div className="flex gap-4">
                    <div>
                      <CheckCircleFilled className="text-[var(--primary)] text-[24px]" />
                    </div>
                    <div>
                      <p className="text-[18px] font-semibold">Employees</p>
                      <p className="text-[14px] whitespace-nowrap">1 employee on payroll</p>
                    </div>
                  </div>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button className="border-2 border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] mt-[8px] sm:mt-[0px]"
                    onClick={() => navigate(EMPLOYEE)}
                  >Manage employees</button>
                </div>
              </div>
              <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                <div className="col-span-4">
                  <p className="text-[18px] font-semibold">Contractors  (Optional)</p>
                  <p className="text-[14px]">Add independent contractors to pay them directly and generate 1099 tax forms</p>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button className="border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] mt-[8px] sm:mt-[0px]">Add contractor</button>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[32px]">
            <div className="border rounded-md m-[20px] p-[24px] shadow">
              <div className="flex items-center gap-2">
                <i className="bi bi-buildings text-[var(--primary)] text-[24px]"></i>
                <p className="text-[18px] font-semibold">Business</p>
              </div>
              <div className="mt-[20px] grid grid-cols-6 items-center justify-between">
                <div className='col-span-4'>
                  <div className="flex gap-4">
                    <div>
                      <CheckCircleFilled className="text-[var(--primary)] text-[24px]" />
                    </div>
                    <div>
                      <p className="text-[18px] font-semibold">Business</p>
                      <p className="text-[14px]"><span className="font-semibold">Curry Cook LLC</span> - 25958 S. Knollwood Dr., Chesterfield, MI 48051, USA</p>
                    </div>
                  </div>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button className="border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] mt-[8px] sm:mt-[0px]"
                    onClick={() => navigate(EDIT_COMPANY)}
                  >Edit details</button>
                </div>
              </div>
              <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                <div className="col-span-4">
                  <p className="text-[18px] font-semibold">Pay schedule</p>
                  <p className="text-[14px] whitespace-nowrap">Pay schedule: <span className="font-semibold">Every month</span></p>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button className="border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px]"
                    onClick={() => navigate(PAYSCHEDULE)}
                  >Edit schedule</button>
                </div>
              </div>
              <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                <div className="col-span-4">
                  <p className="text-[18px] font-semibold">Company signatory</p>
                  <p className="text-[14px]"><span className="font-semibold">Mitesh Patel</span>- CEO</p>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button className="border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px]">Add signatory</button>
                </div>
              </div>
              <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                <div className="col-span-4">
                  <p className="text-[18px] font-semibold">Funding account</p>
                  <p className="text-[14px]">Add a bank account that will be used to fund your payroll</p>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button className="border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px]">Add account</button>
                </div>
              </div> 
              <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                <div className="col-span-4">
                  <p className="text-[18px] font-semibold">Tax profile</p>
                  <p className="text-[14px]">Edit your business's withholding schedule and tax rates.</p>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button className="border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px]">Add details</button>
                </div>
              </div>
              <div className="mt-[24px] grid grid-cols-6 items-center justify-between">
                <div className="col-span-4">
                  <p className="text-[18px] font-semibold">Prior payrolls</p>
                  <Checkbox onChange={onChange} className='text-[15px] font-semibold'>New business</Checkbox>
                  <p className="text-[14px]">Last pay period date: <span className="font-semibold">Sep 28, 2023</span> </p>
                </div>
                <div className='sm:col-span-2 col-span-4'>
                  <button
                    className={`border-2  border-[var(--primary)] rounded-full py-[4px] w-[180px] text-[14px] text-[var(--primary)] sm:mt-0 mt-[8px] ${isNewBusiness ? 'opacity-50 cursor-not-allowed' : ''}`}
                    onClick={() => navigate(PRIOR_PAYROLLS)}
                    disabled={isNewBusiness}
                  >
                    Add history
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
};

export default DashboardHome;
