import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../components/Loader";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { DashboardAPI } from "../../apis/dashboardApi";
import { useOutletContext } from "react-router-dom";
import moment from "moment";

const typesLabelKeyMap: any = {
  overtime: "Overtime",
  double_time: "Double Time",
  vacation_time: "Vacation Time",
  sick_time: "Sick Time",
  total: "Total",
};

interface Day {
  date: string;
  dayOfWeek: string;
  isDisabled?: boolean; // Optional for disabling past/future dates
}

const TimeSheet = () => {
  const [loading, setLoading] = useState(false);
  const [employeeData, setEmployeeData] = useState<any>([]);
  const { selectedCompany } = useOutletContext<any>();

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [displayedDays, setDisplayedDays] = useState<Day[]>([]);

  const [scheduleStartDate, setScheduleStartDate] = useState(new Date());
  const [scheduleEndDate, setScheduleEndDate] = useState(new Date());
  const [payrollApproved, setPayrollApproved] = useState<boolean>(false);

  const [currentWeek, setCurrentWeek] = useState<Date[]>([]);

  const getDaysOfWeek = (startDate: Date): Day[] => {
    const days: Day[] = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate.getTime() + i * 1000 * 60 * 60 * 24);
      days.push({
        date: date.toISOString().slice(0, 10),
        dayOfWeek: new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
          date
        ),
        isDisabled: date < scheduleStartDate || date > scheduleEndDate,
      });
    }
    return days;
  };

  const handleNextWeek = () => {
    const newStartDate = new Date(
      startDate.getTime() + 7 * 1000 * 60 * 60 * 24
    );
    if (newStartDate <= scheduleEndDate) {
      setStartDate(newStartDate);
      setDisplayedDays(getDaysOfWeek(newStartDate));
    }
  };

  const handlePreviousWeek = () => {
    const newStartDate = new Date(
      startDate.getTime() - 7 * 1000 * 60 * 60 * 24
    );
    if (newStartDate >= scheduleStartDate) {
      setStartDate(newStartDate);
      setDisplayedDays(getDaysOfWeek(newStartDate));
    }
  };

  const getCurrentWeek = () => {
    const now = new Date();
    let dayOfWeek = now.getDay();
    const firstDayOfWeek = now.getDate() - dayOfWeek;
    const lastDayOfWeek = firstDayOfWeek + 6;
    const weekArray = [];
    for (let i = firstDayOfWeek; i <= lastDayOfWeek; i++) {
      weekArray.push(new Date(now.getFullYear(), now.getMonth(), i));
    }
    setCurrentWeek(weekArray);
  };

  useEffect(() => {
    getCurrentWeek();
  }, []);

  useEffect(() => {
    setDisplayedDays(getDaysOfWeek(startDate));
    // getTimeSheet(employeeData);
  }, [startDate]);

  const getEmployees = async () => {
    setLoading(true);
    try {
      const data = await DashboardAPI.getAllEmployee(
        selectedCompany ? { company: selectedCompany } : {}
      );

      const newData = data?.data?.values?.results?.map((item: any) => {
        return {
          id: item.emp_id,
          name: item.first_name + " " + item.last_name,
          allState: false,
          timeSheet: [
            ...Array(7).fill(
              {
                overtime: 0,
                double_time: 0,
                vacation_time: 0,
                sick_time: 0,
                total: 0,
              },
              0,
              7
            ),
          ],
        };
      });

      setEmployeeData(newData);
      getTimeSheet(newData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmployees();
  }, [selectedCompany]);

  const submitTimeSheet = async () => {
    const weekMap: any = {};

    for (let i = 0; i < 7; i++) {
      const date = new Date(startDate);
      const newDate = new Date(date.setDate(date.getDate() + i))
        ?.toISOString()
        ?.substring(0, 10)
        ?.split("-")
        ?.reverse()
        ?.join("-");
      weekMap[newDate] = [];
    }

    employeeData?.map((item: any) => {
      item?.timeSheet?.map((time: any, index: number) => {
        const date = new Date(startDate);
        const newDate = new Date(date.setDate(date.getDate() + index))
          ?.toISOString()
          ?.substring(0, 10)
          ?.split("-")
          ?.reverse()
          ?.join("-");

        weekMap[newDate].push({
          emp_id: item?.id,
          overtime: time?.overtime,
          doubletime: time?.double_time,
          vacation: time?.vacation_time,
          sicktime: time?.sick_time,
          workspent: time?.total,
        });
      });
    });

    try {
      setLoading(true);
      const data = await DashboardAPI.postDailyTimeSheet(selectedCompany, {
        timesheet: weekMap,
      });
      if (data?.success) {
        toast.success("Timesheet submitted successfully");
        weeklyTimesheet();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const weeklyTimesheet = async () => {
    setLoading(true);

    const obj: any = {};

    Object.keys(employeeData).map((item: any) => {
      obj[item] = {
        emp_id: employeeData[item].id,
        start_date: moment(startDate).format("DD-MM-YYYY"),
        end_date: moment(startDate).add(6, "days").format("DD-MM-YYYY"),
        // start_date: startDate?.split("-")?.reverse()?.join("-"),
        // end_date: startDate?.split("-")?.reverse()?.join("-"),
        workspent: employeeData[item].timeSheet
          .map((k: any) => Number(k["total"]))
          ?.reduce((a: number, b: number) => a + b, 0),
        overtime: employeeData[item].timeSheet
          .map((k: any) => Number(k["overtime"]))
          ?.reduce((a: number, b: number) => a + b, 0),
        doubletime: employeeData[item].timeSheet
          .map((k: any) => Number(k["double_time"]))
          ?.reduce((a: number, b: number) => a + b, 0),
        vacation: employeeData[item].timeSheet
          .map((k: any) => Number(k["vacation_time"]))
          ?.reduce((a: number, b: number) => a + b, 0),
        sicktime: employeeData[item].timeSheet
          .map((k: any) => Number(k["sick_time"]))
          ?.reduce((a: number, b: number) => a + b, 0),
      };
    });

    try {
      const data = await DashboardAPI.postWeeklyTimeSheet(selectedCompany, {
        timesheet: obj,
      });
      if (data?.success) {
        console.log("weekly timesheet submitted successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getTimeSheet = async (empData: any) => {
    setLoading(true);
    try {
      const data = await DashboardAPI.getDailyTimesheet(selectedCompany);
      setStartDate(new Date(data?.data.start_date));
      setEndDate(new Date(data?.data.end_date));
      setScheduleStartDate(new Date(data?.data.payschedule_start_date));
      setScheduleEndDate(new Date(data?.data.payschedule_end_date));
      setPayrollApproved(data?.data.approve_payroll);
      setEmployeeData([...empData]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getWeeklyTimeSheet = async () => {
    setLoading(true);
    try {
      const data = await DashboardAPI.getDailyTimesheet(selectedCompany, {
        start_date: displayedDays[0].date,
        end_date: displayedDays[6].date,
      });
      setPayrollApproved(data?.data.approve_payroll);
      const timeSheetData = data?.data?.values;

      Object.keys(timeSheetData)?.map((item: any, index: any) => {
        employeeData?.map((emp: any) => {
          const empDataArr = timeSheetData[item]?.filter(
            (time: any) => emp.id == time.emp_id
          );
          const empData = empDataArr[empDataArr.length - 1];

          if (empData) {
            emp.timeSheet[index] = {
              overtime: empData?.overtime,
              double_time: empData?.doubletime,
              vacation_time: empData?.vacation,
              sick_time: empData?.sicktime,
              total: empData?.workspent,
            };
          } else {
            emp.timeSheet[index] = {
              overtime: 0,
              double_time: 0,
              vacation_time: 0,
              sick_time: 0,
              total: 0,
            };
          }
        });
      });
      setEmployeeData([...employeeData]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWeeklyTimeSheet();
  }, [displayedDays]);

  // useEffect(() => {
  //   if (employeeData && employeeData.length > 0) {
  //     getTimeSheet(employeeData);
  //   }
  // }, []);
  useEffect(() => {
    if (employeeData && employeeData.length > 0 && displayedDays) {
      getTimeSheet(employeeData);
    }
  }, []);

  return (
    <div className="w-[100%] max-h-[100vh] pb-[100px]">
      <ToastContainer />
      {loading ? <Loader /> : null}
      <div className="mt-[20px] flex gap-[15px]">
        <p className="font-semibold text-[18px] text-[var(--secondary)]">
          Timesheet
        </p>
      </div>
      <div className="rounded-[20px] border-[1px] py-[20px] mt-[30px] ">
        <div className="flex justify-between items-center">
          <div className="flex gap-[10px] w-[80%] m-auto">
            <div className="flex w-[50%] flex justify-between items-center border-r-[2px]">
              <i
                onClick={handlePreviousWeek}
                className="bi bi-arrow-left-circle-fill text-[20px] cursor-pointer"
              ></i>
              {/* <p className="text-[12px]  pr-[50px]">
                {renderDate(week.firstday)}
              </p> */}
              <div className="flex gap-[12px] text-[12px]  pr-[50px]">
                <div className="flex-[0.5]">
                  <p className="text-[20px] font-semibold text-[var(--secondary)]">
                    {moment(startDate).format("DD")}
                  </p>
                </div>
                <div className="flex-[0.5]">
                  <p className="flex flex-row whitespace-nowrap">
                    {moment(startDate).format("MM")}{" "}
                    {moment(startDate).format("YYYY")}
                  </p>
                  <p>{moment(startDate).format("dddd")}</p>
                </div>
              </div>
            </div>

            <div className="flex w-[50%] flex justify-between items-center">
              <div>
                {/* <p className="text-[12px]  pl-[50px]">
                  {renderDate(week.lastday)}
                </p> */}
                <div className="flex gap-[12px] text-[12px]  pl-[50px]">
                  <div className="flex-[0.5]">
                    <p className="text-[20px] font-semibold text-[var(--secondary)]">
                      {moment(displayedDays[6]?.date).format("DD")}
                    </p>
                  </div>
                  <div className="flex-[0.5]">
                    <p className="flex flex-row whitespace-nowrap">
                      {moment(displayedDays[6]?.date).format("MM")}{" "}
                      {moment(displayedDays[6]?.date).format("YYYY")}
                    </p>
                    <p>{moment(displayedDays[6]?.date).format("dddd")}</p>
                  </div>
                </div>
              </div>

              <i
                onClick={handleNextWeek}
                className="bi bi-arrow-right-circle-fill text-[20px] cursor-pointer"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-[20px]">
        <div className="header ">
          <div className="grid grid-cols-12 gap-[5px] bg-[#e5e5e5] p-[5px] rounded-t-[8px] ">
            <div className="col col-span-3">Employee</div>
            {/* {[1, 2, 3, 4, 5, 6, 7].map((item, index) => {
              const firstDay = new Date(day.date);
              const dayOfMonth = new Date(
                firstDay.getFullYear(),
                firstDay.getMonth(),
                firstDay.getDate() + index
              ).getDate();
              return (
                <div
                  className="col col-span-1 flex flex-col justify-center items-center"
                  key={index + "day"}
                >
                  <p className="text-[14px]">{dayArray[index].slice(0, 3)} </p>
                  <p className="text-[14px]">{dayOfMonth}</p>
                </div>
              );
            })} */}
            {displayedDays.map((day) => (
              <div
                className="col col-span-1 flex flex-col justify-center items-center"
                key={day.date}
              >
                <p className="text-[14px]">{day.dayOfWeek.slice(0, 3)} </p>
                <p className="text-[14px]">{new Date(day.date).getDate()}</p>
              </div>
            ))}
            <div className="col-span-2">
              <p className="text-center">Total</p>
            </div>
          </div>

          <div className="border-[1px] p-[10px] rounded-b-[8px]">
            {employeeData?.map((item: any, index: number) => (
              <div
                key={item?.name}
                className={
                  "grid grid-cols-12 gap-[5px]  p-[10px] py-[20px] border-b-[1px] border-[#000]/[0.75] hover:bg-[#e5e5e5]/[0.2]  " +
                  (item?.allState ? "bg-[#e5e5e5]/[0.2]" : "")
                }
              >
                <div className="col col-span-3 flex items-center ">
                  <p
                    className="text-[14px] my-auto text w-[100%] justify-between flex items-center cursor-pointer"
                    onClick={() => {
                      setEmployeeData((prev: any) =>
                        employeeData.map((it: any) => {
                          if (it?.id === item?.id) {
                            return {
                              ...it,
                              allState: !it?.allState,
                            };
                          } else {
                            return it;
                          }
                        })
                      );
                    }}
                  >
                    {item?.name}{" "}
                    {!item?.allState ? (
                      <DownOutlined className="text-[var(--primary)] cursor-pointer font-bold text-[18px] pr-[30px]" />
                    ) : (
                      <UpOutlined className="text-[var(--primary)] cursor-pointer font-bold text-[18px] pr-[30px]" />
                    )}
                  </p>
                </div>

                {item?.timeSheet.map((itemTime: any, index: any) => (
                  <div
                    key={item?.name + " " + index}
                    className="col col-span-1 flex flex-col justify-center items-center"
                  >
                    <input
                      className="border-[1px] w-[100%] p-[5px]"
                      type="number"
                      value={itemTime?.total?.toFixed(2)}
                      onChange={(e) => {
                        setEmployeeData((prev: any) =>
                          employeeData.map((it: any) => {
                            if (it?.id === item?.id) {
                              return {
                                ...it,
                                timeSheet: it?.timeSheet.map(
                                  (timeItem: any, indexItem: number) => {
                                    if (indexItem === index) {
                                      return {
                                        ...timeItem,
                                        total: parseFloat(e.target.value),
                                      };
                                    } else {
                                      return timeItem;
                                    }
                                  }
                                ),
                              };
                            }

                            return it;
                          })
                        );
                      }}
                      disabled={payrollApproved}
                    />
                  </div>
                ))}
                <div className="col-span-2">
                  <input
                    className="border-[1px] w-[100%] p-[5px]"
                    // disabled
                    value={item?.timeSheet
                      .map((k: any) => Number(k.total))
                      ?.reduce((a: number, b: number) => a + b, 0)
                      ?.toFixed(2)}
                    onChange={(e) => {
                      setEmployeeData((prev: any) =>
                        employeeData.map((it: any) => {
                          if (it?.id === item?.id) {
                            return {
                              ...it,
                              timeSheet: it?.timeSheet.map(
                                (timeItem: any, indexItem: number) => {
                                  if (
                                    // indexItem === 0 ||
                                    // indexItem === it?.timeSheet.length - 1
                                    displayedDays[indexItem].dayOfWeek ===
                                      "Saturday" ||
                                    displayedDays[indexItem].dayOfWeek ===
                                      "Sunday"
                                  ) {
                                    return timeItem;
                                  }
                                  const avg =
                                    parseFloat(e.target.value) /
                                    (it?.timeSheet.length - 2);
                                  return {
                                    ...timeItem,
                                    total: avg,
                                  };
                                }
                              ),
                            };
                          } else {
                            return it;
                          }
                        })
                      );
                    }}
                    disabled={payrollApproved}
                  />
                </div>
                {item?.allState &&
                  [
                    "overtime",
                    "double_time",
                    "vacation_time",
                    "sick_time",
                  ]?.map((itm, itmIndex) => {
                    return (
                      <div className="col-span-12 grid grid-cols-12 border-t-[1px] gap-[5px] py-[10px] px-[5px] rounded-b-[8px]">
                        <div className="col col-span-3">
                          <p className="text-[12px] text-[#000]/[0.5] font-semibold text justify-between flex capitalize">
                            {typesLabelKeyMap[itm]}{" "}
                          </p>
                        </div>

                        {item?.timeSheet.map((itemT: any, indexT: any) => (
                          <div
                            className="col col-span-1 flex flex-col justify-center items-center"
                            key={index}
                          >
                            <input
                              value={itemT[itm]?.toFixed(2)}
                              type="number"
                              className="border-[1px] w-[100%] p-[5px]"
                              onChange={(e) => {
                                setEmployeeData((prev: any) =>
                                  employeeData.map((it: any) => {
                                    if (it?.id === item?.id) {
                                      return {
                                        ...it,
                                        timeSheet: it?.timeSheet.map(
                                          (
                                            timeItem: any,
                                            indexItem: number
                                          ) => {
                                            if (indexItem === indexT) {
                                              return {
                                                ...timeItem,
                                                [itm]: parseFloat(
                                                  e.target.value
                                                ),
                                              };
                                            } else {
                                              return timeItem;
                                            }
                                          }
                                        ),
                                      };
                                    }

                                    return it;
                                  })
                                );
                              }}
                              disabled={payrollApproved}
                            />
                          </div>
                        ))}
                        <div className="col-span-2">
                          <input
                            className="border-[1px] w-[100%] p-[5px]"
                            // disabled
                            value={item?.timeSheet
                              .map((k: any) => Number(k[itm]))
                              ?.reduce((a: number, b: number) => a + b, 0)
                              ?.toFixed(2)}
                            onChange={(e) => {
                              setEmployeeData((prev: any) =>
                                employeeData.map((it: any) => {
                                  if (it?.id === item?.id) {
                                    return {
                                      ...it,
                                      timeSheet: it?.timeSheet.map(
                                        (timeItem: any, indexItem: number) => {
                                          const avg =
                                            parseFloat(e.target.value) / 5;
                                          if (
                                            indexItem === 0 ||
                                            indexItem ===
                                              it?.timeSheet.length - 1
                                          ) {
                                            return timeItem;
                                          }
                                          return {
                                            ...timeItem,
                                            [itm]: avg,
                                          };
                                        }
                                      ),
                                    };
                                  } else {
                                    return it;
                                  }
                                })
                              );
                            }}
                            disabled={payrollApproved}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="relative ">
        <div className="footer border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md bg-[#e5e5e5] right-[0px] ">
          <div className="flex justify-between gap-[10px] p-[5px]">
            <button
              className="bg-[var(--primary)] ml-auto text-[#fff] font-semibold rounded-[8px] px-[20px] py-[5px]"
              onClick={(e) => {
                e.preventDefault();
                submitTimeSheet();
              }}
              disabled={payrollApproved}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeSheet;
