import React, { useEffect, useState } from "react";
import Input from "../../components/Input/Input";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../components/Loader/index";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { REGISTER } from "../../navigation/Constant";
import { LOGIN } from "../../navigation/Constant";
import { EMAIL_REGEX } from "../../constants/regex";
import OtpInput from "../../components/OtpInput/OtpInput";
import { AuthAPI } from "../../apis/authAPI";
import { RESET } from "../../navigation/Constant";
const logo = require("../../assets/images/logo.png");

const initialErrorState = {
  error: false,
  msg: "",
};

const ChangePassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [newpassword, setNewPassword] = React.useState("");
  const [confirmpassword, setConfirmPassword] = React.useState("");
  const [oldpassword, setOldpassword] = React.useState("");
  const [formErr, setFormErr] = React.useState({
    password: initialErrorState,
    oldpassword: initialErrorState,
    confirm_password: initialErrorState,
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [emailSent, setEmailSent] = useState(false);
  const [otp, setOtp] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    if (!email) {
      return toast.error("Please fill all the details", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    if (validate()) {
      return;
    }

    try {
      setLoading(true);
      const data = await AuthAPI.postChangePassword({
        email,
        old_password: oldpassword,
        new_password: newpassword,
      });

      if (data.success) {
        setEmailSent(true);
        toast.success(data.message);

        setTimeout(() => {
          navigate(LOGIN);
        }, 1000);
      }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const submitOtp = async (e: any) => {
    if (!otp) {
      return toast.error("Please fill all the details", {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    }

    try {
      setLoading(true);
      const data = await AuthAPI.postResetPasswordEmailOTP({
        email,
        email_otp: Number(otp),
      });

      if (data.success) {
        toast.success(data.message);
        sessionStorage.setItem("passwordReset", email);
        navigate(RESET);
      }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    let isError = false;
    const errors = {
      password: initialErrorState,
      oldpassword: initialErrorState,
      confirm_password: initialErrorState,
    };

    if (!oldpassword) {
      isError = true;
      errors.oldpassword = {
        error: true,
        msg: "Please enter your old password",
      };
    }

    if (!newpassword) {
      isError = true;
      errors.password = {
        error: true,
        msg: "Please enter your new password",
      };
    }

    if (!confirmpassword) {
      isError = true;
      errors.confirm_password = {
        error: true,
        msg: "Please enter your confirm password",
      };
    }

    if (newpassword !== confirmpassword) {
      isError = true;
      errors.confirm_password = {
        error: true,
        msg: "Password and confirm password does not match",
      };
    }

    setFormErr(errors);
    return isError;
  };

  useEffect(() => {
    setEmail(searchParams.get("email") || "");
  }, [newpassword, confirmpassword]);

  return (
    <div className="bg-[#F4F4F496]">
      <ToastContainer />
      {loading && <Loader />}
      <div className="min-h-[100vh] container py-[30px]  mx-auto flex flex-col items-center">
        <div className="flex w-[100%] items-start mb-[60px]">
          <img src={logo} alt="" className="w-[300px]" />
        </div>
        <div className="flex flex-col gap-[10px] card w-[80%] md:w-[40%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#ffffff] py-[30px] px-[20px] rounded-[8px]  mx-auto">
          <h1 className="text-center text-[#5ABC4BE0] text-3xl font-bold">
            Reset your Password
          </h1>
          {/* <h3 className="text-center text-2xl font-bold">Your email</h3> */}
          <div className="flex flex-col gap-[10px] w-[70%] mt-[30px] mx-auto">
            <Input
              disabled
              label="Email"
              name="email"
              type="email"
              value={email}
              placeholder="Enter your email"
              handleChange={(e: any) => setEmail(e.target.value)}
            />

            <Input
              label="Old Password"
              name="oldpassword"
              type="password"
              placeholder="New password"
              className="bg-[#F4F4F4] rounded-[12px]"
              handleChange={(e: any) => {
                setFormErr({
                  ...formErr,
                  oldpassword: initialErrorState,
                });
                setOldpassword(e.target.value);
              }}
              value={oldpassword}
              errTxt={formErr?.oldpassword?.msg}
            />

            <Input
              label="New Password"
              name="newpassword"
              type="password"
              placeholder="New password"
              className="bg-[#F4F4F4] rounded-[12px]"
              handleChange={(e: any) => {
                setFormErr({
                  ...formErr,
                  password: initialErrorState,
                });
                setNewPassword(e.target.value);
              }}
              value={newpassword}
              errTxt={formErr?.password?.msg}
            />
            <Input
              label="Confirm Password"
              name="confirmpassword"
              type="password"
              placeholder="Confirm password"
              className="bg-[#F4F4F4] rounded-[12px]"
              handleChange={(e: any) => {
                setFormErr({
                  ...formErr,
                  confirm_password: initialErrorState,
                });
                setConfirmPassword(e.target.value);
              }}
              value={confirmpassword}
              errTxt={formErr?.confirm_password?.msg}
            />

            <p
              className="text-right hover:underline cursor-pointer"
              onClick={() => {
                navigate(LOGIN);
              }}
            >
              Back to Login
            </p>
          </div>
          {!emailSent ? (
            <button
              className="bg-[#447EC1] text-[#fff] text px-[60px] mx-auto font-semibold rounded-[12px]  py-[8px] mt-[15px]"
              onClick={(e: any) => handleSubmit(e)}
            >
              Reset Password
            </button>
          ) : (
            <button
              className="bg-[#447EC1] text-[#fff] text px-[60px] mx-auto font-semibold rounded-[12px]  py-[8px] mt-[15px]"
              onClick={(e: any) => submitOtp(e)}
            >
              Submit OTP
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
