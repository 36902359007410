import React, { useEffect, useState } from "react";
import Input from "../Input/Input";
import CustomSelect from "../Select/Select";
import { Select } from "antd";
import { AuthAPI } from "../../apis/authAPI";
import { toast } from "react-toastify";
import { CommonApi } from "../../apis/commonApi";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import Loader from "../Loader";
import { DashboardAPI } from "../../apis/dashboardApi";
import { useOutletContext } from "react-router-dom";

interface personalInfoProps {
  data?: any;
  setData?: any;
  masterData?: any;
  formErrors?: any;
  setFormErrors?: any;
  edit?: Boolean;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const PersonalInfo = ({
  data,
  setData,
  masterData,
  formErrors,
  setFormErrors,
  edit,
}: personalInfoProps) => {
  const [extension, setExtension] = React.useState<any>([]);
  const [city, setCity] = React.useState<any>([]);
  const [state, setState] = React.useState<any>([]);
  const [company_id, setCompanyId] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);

  const [editPhoto, setEditPhoto] = useState(false);

  const getExtension = async () => {
    try {
      const [data1, data2] = await Promise.all([
        AuthAPI.getExtentionList(),
        // CommonApi.getCityList(),
        CommonApi.getStateList(),
      ]);
      //   const data = await AuthAPI.getExtentionList()
      if (data1.success) {
        setExtension(
          data1?.data?.extension.map((item: any) => ({
            label: item,
            value: item,
          }))
        );
      }

      if (data2.success) {
        setState(
          data2?.data?.state_list.map((item: any) => ({
            label: item,
            value: item,
          }))
        );
      }

      // if(data3.success){
      // setState(data?.data?.state.map((item: any) => ({ label: item, value: item })))
      // }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        // autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const getCityList = async (state: any) => {
    try {
      setLoading(true);
      const dataum = await CommonApi.getCityList({ state: data?.state });
      if (dataum.success) {
        setCity(
          dataum?.data?.city_list.map((item: any) => ({
            label: item,
            value: item,
          }))
        );
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data?.state) {
      getCityList(data?.state);
    }
  }, [data?.state]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: { error: false, msg: `` } });

    setData({ ...data, [name]: value, company_id: selectedCompany});
  };


  //   const [loading, setLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [CompanyName, setCompanyName] = useState<any>([]);
  const [selectedCompany, setSelectedCompany] = useState()
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChangeImage: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {

    if (newFileList[0]?.originFileObj) {
      if (newFileList[0]?.originFileObj?.size > 200000) {
        toast.error('File size should be less than 200KB')
        return
      }
    }

    setFileList(newFileList);

    setData({ ...data, photo: newFileList[0]?.originFileObj });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const [company, setCompany] = useState<any>([]);
  const getCompanies = async () => {
    // const { data, error } = useSWR('/api/company', fetcher)
    // if (error) message.error(error?.response?.data?.message)
    try {
      const data = await DashboardAPI?.getCompany();
      console.log(data)

      setCompany(data?.data?.values || []);
    } catch (err: any) {
      console.log(err);
      // const errMsg = err?.response?.data?.message || 'Something went wrong'
      //     toast.error(errMsg)
    } finally {
      // setLoading(false)
    }
  };

  const [otherState, setOtherState] = useState('');
  const [otherCity, setOtherCity] = useState('');

  useEffect(() => {
    getExtension();
    getCompanies();
  }, []);

  const contextData = useOutletContext<any>()

  useEffect(() => {
    setSelectedCompany(contextData.selectedCompany)
  }, [contextData.selectedCompany])


  return (
    <div>
      <div className="grid grid-cols-2 gap-[10px]">
        {loading && <Loader />}
        <div className="col-span-2 ">
          <div className="grid grid-cols-4 gap-[10px]">
            <div className="col-span-3 flex flex-col gap-[10px]">
              <div className="col-span-1">
                <Input
                  type={"text"}
                  name={"first_name"}
                  label={"First Name"}
                  required
                  placeholder={"Enter First Name"}
                  value={data?.first_name}
                  handleChange={handleChange}
                  errTxt={formErrors?.first_name?.msg}
                />
              </div>
              <div className="col-span-1">
                <Input
                  type={"text"}
                  name={"middle_name"}
                  label={"Middle Initial"}
                  placeholder={"Enter Middle Initial"}
                  value={data?.middle_name}
                  handleChange={handleChange}
                  errTxt={formErrors?.middle_name?.msg}
                />
              </div>



              <div className="col-span-1">
                <Input
                  type={"text"}
                  name={"last_name"}
                  label={"Last Name"}
                  required
                  placeholder={"Enter Last Name"}
                  value={data?.last_name}
                  handleChange={handleChange}
                  errTxt={formErrors?.last_name?.msg}
                />
              </div>

              {/* <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Select Company <span className="text-[red]">*</span>
                </label>
                <Select
                  defaultValue={data?.company_name}
                  showSearch
                  mode={"multiple"}
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[0]"
                  value={data?.company_name}
                  options={[
                    ...company.map((item: any) => ({
                      label: item.company_name,
                      value: item.company_id,
                    })),
                  ]}
                  onChange={(e) => {
                    setData((prevData: any) => ({ ...prevData, company_id: e }));
                  }}
                />
              </div> */}

              <div className="col-span-1 flex flex-col">
                <Input
                  type={"text"}
                  name={"company_name"}
                  label={"Company Name"}
                  disabled
                  placeholder={"Enter Company Name"}
                  value={company?.find((item: any) => item.company_id == selectedCompany)?.company_name || "Select Company"}
                  handleChange={handleChange}
                  errTxt={formErrors?.company_name?.msg}
                />
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center">
              {edit && !editPhoto ? (
                <div className="flex flex-col gap-[10px]">
                  <img
                    src={data.photo_url || "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=1480&t=st=1682599573~exp=1682600173~hmac=70167dd96663e16bc7d1b9ea915c69f452455a2db0362cb2b6eff25ae440acbd"}
                    alt="profile"
                    className="w-[160px] border-[1px] rounded-[8px] h-[160px] object-cover"
                  />
                  <p
                    className="text-[12px] text-center hover:underline cursor-pointer "
                    onClick={() => setEditPhoto(true)}
                  >
                    Edit Photo
                  </p>
                </div>
              ) : (
                <>
                  <Upload
                    listType="picture-card"
                    className="w-[fit-content]"
                    fileList={fileList}
                    onPreview={handlePreview}
                    beforeUpload={() => {
                      return false;
                    }}
                    onChange={handleChangeImage}
                    maxCount={1}
                    accept="image/png, image/jpeg, image/jpg"
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      className="w-[160px] h-[160px] object-cover"
                      style={{ width: "160px", height: "160px" }}
                      src={previewImage}
                    />
                  </Modal>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-span-1">
          <Input
            type={"email"}
            name={"email"}
            label={"Email"}
            required
            placeholder={"Enter Email"}
            value={data?.email}
            disabled={edit ? true : false}
            handleChange={handleChange}
            errTxt={formErrors?.email?.msg}
          />
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Phone No. <span className="text-[red]">*</span>
          </label>
          <div className="flex gap-[10px] mt-[5px]">
            <Select
              defaultValue={data?.extension}
              style={{ width: 80, flex: 0.1 }}
              className="border-[1px] text-[14px] rounded-[4px] p-[0] "
              options={[...extension]}
              value={data?.extension}
              onChange={(e) => {
                setData((prev: any) => ({ ...prev, extension: e }));
              }}
            />

            <input
              name={"mobile_no"}
              type={"number"}
              className={
                " flex-[0.9] border-[1px] text-[14px] rounded-[4px] p-[10px]"
              }
              placeholder={"Enter your Mobile Number"}
              value={data?.mobile_no}
              onChange={(e: any) => {
                if (e.target.value.length < 11) {
                  handleChange(e);
                }
              }
              }
              disabled={edit ? true : false}
              onBlur={(e: any) => {
                {
                  e.target.value?.length < 10 ? setFormErrors({ ...formErrors, mobile_no: { error: true, msg: `Phone number must 10 digits` } }) : setFormErrors({ ...formErrors, mobile_no: { error: false, msg: `` } });
                }
              }
              }

            />
          </div>
          {formErrors?.mobile_no?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.mobile_no?.msg}{" "}
            </label>
          )}
        </div>

        <div className="col-span-1">
          <Input
            type={"text"}
            name={"ssn"}
            label={"SSN"}
            placeholder={"Enter SSN ( 9 digit )"}
            value={data?.ssn}
            handleChange={(e: any) => {
              if (e.target.value.length < 10) {
                handleChange(e);
              }
            }
            }
            onBlur={(e: any) => {
              {
                e.target.value?.length < 9 ? setFormErrors({ ...formErrors, ssn: { error: true, msg: `SSN must be 9 digits` } }) : setFormErrors({ ...formErrors, ssn: { error: false, msg: `` } });
              }
            }
            }
            errTxt={formErrors?.ssn?.msg}
          />
        </div>

        <div className="col-span-2">
          <Input
            type={"text"}
            name={"address"}
            label={"Street Address"}
            required
            placeholder={"Enter Street Address"}
            value={data?.address}
            handleChange={handleChange}
            errTxt={formErrors?.address?.msg}
          />
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            State <span className="text-[red]">*</span>
          </label>
          {data?.state === 'other' ? (
            <input
              type="text"
              className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[0px]"
              placeholder="Enter Other State"
              value={otherState}
              onChange={(e) => setOtherState(e.target.value)}
            />
          ) : (
            <Select
              showSearch
              className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[0]"
              placeholder="Search to Select"
              value={data?.state}
              options={[...state, { value: 'other', label: 'Other' }]}
              onChange={(e) => {
                if (e.value === 'other') {
                  setOtherState('');
                  setData((prev: any) => ({ ...prev, state: '' }));
                } else {
                  setData((prev: any) => ({ ...prev, state: e }));
                }
              }}
            />
          )}
          {formErrors?.state?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.state?.msg}{" "}
            </label>
          )}
        </div>
        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            City <span className="text-[red]">*</span>
          </label>
          {data?.state === 'other' || data?.city === 'other' || (!city || city.length === 0) ? (
            <input
              type="text"
              className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[10px]"
              placeholder="Enter City"
              value={otherCity}
              onChange={(e) => setOtherCity(e.target.value)}
            />
          ) : (
            <Select
              showSearch
              className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[0]"
              placeholder="Search to Select"
              value={data?.city}
              options={[...city, { value: 'other', label: 'Other' }]}
              onChange={(e) => {
                if (e.value === 'other') {
                  setOtherCity('');
                  setData((prev: any) => ({ ...prev, city: '' }));
                } else {
                  setData((prev: any) => ({ ...prev, city: e }));
                }
              }}
            />
          )}
          {formErrors?.city?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.city?.msg}{" "}
            </label>
          )}
        </div>

        <div className="col-span-1">
          <Input
            type={"number"}
            name={"zip_code"}
            label={"Zipcode ( 5 digits )"}
            required
            placeholder={"Enter Zipcode"}
            value={data?.zip_code}
            handleChange={(e: any) => {
              if (e.target.value.length < 6) {
                handleChange(e);
              }
            }
            }
            onBlur={(e: any) => {
              {
                e.target.value?.length < 5 ? setFormErrors({ ...formErrors, zip_code: { error: true, msg: `Zipcode must be 5 digit` } }) : setFormErrors({ ...formErrors, zip_code: { error: false, msg: `` } });
              }

            }
            }
            errTxt={formErrors?.zip_code?.msg}
          />
        </div>

        <div className="col-span-1"></div>

        <div className="col-span-1">
          <Input
            type={"date"}
            name={"hire_date"}
            label={"Hire Date"}
            required
            placeholder={"Enter Hire Date"}
            value={data?.hire_date}
            handleChange={handleChange}
            errTxt={formErrors?.hire_date?.msg}
          />
        </div>

        <div className="col-span-1">
          <Input
            type={"text"}
            name={"driver_license"}
            label={"License no."}
            placeholder={"Enter license no"}
            value={data?.driver_license}
            handleChange={handleChange}
            errTxt={formErrors?.driver_license?.msg}
          />
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Marital status
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            value={data?.marital_status}
            options={masterData?.marital_status}
            onChange={(e) => {
              setData((prev: any) => ({ ...prev, marital_status: e }));
            }}
          />
          {formErrors?.marital_status?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.marital_status?.msg}{" "}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
