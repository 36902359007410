import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import Input from "../../../components/Input/Input";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader/index";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ADD_EMPLOYEE, LOGIN, PAYROLL_SETUP, VERIFY_OTP } from "../../../navigation/Constant";
import { EMPLOYEE } from "../../../navigation/Constant";
import Sel from "../../../components/Select/Select";

import { Select } from "antd";
import { CommonApi } from "../../../apis/commonApi";
import ImageUpload from "../../../components/ImageUpload/ImageUpload";

import { Radio } from "antd";
import { parse } from "path";

import moment from "moment";

type Props = {}

function PaySchedule({ }: Props) {

    const initialErrorState = {
        error: false,
        msg: "",
    };

    const { selectedCompany } = useOutletContext<any>()

    const initialData = {
        company_name: "",
        country: "",
        state: "",
        city: "",
        zip_code: "",
        business_currency: "",
        org_type: "",
        business_type: "",
        address: "",
        fei_no: "",
        uia_no: "",
        company_logo: "",
        company_type: "",
        company_website: "",
        payroll_freq: "every week",
        period_start: "",
        payday: 0,
        fedral_dependent_credit: 0,
        fedral_other_dependent_credit: 0,
        percentage_credit: 0,
        medicare_tax: 0,
        social_security_tax: 0,

    };


    const [master, setMaster] = React.useState<any>({});
    const [submit, setSubmit] = React.useState(false)
    const [city, setCity] = React.useState<any>([]);
    const [state, setState] = React.useState<any>([]);

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = useState<any>(initialData);
    const [data, setData] = useState<any>({}); // for edit

    const [startDateEdit, setStartDateEdit] = React.useState<any>(false);

    const [paySchedule, setPaySchedule] = React.useState<any>({
        start_date: null,
        end_date: null,
        pay_date: null,
    });
    const [payScheduleExisted, setPayScheduleExisted] = React.useState<any>({
        start_date: null,
        end_date: null,
        pay_date: null,
    });
    const [startDay, setStartDay] = useState(new Date().getDay())
    const [showSelect, setSelect] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const dayArraySelect = [
        {
            value: "Sunday",
            label: "Sunday"
        },
        {
            value: "Monday",
            label: "Monday"
        },
        {
            value: "Tuesday",
            label: "Tuesday"
        },
        {
            value: "Wednesday",
            label: "Wednesday"
        },
        {
            value: "Thursday",
            label: "Thursday"
        },
        {
            value: "Friday",
            label: "Friday"
        },
        {
            value: "Saturday",
            label: "Saturday"
        },
    ]

    const getMaxDaysForFrequency = (frequency: string): number => {
        switch (frequency) {
            case "every week":
                return 7;
            case "every other week":
                return 14;
            case "twice a month":
                return 15;
            case "once a month":
                return 31;
            default:
                return 31;
        }
    };


    const [week, setWeek] = useState<any>({});
    const [weekUtc, setWeekUtc] = useState<any>({});
    const [timesheetExist, setTimesheetExist] = useState(false);

    const nextWeek = () => {
        if (startDay < 0 || startDay > 6) {
            console.error('Invalid start day. Please provide a value between 0 (Sunday) and 6 (Saturday).');
            return;
        }

        var curr = new Date(week.lastday);
        var newDate = new Date(curr.getTime() + 24 * 60 * 60 * 1000);

        // Calculate the next week's starting day
        var diff = startDay - newDate.getDay();
        diff = diff < 0 ? 7 + diff : diff;

        var first = newDate.getDate() + diff;
        var last = first + 6;

        var firstday = new Date(newDate.setDate(first));

        const millisecondsInDay = 24 * 60 * 60 * 1000;

        // Calculate the time for the nth previous date
        const targetTime = firstday.getTime() + 6 * millisecondsInDay;

        // Create a new Date object with the target time
        const lastday = new Date(targetTime);

        var firstdayUtc = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            first
        ).toUTCString();
        var lastdayUtc = new Date(
            newDate.getFullYear(),
            newDate.getMonth(),
            last
        ).toUTCString();

        setWeek({
            firstday: firstday.toISOString().substring(0, 10),
            lastday: lastday.toISOString().substring(0, 10),
        });
        setWeekUtc({ firstday: firstdayUtc, lastday: lastdayUtc });
    };

    const [formErrors, setFormErrors] = useState<any>(initialErrorState);

    const params = useParams();

    const navigate = useNavigate();

    const handlecomp = async () => {
        try {
            setLoading(true);

            const dataum = await DashboardAPI.patchPayschedule(selectedCompany, {
                start_date: moment(paySchedule?.start_date).format("YYYY-MM-DD"),
                end_date: moment(paySchedule?.end_date).format("YYYY-MM-DD"),
                pay_date: moment(paySchedule?.pay_date).format("YYYY-MM-DD"),
                payroll_freq: formData?.payroll_freq,
                payday: formData?.payday,
                period_start: dayArraySelect[startDay].value,
            });

            if (dataum.success) {
                toast.success("Payschedule Added Successfully");
                setTimeout(function () {
                    navigate(PAYROLL_SETUP);
                }, 1000);
            }
            getPayschedule();
            setData(dataum?.data?.values);
            setFormData(dataum?.data?.values);

            if (dataum?.data?.values?.payday) setIsDataLoaded(true);
            else setIsDataLoaded(false);
        } catch (error: any) {
            const errMsg =
                error?.response?.data?.message ||
                error?.message ||
                "something went wrong";
            return toast.error(errMsg);
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
    };

    const [formErr, setFormErr] = useState({
        company_name: initialErrorState,
        address: initialErrorState,
        country: initialErrorState,
        state: initialErrorState,
        city: initialErrorState,
        zip_code: initialErrorState,
        business_currency: initialErrorState,
        org_type: initialErrorState,
        company_type: initialErrorState,
    });

    const validate = (e: any) => {
        const { name, value } = e.target;

        if (name === "company_name") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    company_name: {
                        error: true,
                        msg: "Please enter company name",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({
                    ...prev,
                    company_name: initialErrorState,
                }));
            }
        } else if (name === "address") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    address: {
                        error: true,
                        msg: "Please enter address",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({ ...prev, address: initialErrorState }));
            }
        } else if (name === "country") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    country: {
                        error: true,
                        msg: "Please enter country",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({ ...prev, country: initialErrorState }));
            }
        } else if (name === "state") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    state: {
                        error: true,
                        msg: "Please enter  state",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({ ...prev, state: initialErrorState }));
            }
        } else if (name === "city") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    city: {
                        error: true,
                        msg: "Please enter city",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({ ...prev, city: initialErrorState }));
            }
        } else if (name === "zip_code") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    zip_code: {
                        error: true,
                        msg: "Please enter zipcode",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({ ...prev, zip_code: initialErrorState }));
            }
        } else if (name === "business_currency") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    business_currency: {
                        error: true,
                        msg: "Please enter business currency",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({
                    ...prev,
                    business_currency: initialErrorState,
                }));
            }
        } else if (name === "org_type") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    type_of_org: {
                        error: true,
                        msg: "Please enter type of organisation",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({
                    ...prev,
                    type_of_org: initialErrorState,
                }));
            }
        } else if (name === "company_type") {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev,
                    company_type: {
                        error: true,
                        msg: "Please enter type of business",
                    },
                }));
            } else {
                setFormErr((prev: any) => ({
                    ...prev,
                    company_type: initialErrorState,
                }));
            }
        }
    };

    const validateSubmit = () => {
        if (
            formErr.company_name.error ||
            formErr.address.error ||
            formErr.city.error ||
            formErr.state.error ||
            formErr.zip_code.error ||
            formErr.country.error ||
            formErr.business_currency.error ||
            formErr.org_type.error ||
            formErr.company_type.error
        ) {
            return true;
        }
    };

    const getCityList = async (state: any) => {
        try {
            setLoading(true);
            const dataum = await CommonApi.getCityList({ state: formData?.state });
            if (dataum.success) {
                setCity(
                    dataum?.data?.city_list.map((item: any) => ({
                        label: item,
                        value: item,
                    }))
                );
            }
        } catch (error: any) {
            const errMsg =
                error?.response?.data?.message ||
                error?.message ||
                "something went wrong";
            return toast.error(errMsg);
        } finally {
            setLoading(false);
        }
    };

    const getStateList = async () => {
        try {
            setLoading(true);
            const dataum = await CommonApi.getStateList();
            if (dataum.success) {
                setState(
                    dataum?.data?.state_list.map((item: any) => ({
                        label: item,
                        value: item,
                    }))
                );
            }
        } catch (error: any) {
            const errMsg =
                error?.response?.data?.message ||
                error?.message ||
                "something went wrong";
            return toast.error(errMsg);
        } finally {
            setLoading(false);
        }
    };

    const getDetails = async () => {
        try {
            setLoading(true);
            const dataum = await DashboardAPI.getDetails();
            if (dataum.success) {
                setMaster(dataum.data.form_details);
            }
        } catch (error: any) {
            const errMsg =
                error?.response?.data?.message ||
                error?.message ||
                "something went wrong";
            return toast.error(errMsg);
        } finally {
            setLoading(false);
        }
    };

    const getPayschedule = async (): Promise<void> => {
        try {
            setLoading(true);

            const dataum = await DashboardAPI.getPayschedule(selectedCompany);
            const values = dataum?.data?.values;

            console.log("Start Date:", values?.start_date || new Date());
            console.log("End Date:", values?.end_date || new Date());
            console.log("Pay Date:", values?.pay_date || new Date());

            console.log("Before setting pay schedule:", paySchedule);

            const timesheetExistValue = values?.timesheet_is_exist || false;
            setTimesheetExist(timesheetExistValue);

           
            setPaySchedule((prev: any) => ({
                ...prev,
                start_date: values?.start_date || new Date(),
                end_date: values?.end_date || new Date(),
                pay_date: values?.pay_date || new Date(),
            }));
            console.log("After setting pay schedule:", paySchedule);
            if (!values?.payroll_freq) {
                const today = new Date();
                console.log("Setting default dates...");
                console.log("Start Date:", today);
                console.log("Pay Date:", new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6));
                console.log("End Date:", new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6));
                setPaySchedule({
                    start_date: today,
                    pay_date: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6),
                    end_date: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 6),
                });
                return;
            }

            if (values?.payday) {
                setIsDataLoaded(true);
            }
            setFormData((prev: any) => ({
                ...prev,
                payroll_freq: values?.payroll_freq,
                payday: values?.payday,
            }));

            setSelect(
                values?.payroll_freq === "every week" || values?.payroll_freq === "every other week"
            );

            const startInd = dayArraySelect.findIndex((s) => s.value == values?.period_start);
            setStartDay(startInd);

            const endInd = dayArraySelect.findIndex((s) => s.value == values?.period_end);
            console.log("End Date Index:", endInd);
            setPaySchedule({
                ...paySchedule,
                end_date: endInd
            })
            if (values?.start_date && values?.end_date && values?.pay_date) {
                setPaySchedule((prev: any) => ({
                    ...prev,
                    start_date: values?.start_date,
                    end_date: values?.end_date,
                    pay_date: values?.pay_date,
                }))
            }
            setIsDataLoaded(!!values?.payday);

        } catch (error: any) {
            const errMsg: string = error?.response?.data?.message || error?.message || "something went wrong";
            toast.error(errMsg);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedCompany) {
            getPayschedule();
        }
    }, [selectedCompany]);

    useEffect(() => {
        getStateList();
    }, []);

    useEffect(() => {
        getDetails();
    }, []);

    useEffect(() => {
        if (formData?.state) {
            getCityList(formData?.state);
        }
    }, [formData?.state]);

    const [editImg, setEditImg] = useState(false);

    const [step, setStep] = useState(1);

    useEffect(() => {
        const currentDate = new Date();
        const currentDayOfWeek = currentDate.getDay();

        const getFirstDayOfWeek = () => {
            let daysToAdd = (startDay - currentDayOfWeek + 7) % 7;

            if (daysToAdd === 0 && startDay !== currentDayOfWeek) {
                daysToAdd = 7;
            }

            const desiredDate = new Date(currentDate);
            desiredDate.setDate(currentDate.getDate() + daysToAdd);

            return desiredDate;
        };
        var firstDayOfWeek = getFirstDayOfWeek();
        var lastDayOfWeek = new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate() + 6);
        var lasDaySecondWeek = new Date(
            firstDayOfWeek.getFullYear(),
            firstDayOfWeek.getMonth(),
            firstDayOfWeek.getDate() + 13
        );
        var firstDayOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );
        var lastDayOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
        );
        const getFirstDayOfHalfMonth = () => {
            if (currentDate.getDate() <= 15) {
                return firstDayOfMonth;
            } else {
                return new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    16
                );
            }
        }
        var halfMonth = getFirstDayOfHalfMonth();
        const getLastDayOfHalfMonth = () => {
            if (currentDate.getDate() <= 15) {
                return new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    15
                );
            } else {
                return lastDayOfMonth;
            }
        }
        var lastDayOfHalfMonth = getLastDayOfHalfMonth();

        switch (formData?.payroll_freq) {
            case "every week":
                setPaySchedule({
                    start_date: firstDayOfWeek,
                    end_date: lastDayOfWeek,
                    pay_date: new Date(lastDayOfWeek.getFullYear(), lastDayOfWeek.getMonth(), lastDayOfWeek.getDate() + Number(formData?.payday)),
                });
                break;
            case "every other week":
                setPaySchedule({
                    start_date: firstDayOfWeek,
                    end_date: lasDaySecondWeek,
                    pay_date: new Date(lasDaySecondWeek.getFullYear(), lasDaySecondWeek.getMonth(), lasDaySecondWeek.getDate() + Number(formData?.payday)),
                });
                break;
            case "twice a month":
                setPaySchedule({
                    start_date: halfMonth,
                    end_date: lastDayOfHalfMonth,
                    pay_date: new Date(lastDayOfHalfMonth.getFullYear(), lastDayOfHalfMonth.getMonth(), lastDayOfHalfMonth.getDate() + Number(formData?.payday)),
                });
                break;
            case "once a month":
                setPaySchedule({
                    start_date: firstDayOfMonth,
                    end_date: lastDayOfMonth,
                    pay_date: new Date(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() + Number(formData?.payday)),
                });
                break;
        }
    }, [formData?.payroll_freq, formData?.period_start, formData.payday, startDay]);

    // useEffect(() => {
    //     var currentDate = new Date();

    //     // Get the current day of the week (0-6, where 0 is Sunday)
    //     var currentDayOfWeek = currentDate.getDay();

    //     // Calculate the number of days to subtract to get to the first day of the week
    //     var daysToSubtract = (currentDayOfWeek + 7 - startDay) % 7;

    //     // Subtract the days from the current date to get the first day of the week
    //     var firstDayOfWeek = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth(),
    //         currentDate.getDate() - daysToSubtract
    //     )?.toISOString();

    //     var lastDayOfWeek = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth(),
    //         currentDate.getDate() - daysToSubtract + 6
    //     )?.toISOString();

    //     var lasDaySecondWeek = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth(),
    //         currentDate.getDate() - daysToSubtract + 13
    //     )?.toISOString();


    //     // Set the date to the first day of the month

    //     var firstDayOfMonth = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth(),
    //         2
    //     )?.toISOString();
    //     var halfMonth = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth(),
    //         16
    //     )?.toISOString();

    //     var lastDayOfMonth = new Date(
    //         currentDate.getFullYear(),
    //         currentDate.getMonth() + 1,
    //         1
    //     )?.toISOString();
    //     if (
    //         formData?.payroll_freq == "every week" ||
    //         formData?.payroll_freq == "every other week"
    //     ) {

    //         setPaySchedule({
    //             start_date: firstDayOfWeek,
    //             end_date:
    //                 formData?.payroll_freq == "every week"
    //                     ? lastDayOfWeek
    //                     : lasDaySecondWeek,
    //             pay_date:
    //                 formData?.payroll_freq == "every week"
    //                     ? lastDayOfWeek
    //                     : lasDaySecondWeek,
    //         });
    //     } else if (formData?.payroll_freq == "twice a month" || formData?.payroll_freq == "once a month") {
    //         // var currentDate = new Date();

    //         setPaySchedule({
    //             start_date: firstDayOfMonth,
    //             end_date:
    //                 formData?.payroll_freq == "twice a month"
    //                     ? halfMonth
    //                     : lastDayOfMonth,
    //             pay_date:
    //                 formData?.payroll_freq == "twice a month"
    //                     ? halfMonth
    //                     : lastDayOfMonth,
    //         });
    //     }

    //     if (paySchedule?.start_date && paySchedule?.end_date && formData?.payday) {
    //         const endDate = new Date(paySchedule?.end_date);
    //         const payDate = new Date(
    //             endDate.getFullYear(),
    //             endDate.getMonth(),
    //             Number(endDate.getDate()) + Number(formData?.payday)
    //         )?.toISOString();
    //         setPaySchedule({
    //             ...paySchedule,
    //             start_date: firstDayOfWeek,
    //             pay_date: payDate,
    //         });
    //     }
    // }, [formData?.payroll_freq, formData?.period_start, formData?.payday, startDay]);

    const setDate = (startDate: any) => {
        // if (
        //   formData?.payroll_freq == "every week" ||
        //   formData?.payroll_freq == "every other week"
        // ) {
        //   const endDateWeek = moment(startDate).add(6, "days").format("YYYY-MM-DD");
        //   const endDateTwoWeek = moment(startDate)
        //     .add(12, "days")
        //     .format("YYYY-MM-DD");

        //   setPaySchedule({
        //     ...paySchedule,
        //     start_date: startDate,
        //     end_date:
        //       formData?.payroll_freq == "every week" ? endDateWeek : endDateTwoWeek,
        //     pay_date:
        //       formData?.payroll_freq == "every week"
        //         ? moment(endDateWeek)
        //             .add(formData?.payday, "days")
        //             .format("YYYY-MM-DD")
        //         : endDateTwoWeek,
        //   });
        // } else if (formData?.payroll_freq == "twice a month" || "once a month") {
        //   const endDateMonth = moment(startDate)
        //     .endOf("month")
        //     .format("YYYY-MM-DD");
        //   const endDateHalfMonth = moment(startDate)
        //     .add(14, "days")
        //     .format("YYYY-MM-DD");

        //   setPaySchedule({
        //     ...paySchedule,
        //     start_date: startDate,
        //     end_date:
        //       formData?.payroll_freq == "twice a month"
        //         ? endDateHalfMonth
        //         : endDateMonth,
        //     pay_date:
        //       formData?.payroll_freq == "twice a month"
        //         ? endDateHalfMonth
        //         : endDateMonth,
        //   });
        // }

        setPaySchedule({
            ...paySchedule,
            start_date: startDate,
        });


        setStartDateEdit(false);
    };


    return (
        <div className="container mx-auto">
            <ToastContainer />
            {loading && <Loader />}
            <div>
                <div className="grid grid-cols-2 gap-[10px] mt-[30px] w-[100%]">
                    <div className="col-span-2 flex gap-[15px] flex-col">
                        <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                            Choose Payroll Frequency
                        </label>
                        <Radio.Group
                            value={formData?.payroll_freq}
                            onChange={(e) => {
                                setFormData((prev: any) => ({
                                    ...prev,
                                    payroll_freq: e.target.value,
                                    payday: 0,
                                }));

                                if (e.target.value === "every week" || e.target.value === "every other week") {
                                    setSelect(true);
                                } else {
                                    setSelect(false);
                                }
                            }}
                        // disabled={isDataLoaded}
                        >
                            <Radio value={"every week"}>Every Week</Radio>
                            <Radio value={"every other week"}>Every Other Week</Radio>
                            <Radio value={"twice a month"}>Twice a Month</Radio>
                            <Radio value={"once a month"}>Once a Month</Radio>
                        </Radio.Group>
                    </div>
                    {showSelect ? (
                        <div className="py-5">
                            <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                                Day of the week your payroll period starts
                            </label>
                            <Sel
                                options={dayArraySelect}
                                handleChange={(e: any) => {
                                    const ind = dayArraySelect.findIndex(s => s.value == e.target.value)
                                    setStartDay(ind === -1 ? 0 : ind)
                                }}
                                value={dayArraySelect[startDay].value}
                            // disabled={isDataLoaded}
                            />
                        </div>
                    ) : null}
                    <div className="col-span-2 flex gap-[15px] flex-col my-[10px]">
                        <p className="flex items-center text-[#333333] opacity-70 font-semibold text-[14px] ">
                            Default Payday to
                            <div className="flex flex-col mx-[10px]">
                                <input
                                    value={formData?.payday}
                                    className="border-[1px] w-[50px]"
                                    type="number"
                                    onChange={(e) => {
                                        const inputValue = e.target.value.trim();
                                        const maxDays = getMaxDaysForFrequency(formData.payroll_freq);
                                        if (inputValue === "" || (parseInt(inputValue) <= maxDays && /^\d+$/.test(inputValue))) {
                                            setFormData({
                                                ...formData,
                                                payday: inputValue === "" ? "" : parseInt(inputValue),
                                            });
                                        }
                                    }}
                                />
                                <span className="text-[#000]/[0.5] text-[10px] mb-[-15px]">
                                    {getMaxDaysForFrequency(formData.payroll_freq)} days Max
                                </span>
                            </div>
                            Days after payroll period ends
                        </p>
                    </div>

                    <div className="col-span-1"></div>

                    <hr className="col-span-2 my-[20px] h-[2px] bg-[#000]/[0.3]" />

                    <div className="col-span-2 flex gap-[15px] flex-col my-[10px]">
                        <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                            Your Payroll Schedule will look based on the settings above
                        </label>
                        <div className="grid grid-cols-4 gap-[10px]">
                            <div className="col-span-1">

                                {!startDateEdit ? (
                                    <div className="rounded-[4px] border-[1px]">
                                        <div className="bg-green-500 py-[5px] text-center">
                                            <h2 className="tetx-sm text-white">Start Date</h2>
                                        </div>
                                        <div className="py-[20px] text-center">
                                            <h2 className="whitespace-wrap">
                                                {moment(paySchedule?.start_date).format(
                                                    "ddd, DD MMM YYYY"
                                                )}
                                            </h2>
                                        </div>

                                    </div>
                                ) : (
                                    <Input
                                        type="date"
                                        name={"start_date"}
                                        label={"Start Date"}
                                        handleChange={(e: any) => {
                                            setDate(e.target.value);
                                        }}
                                        value={ paySchedule?.start_date?.split("T")[0] || ""}
                                        errTxt={formErrors?.start_date?.msg}
                                    />
                                )}
                            </div>

                            <div className="col-span-1">
                                <div className="rounded-[4px] border-[1px]  h-[100%]">
                                    <div className="bg-blue-500 py-[5px] text-center">
                                        <h2 className="tetx-sm text-white">End Date</h2>
                                    </div>
                                    <div className="py-[20px] text-center">
                                        <h2 className="whitespace-wrap">
                                            {moment(paySchedule?.end_date).format("ddd, DD MMM YYYY")}
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <div className="col-span-1">
                                <div className="rounded-[4px] border-[1px] h-[100%]">
                                    <div className="bg-blue-500 py-[5px] text-center">
                                        <h2 className="tetx-sm text-white">Pay Day</h2>
                                    </div>
                                    <div className="py-[20px] text-center">
                                        <h2 className="whitespace-wrap">
                                            {moment(paySchedule?.pay_date).format("ddd, DD MMM YYYY")}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="relative ">
                    <div className="footer bg-[#e5e5e5] border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md  right-[0px] ">
                        <div className="flex justify-between gap-[10px] p-[10px]">
                            <button className={`bg-[var(--primary)] ml-auto text-white rounded-[8px] px-[20px] py-[10px] ${timesheetExist ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
                                }`}
                                onClick={handlecomp}
                                disabled={timesheetExist}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default PaySchedule