import React, { useEffect, useState } from 'react'
import Loader from "../../../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import { Select, Space, DatePicker } from 'antd';
import Input from '../../../components/Input/Input';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { useOutletContext } from 'react-router-dom';
import { BASE_URL } from '../../../constants/apiConstant';

type Props = {
    company: any
}

const Form = ({ company }: Props) => {
    const [quarter, setQuarter] = useState<any>();
    const [year, setYear] = useState<any>('');
    const { selectedCompany } = useOutletContext<any>()
    console.log(selectedCompany);


    const handleChangeQuarter = (value: { value: string; label: React.ReactNode }) => {
        setQuarter(value.value); // Access the 'value' property of the object
    };

    const handleChangeYear = (value: { value: string; label: React.ReactNode }) => {
        setYear(value.value); // Access the 'value' property of the object
    };

    const handleViewReportClick = () => {
        // Convert year and quarter to Dayjs objects
        const yearAsDate = dayjs(`${year}-01-01`);
        const quarterAsDate = yearAsDate.add(3, 'month').subtract(3 - parseInt(quarter), 'month');
        const formattedYear = yearAsDate.format('YYYY');
        const formattedQuarter = quarterAsDate.format('Q');

        // Create the URL with the formatted values 
        const url = BASE_URL + `payroll/f_941/${selectedCompany}?quarter=${quarter?.value}&year=${formattedYear}`;

        // Open the URL or handle it as needed
        window.open(url, '_blank');
    };

    const [loading, setLoading] = useState<boolean>(false);
    return (
        <>
            {loading && <Loader />}
            <ToastContainer />
            <div>

                <div className="mt-[20px] items-cetner flex">
                    <p className="font-semibold text-[18px] text-[var(--secondary)]">
                        Reports
                    </p>
                </div>

                <div className='mt-[20px]'>
                    <div className='border-[#E9EAEC] border-[1px] pb-[32px] rounded-[8px]'>
                        <div className='p-[10px] bg-[#E6F4FF]'></div>
                        <div className='p-4 gap-4 grid grid-cols-1 sm:grid-cols-6 items-center justify-center'>
                            <div className='sm:col-span-2 col-span-4'>
                                <div className='flex sm:flex-col gap-[5px] justify-center'>
                                    <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                                        Select Quarter
                                        <span className="text-[red]">*</span>
                                    </label>
                                    <Select
                                        value={quarter}
                                        labelInValue
                                        placeholder="Select"
                                        // defaultValue={{ value: 'Select', label: 'Select' }}
                                        style={{ height: '45px', border: '2px solid #e0e0e0', borderRadius: "4px", }}
                                        onChange={e => { setQuarter(e) }}
                                        className='w-[160px] sm:w-auto'
                                        options={[
                                            {
                                                value: '1',
                                                label: '1',
                                            },
                                            {
                                                value: '2',
                                                label: '2',
                                            },
                                            {
                                                value: '3',
                                                label: '3',
                                            },
                                            {
                                                value: '4',
                                                label: '4',
                                            }
                                        ]}
                                    />
                                </div>
                            </div>
                            <div className='sm:col-span-2 col-span-4'>
                                <div className='flex sm:flex-col gap-[5px] justify-center'>
                                    <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                                        Select Year
                                        <span className="text-[red]">*</span>
                                    </label>
                                    <DatePicker
                                        value={year ? dayjs(year) : null} // Convert year to Dayjs object
                                        style={{ height: '45px', border: '2px solid #e0e0e0', borderRadius: "4px" }}
                                        picker="year"
                                        name={"pay_date"}
                                        onChange={e => {
                                            setYear(dayjs(e))
                                        }}
                                        className='w-[160px] sm:w-auto'
                                    />
                                </div>
                            </div>
                            <div className='mx-auto sm:mx-0 sm:mt-6 mt-4'>
                                <a
                                    onClick={handleViewReportClick}
                                    style={{ cursor: 'pointer' }}
                                    className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[25px] py-[14px] text-[14px]">
                                    View report
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Form