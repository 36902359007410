import React, { useState, useEffect } from "react";
import logo from "../../assets/images/logo.png";

import { useNavigate } from "react-router";
import { LOGIN } from "../../navigation/Constant";
import { DashboardAPI } from "../../apis/dashboardApi";
import { AuthAPI } from "../../apis/authAPI";

import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";

const Topbar = (props: { setToggle: any }) => {
  const navigate = useNavigate();

  const [user, setuser] = useState<any>({});

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (!userData) {
      //   navigate(LOGIN)
      return;
    }
    setuser(JSON.parse(userData || "{}"));
  }, []);

  const logout = async () => {
    try {
      const data = await AuthAPI?.postLogout();
      console.log(data);
      localStorage.clear();
      navigate(LOGIN);
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      alert(errMsg);
    }
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <a
          href="javascript:void(0)"
          onClick={() => navigate("/dashboard/employee/edit/" + user?.emp_id)}
        >
          My Profile
        </a>
      ),
      key: "0",
    },
    {
      type: "divider",
    },
    {
      label: (
        <button
          className="logout flex gap-[10px] text-[red] items-center"
          onClick={() => logout()}
        >
          <i className="bi bi-box-arrow-left text-[16px]"></i> Logout
        </button>
      ),
      key: "3",
    },
  ];

  return (
    <div className="bg-[#000]/[0.1] border-b-[1px] border-[#000]/[0.2] shadow-lg flex border-bottom-[1px]">
      <div className="container flex mx-auto justify-between py-[15px]">
        <div className="flex gap-[30px]">
          <button
            className="relative"
            onClick={() => {
              props.setToggle((prev: any) => !prev);
            }}
          >
            <i className="bi bi-list absolute sm:left-[-10px] left-2 top-[0] text-3xl font-bold z-50"></i>
          </button>
          {/* <img
            src={
              "https://payweek-dev.s3.ap-south-1.amazonaws.com/payweeklogo.png"
            }
            alt=""
            className="w-[160px]"
          /> */}
        </div>
        <div className="flex gap-[10px]">
          <i className="bi bi-bell text-[#000]/[0.5] text-[22px]"></i>

          <Dropdown menu={{ items }} trigger={["click"]}>
            <div className=" cursor-pointer flex gap-[10px] items-center justify-center">
              <i className="bi text-[#000]/[0.5] bi-person-circle text-[22px]"></i>
              <p className="text-[#000] font-semibold text-[16px]">
                {user?.first_name + " " + user?.last_name}
              </p>
              <i className="bi bi-caret-down-fill"></i>
            </div>
          </Dropdown>
          {/* <div className='flex gap-[10px] items-center'>
            
            <button className='logout' onClick={() => logout()}>
              <i className="bi bi-box-arrow-left text-[#000] text-[22px]"></i>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
