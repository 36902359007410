import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import { useLocation, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { DashboardAPI } from "../../../apis/dashboardApi";
import Loader from "../../../components/Loader";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import moment from "moment";
import { PRINT_SLIP } from "../../../navigation/Constant";
import type { MenuProps } from 'antd';
import { Button, Dropdown, message, Space, Tooltip } from 'antd';
import { Modal } from 'antd';
import { hover } from "@testing-library/user-event/dist/hover";

const ApprovedPayrollDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const { selectedCompany } = useOutletContext<any>();

  const [data, setData] = useState<any>([]);
  const [companydata, setCompanyData] = useState<any>({});

  useEffect(() => {
    // getData();
    if (state && selectedCompany) {
      const data = state.data;
      getData(data);
    }
  }, [state, selectedCompany]);



  const getData = async (data: any) => {
    try {
      setLoading(true);
      const res = await DashboardAPI.postApprovedPayrolls({
        company_id: Number(selectedCompany),
        start_date: data?.start_date,
        end_date: data?.end_date,
        pay_date: data?.pay_date,
      });

      if (res.success) {
        toast.success(res.message);
        setCompanyData({ ...res?.data?.comp_pay_slip, show_details: false });
        const arr: any = [];
        Object.keys(res?.data?.pay_slip).filter((item: any) => {
          if (res?.data?.pay_slip[item]?.name) {
            arr.push({
              ...res?.data?.pay_slip[item],
              emp_name:
                res?.data?.pay_slip[item]?.name[0]?.first_name +
                " " +
                res?.data?.pay_slip[item]?.name[0]?.last_name,
              emp_id: item,
            });
          }
        });

        arr.forEach((element: any) => {
          element.allState = false;
        });
        setData(arr);
      }
    } catch (err: any) {
      const errMsg = err?.data?.message || "Something went wrong!";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const handlePdf = async () => {
    try {
      const res = await DashboardAPI.postpdfpaysleep(selectedCompany, {
        start_date: companydata?.start_date,
        end_date: companydata?.end_date,
        pay_date: companydata?.pay_date,
        // company_id: companydata.company_id
      })
      if (res) {
        var binaryData = [];
        binaryData.push(res);
        const url: any = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        );
        var link = document.createElement("a");
        // link.download = "YourDesiredFileName.pdf"; // Change "YourDesiredFileName" to your choice
        link.href = url;
        link.target = "_blank"
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  const handleCSV = async () => {
    try {
      // Make a POST request to the server to fetch CSV data
      const res = await DashboardAPI.postExportcsv(selectedCompany,{
        start_date: companydata?.start_date,
        end_date: companydata?.end_date,
        pay_date: companydata?.pay_date,
        // company_id: companydata.company_id
      });

      if (res) {
        // Convert the CSV data to a Blob
        const blob = new Blob([res], { type: "text/csv" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "pay_statements.csv"; // Change the filename as desired
        link.target = "_blank";

        // Append the link to the document, trigger the click event, and remove the link
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.error(err);
    }
  };


  const items: MenuProps['items'] = [
    {
      label: 'PDF',
      key: '1',
      onClick: handlePdf,
    },
    {
      label: 'CSV',
      key: '2',
      onClick: handleCSV,
    }
  ];


  const menuProps = {
    items,
  };

  const handleDelete = async () => {
    try {
      const res = await DashboardAPI.postDeletepayroll(selectedCompany,{
        start_date: companydata?.start_date,
        end_date: companydata?.end_date,
        pay_date: companydata?.pay_date,
        // company_id: companydata.company_id
      });
      if (res) {
        navigate(-1)
      }
    }
    catch (err) {
      console.error(err);
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mx-auto">
      {loading && <Loader />}

      <div className="conatiner mx-auto pt-[20px] pb-[60px]">
        <h6 className="text-3xl ">Approved Payroll Summary</h6>
      </div>

      <div className="container flex flex-col mx-auto p-[20px]  rounded-[10px] border-gray-300 border-[1px] ">
        <h2 className="text-center text-lg font-semibold">
          Here's a look at the{" "}
          <span className="text-[var(--primary)]">
            {" "}
            {/* {moment(String(companydata?.start_date))?.format("Do MMM YYYY")} */}
            {companydata?.start_date}
          </span>{" "}
          to{" "}
          <span className="text-[var(--primary)]">
            {" "}
            {/* {moment(companydata?.end_date)?.format("Do MMM YYYY")} */}
            {companydata?.end_date}
          </span>{" "}
          payroll.
        </h2>
        <div className="flex mt-[30px] flex-col gap-[10px]">
          <h6 className="text-gray-400 text-center text-sm font-semibold">
            your total payroll cost is
          </h6>
          <h2 className="text-center text-3xl font-medium">
            ${companydata?.payroll_cost?.toFixed(2)}
          </h2>
        </div>

        <div className="grid grid-cols-2 mt-[20px] py-[10px]">
          <div className=" flex items-center justify-center border-r-[1px] border-gray-400">
            <div className="flex flex-col gap-[10px]">
              <h6 className="text-gray-400 text-center text-sm font-semibold">
                Payroll approved on
              </h6>
              <p className="text-center">
                {/* {moment(companydata?.approve_date)?.format("Do MMM YYYY")} */}
                {moment(companydata?.approve_date)?.format("Do MMM YYYY")}
              </p>
            </div>
          </div>
          <div className=" flex items-center justify-center ">
            <div className="flex flex-col gap-[10px]">
              <h6 className="text-gray-400 text-center text-sm font-semibold">
                Payday on
              </h6>
              <p className="text-center">
                {/* {moment(companydata?.pay_date)?.format("Do MMM YYYY")} */}
                {companydata?.pay_date}
              </p>
            </div>
          </div>
        </div>

        <div className="mx-auto mt-[45px] flex gap-[10px]">
          <Dropdown menu={menuProps}>
            <Button className="border-[1px] outline-0 border-[var(--primary)]  text-[var(--primary)] py-[20px] px-[24px] font-semibold text-[100%] rounded-[20px] items-center flex">
              Download
              <DownOutlined />
            </Button>
          </Dropdown>
          {/* <button onClick={() => handlePdf()}
            className="border-[1px] outline-0 border-[var(--primary)]  text-[var(--primary)] py-[8px] px-[24px] font-semibold rounded-[20px]">
            Download
          </button> */}
          <button onClick={() => navigate(PRINT_SLIP, { state: { start_date: companydata?.start_date, end_date: companydata?.end_date, pay_date: companydata?.pay_date, company_id: companydata.company_id } })} className="border-[1px] outline-0 border-[var(--primary)]  text-[var(--primary)] py-[8px] px-[24px] font-semibold rounded-[20px]">
            Print Checks
          </button>

          {state?.index === 0 ? (
            <button onClick={showModal} className="bg-red-500 font-semibold text-white py-[8px] px-[24px] rounded-[20px]">
              Delete
            </button>
          ) :
            <button style={{ cursor: 'not-allowed' }} disabled className="bg-red-300 font-semibold text-white py-[8px] px-[24px] rounded-[20px]">
              Delete
            </button>
          }

          <Modal className="mt-40" title="Delete this payroll?" open={isModalOpen} onOk={handleDelete} okText="Delete" onCancel={handleCancel}
          >
            <p>Are you sure you want to delete this payroll?</p>  
          </Modal>
        </div>
      </div>

      <div className="grid grid-cols-4  mt-[45px] pb-[50px]">
        <div className="col-span-4 mb-[20px]">
          <h2 className="text-lg font-bold">Payroll Summary</h2>
        </div>
        <div className="col-span-3">
          <h2 className="text-md font-bold">Description</h2>
        </div>
        <div className="col-span-1">
          <h2 className="text-md text-center font-bold">Amount</h2>
        </div>
        <div className="col-span-4 py-[5px] border-b-[2px] border-gray-400"></div>
        <div className="col-span-3 py-[5px]">
          <p className="text-md text-gray-700 ">Employees</p>
          <p className="text-sm text-gray-400 ">
            {companydata?.no_of_emp} employees included
          </p>
        </div>
        <div className="col-span-1 py-[5px] flex items-center justify-center">
          <p className="text-center text-lg">
            ${companydata?.payroll_cost?.toFixed(2)}
            <span
              onClick={() => {
                setCompanyData({
                  ...companydata,
                  show_details: !companydata?.show_details,
                });
              }}
              className="ml-[10px] text-blue-700 underline cursor-pointer"
            >
              {companydata?.show_details ? "Close" : "Details"}
            </span>
          </p>
        </div>

        {companydata?.show_details ? (
          <div className="col-span-4 py-[5px] border-b-[1px] border-gray-400">
            <div className="grid grid-cols-8 mb-[30px]">
              <div className="col-span-8 pb-[30px]">
                <p className="text-lg font-[600]">Details</p>
              </div>
              <div className="col-span-3 ">
                <p className="text-md font-medium">Net Wages</p>
              </div>
              <div className="col-span-3">
                <p className="text-right text-md ">
                  {companydata?.net_wages?.toFixed(2)}
                </p>
              </div>

              <div className="col-span-8 py-[15px]">
                <hr className="bg-gray-300 h-[2px]" />
              </div>

              <div className="col-span-6 grid grid-cols-4 gap-[10px]">
                <div className="col-span-2">
                  <p className="text-semibold">Taxes</p>
                </div>
                <div className="col-span-1">
                  <p className="text-semibold text-right">Employer portion</p>
                </div>
                <div className="col-span-1">
                  <p className="text-semibold text-right">Employee portion</p>
                </div>
                <div className="col-span-2">
                  <p className="text-sm text-gray-400 font-medium">
                    Federal Income Tax
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.fedral_inc_tax[0]?.toFixed(2)}{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.fedral_inc_tax[1]?.toFixed(2)}{" "}
                  </p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm text-gray-400 font-medium">
                    Federal Unemployment Tax
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.fedral_unemp_tax[0]?.toFixed(2)}{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.fedral_unemp_tax[1]?.toFixed(2)}{" "}
                  </p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm text-gray-400 font-medium">Medicare</p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.mediacl[0]?.toFixed(2)}{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.mediacl[1]?.toFixed(2)}{" "}
                  </p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm text-gray-400 font-medium">
                    Michigan State Tax
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.state_tax[0]?.toFixed(2)}{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.state_tax[1]?.toFixed(2)}{" "}
                  </p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm text-gray-400 font-medium">
                    Michigan State Unemployment
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.state_unmep_tax[0]?.toFixed(2)}{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.state_unmep_tax[1]?.toFixed(2)}{" "}
                  </p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm text-gray-400 font-medium">Tax</p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    0.00{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    0.00{" "}
                  </p>
                </div>

                <div className="col-span-2">
                  <p className="text-sm text-gray-400 font-medium">
                    Social Security
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.social_security[0]?.toFixed(2)}{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-right text-sm text-gray-400 font-medium">
                    {companydata?.social_security[1]?.toFixed(2)}{" "}
                  </p>
                </div>

                <div className="col-span-2">
                  <p className="text-semibold text-lg">Subtotal taxes</p>
                </div>
                <div className="col-span-1">
                  <p className="text-semibold text-right text-lg">
                    {companydata?.subtotal[0]?.toFixed(2)}{" "}
                  </p>
                </div>
                <div className="col-span-1">
                  <p className="text-semibold text-right text-lg">
                    {companydata?.subtotal[1]?.toFixed(2)}
                  </p>
                </div>
              </div>

              <div className="col-span-8 py-[15px]">
                <hr className="bg-gray-300 h-[2px]" />
              </div>

              <div className="col-span-3 ">
                <p className="text-md font-medium">Total Taxes</p>
              </div>
              <div className="col-span-3">
                <p className="text-right text-md ">
                  {companydata?.total_taxes?.toFixed(2)}
                </p>
              </div>

              <div className="col-span-8 py-[15px]">
                <hr className="bg-gray-300 h-[2px]" />
              </div>

              <div className="col-span-3 ">
                <p className="text-md font-medium">Non Taxable Items</p>
              </div>
              <div className="col-span-3">
                <p className="text-right text-md ">0.00</p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="col-span-2 py-[5px]" />
        <div className="col-span-1 py-[5px]">
          <p className="text-md text-gray-700 font-semibold">
            Total payroll cost{" "}
          </p>
        </div>
        <div className="col-span-1 flex items-center justify-center ">
          <p className="text-center text-md font-semibold">
            ${companydata?.payroll_cost?.toFixed(2)}
          </p>
        </div>

        <div className="col-span-4 pt-[20px] border-b-[2px] border-gray-700"></div>
      </div>

      {data?.length > 0 && (
        <div className="grid grid-cols-4 mt-[45px] pb-[200px]">
          <div className="col-span-4 mb-[20px]">
            <h2 className="text-lg font-bold">Pay Statements</h2>
          </div>
          {/* <div className="col-span-4 grid grid-cols-4 bg-[#000]/[0.1] p-[10px]">
          <div className="col-span-2">
            <p className="font-bold">Amount</p>
          </div>
          <div className="col-span-2">
            <p className="font-bold text-right">Effective Date</p>
          </div>
        </div> */}
          <div className="col-span-4 grid grid-cols-4 border-[1px] px-[10px] rounded-[20px]">
            {data?.map((item: any, index: number) => {
              return (
                <div
                  className="col-span-4 cursor-pointer grid grid-cols-12 border-b-[1px] py-[20px] px-[15px]"
                  onClick={(e) => {
                    e.preventDefault();
                    const newArr = [...data];
                    newArr[index]["allState"] = !newArr[index]["allState"];

                    setData(newArr);
                  }}
                  key={index}
                >
                  <div className="col-span-2">
                    <p className="font-bold capitalize">{item?.name}</p>
                  </div>
                  <div className="col-span-8"></div>
                  {/* <div className="col-span-1">
                    <p className="font-normal text-right">
                      {item?.emp_type}
                    </p>
                  </div> */}
                  <div className="col-span-2 w-[100%] flex">
                    <p className="font-normal text-[#000]/[0.5] text-right">
                      {item?.emp_type}
                    </p>
                  </div>

                  {/* <div className="col-span-1"></div> */}
                  <div className="col-span-4">
                    <p className="font-normal text-[14px] capitalize">
                      {(() => {
                        if (item?.regular) {
                          return (
                            <span className=" rounded">
                              Regular{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.regular}
                              </span>
                            </span>
                          );
                        } else if (item?.overtime) {
                          return (
                            <span className=" rounded">
                              Overtime{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.overtime}
                              </span>
                            </span>
                          );
                        } else if (item?.doubletime) {
                          return (
                            <span className=" rounded">
                              Doubletime{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.doubletime}
                              </span>
                            </span>
                          );
                        } else if (item?.vacation) {
                          return (
                            <span className=" rounded">
                              Vacation{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.vacation}
                              </span>
                            </span>
                          );
                        } else if (item?.sicktime) {
                          return (
                            <span className=" rounded">
                              Sicktime{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.sicktime}
                              </span>
                            </span>
                          );
                        } else {
                          return (
                            <span className=" rounded">
                              Regular{" "}
                              <span className="bg-[#000]/[0.2] p-[5px] rounded">
                                {item?.regular}
                              </span>
                            </span>
                          );
                        }
                      })()}
                    </p>
                  </div>
                  <div className="col-span-4"></div>
                  <div className="col-span-3">
                    <p className="font-normal text-right">
                      $ {item?.net_wages[0]?.toFixed(2)}
                    </p>
                  </div>
                  <div className="col-span-1 w-[100%] flex">
                    {!item?.allState ? (
                      <DownOutlined className="ml-auto text-[var(--primary)] cursor-pointer font-bold text-[18px] " />
                    ) : (
                      <UpOutlined className=" ml-auto text-[var(--primary)] cursor-pointer font-bold text-[18px] " />
                    )}
                  </div>

                  {item?.allState && (
                    <div className="col-span-12 px-[5px] py-[10px] grid grid-cols-12  bg-[#e5e5e5]/[0.3] mt-[10px]">
                      <div className="col-span-5">
                        <p className="font-bold"></p>
                      </div>
                      <div className="col-span-3">
                        <p className="font-semibold text-[14px] text-right">
                          Rate
                        </p>
                      </div>
                      <div className="col-span-2">
                        <p className="font-semibold text-[14px] text-right">
                          Amount
                        </p>
                      </div>
                      <div className="col-span-2">
                        <p className="font-semibold text-[14px] text-right">
                          Year to date
                        </p>
                      </div>

                      <hr className="bg-[#000]/[0.6] h-[2px] col-span-12 my-[10px]" />

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Gross Wages
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right">-</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.gross_wages[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.gross_wages[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Regular Pay
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            $ {item?.rate} / hr
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.regular_pay[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.regular_pay[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Previous Bonuses
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                      </div>
                      {/* Outer section END */}

                      {/* Outer Section START */}

                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Taxes
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right">-</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.taxes[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.taxes[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Federal Income Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.fedral_income_tax[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.fedral_income_tax[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Medicare
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.medicare[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.medicare[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            State Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.state_tax[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.state_tax[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Emp City Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.emp_city_tax[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.emp_city_tax[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        {/* <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Comp City Tax
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.comp_city_tax[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.comp_city_tax[1].toFixed(2)}
                          </p>
                        </div> */}

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Social Security
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            {item?.social_security[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            {item?.social_security[1].toFixed(2)}
                          </p>
                        </div>

                        {/* inner section */}

                        {item?.city_tax1 && (
                          <>
                            <div className="col-span-5">
                              <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                                City Tax 1
                              </p>
                            </div>
                            <div className="col-span-3">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                            </div>
                            <div className="col-span-2">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right">
                                {item?.city_tax1 && item?.city_tax1[0]}
                              </p>
                            </div>
                            <div className="col-span-2">
                              <p className="text-[14px] text-[#000]/[0.5] text-right">
                                {/* {item?.city_tax1[1]} */}
                                {item?.city_tax1 &&
                                  item?.city_tax1[1].toFixed(2)}
                              </p>
                            </div>
                          </>
                        )}

                        {/* inner section */}
                        {item?.city_tax2 && (
                          <>
                            <div className="col-span-5">
                              <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                                City Tax 2
                              </p>
                            </div>
                            <div className="col-span-3">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                            </div>
                            <div className="col-span-2">
                              <p className=" text-[14px] text-[#000]/[0.5] text-right">
                                {/* {item?.city_tax2[0]} */}
                                {item?.city_tax2 &&
                                  item?.city_tax2[0].toFixed(2)}
                              </p>
                            </div>
                            <div className="col-span-2">
                              <p className="text-[14px] text-[#000]/[0.5] text-right">
                                {/* {item?.city_tax2[1]} */}
                                {item?.city_tax2 &&
                                  item?.city_tax2[1].toFixed(2)}
                              </p>
                            </div>
                          </>
                        )}

                        {/* inner section */}

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                        {/* Outer section END */}
                      </div>

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Non-taxable items
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right">-</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>

                        {/* inner section */}

                        <div className="col-span-5">
                          <p className="font text-[14px] text-[#000]/[0.5] pl-[20px] ">
                            Indirect Tips
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className="text-[14px] text-[#000]/[0.5] text-right">
                            0.00
                          </p>
                        </div>

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                      </div>
                      {/* Outer section END */}

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-semibold text-[14px] ml-[10px]">
                            Net wages
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.net_wages[0].toFixed(2)}
                          </p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">
                            {item?.net_wages[1].toFixed(2)}
                          </p>
                        </div>

                        <hr className="bg-[#000]/[0.3] h-[1px] col-span-12 my-[10px]" />
                      </div>
                      {/* Outer section END */}

                      {/* Outer Section START */}
                      <div className="col-span-12 grid grid-cols-12">
                        <div className="col-span-5">
                          <p className="font-medium text-[14px] ml-[10px]">
                            Non-taxable non-cash benefits
                          </p>
                        </div>
                        <div className="col-span-3">
                          <p className=" text-[14px] text-right"></p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>
                        <div className="col-span-2">
                          <p className=" text-[14px] text-right">0.00</p>
                        </div>

                        <div className="col-span-12">
                          <p className="text-[14px] mt-[20px]">
                            Available vacation:{" "}
                            <span className="font-semibold">0 hours</span>
                          </p>
                        </div>
                      </div>
                      {/* Outer section END */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ApprovedPayrollDetails;
