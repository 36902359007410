export const ROOT = "/";

export const LOGIN = "/login";
export const REGISTER = "/register";
export const VERIFY_OTP = "/verify-otp";
export const CHANGE_PASSWORD = "/change-password";

export const DASHBOARD = "/dashboard";

//DASHBOARD
export const DASHBOARD_HOME = "/dashboard/home";

export const ADD_COMPANY = "/dashboard/company/add";
export const EDIT_COMPANY = "/dashboard/company/edit/:id";

export const EMPLOYEE = "/dashboard/employee";
export const ADD_EMPLOYEE = "/dashboard/employee/add";
export const EDIT_EMPLOYEE = "/dashboard/employee/edit/:id";

export const PAYROLL = "/dashboard/payroll/";
export const ADD_PAYROLL = "/dashboard/payroll/add";
export const EDIT_PAYROLL = "/dashboard/payroll/edit/:id";

export const INVOICE = "/dashboard/invoice";
export const PRINT_SLIP = "/dashboard/invoice/PrintSlip";
export const ADD_INVOICE = "/dashboard/invoice/add";
export const EDIT_INVOICE = "/dashboard/invoice/edit/:id";

export const CUSTOMER = "/dashboard/customer";
export const ADD_CUSTOMER = "/dashboard/customer/add";
export const EDIT_CUSTOMER = "/dashboard/customer/edit/:id";
export const REPORTS = "/dashboard/Reports/Reports";
export const FORM = "/dashboard/Reports/Form";
export const FORM_1028 = "/dashboard/Reports/Form1028";
export const FORM_940 = "/dashboard/Reports/Form940";
export const WAGES_TAX = "/dashboard/Reports/WagesTax";
export const WAGES_TAX_EMPLIST = "/dashboard/Reports/WagesTaxEmplist";

export const TIME_SHEET = "/dashboard/timesheet";
export const RUN_PAYROLL = "/dashboard/runpayroll";
export const PAYROLL_SETUP = "/dashboard/Home/PayrollSetup";
export const PAYSCHEDULE = "/dashboard/Home/PaySchedule";
export const PRIOR_PAYROLLS = "/dashboard/Home/PriorPayrolls";
export const APPROVED_PAYROLLS = "/dashboard/runpayroll/approved";
export const APPROVED_PAYROLL_DETAILS = "/dashboard/runpayroll/approved/:id";

// export const EMPLOYEE='/employee'
export const FORGOT = "/forgot";
export const RESET = "/reset";
