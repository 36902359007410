import React, { useState, useEffect } from 'react';
import { DatePicker, Input, Table } from 'antd';
import { ToastContainer, toast } from "react-toastify";
import moment, { Moment } from 'moment';
// import dayjs, { Dayjs } from 'dayjs';
import { DashboardAPI } from '../../../apis/dashboardApi';
import Loader from "../../../components/Loader";

import { useNavigate, useOutletContext } from 'react-router-dom';
import { PAYROLL_SETUP } from '../../../navigation/Constant';


type Props = {};

function PriorPayrolls({ }: Props) {

    const [step, setStep] = useState(1);
    const [approve, setAprrove] = useState<Boolean>(true);
    const [firstStepCompleted, setFirstStepCompleted] = useState(false);
    const [firstCheckDate, setFirstCheckDate] = useState<number>();
    const [lastCheckDate, setLastCheckDate] = useState<number>();
    // const [lastPayDate, setLastPayDate] = useState<number>();
    const [firstPayrollDate, setFirstPayrollDate] = useState<string>();
    const [lastPayrollDate, setLastPayrollDate] = useState<string>();
    const [lastPaymentDate, setLastPaymentDate] = useState<string>();
    const [loading, setLoading] = React.useState(false);
    const [companyData, setCompanyData] = useState<any>([]);
    const { selectedCompany } = useOutletContext<any>();

    const [payrollData, setPayrollData] = useState<any>({});
    const [apiData, setApiData] = useState<any>({});

    const [stage2Data, setStage2Data] = useState<any>({})
    const [reviewTax, setReviewTax] = useState<any>([
        {
            "regular_pay_wages": 300.0,
            "regular_pay_ytd": 300.0,
            "ss_emplyr": 5.1,
            "ss_emplyr_ytd": 5.1,
            "fedral_unmep_emplyr": 1.8,
            "fed_unmep_ytd": 1.8,
            "medicare_emplyr": 5.1,
            "medicare_emplyr_ytd": 5.1,
            "ss_emp": 5.1,
            "ss_emp_ytd": 5.1,
            "fed_emp": 30.0,
            "fed_ytd": 30.0,
            "med_emp": 5.1,
            "med_ytd": 5.1,
            "state_unemp_emplyr": 9.0,
            "state_unemp_ytd": 9.0,
            "state_emp": 30.0,
            "state_ytd": 30.0,
            "city_emp": 30.0,
            "city_ytd": 30.0,
            "subtotal_wages": 300.0,
            "subtotal_emp": 100.2,
            "subtotal_emplyr": 70.2,
            "netwages_wages": 199.8,
            "quarter": 1
        },
        {
            "regular_pay_wages": 300.0,
            "regular_pay_ytd": 300.0,
            "ss_emplyr": 5.1,
            "ss_emplyr_ytd": 5.1,
            "fedral_unmep_emplyr": 1.8,
            "fed_unmep_ytd": 1.8,
            "medicare_emplyr": 5.1,
            "medicare_emplyr_ytd": 5.1,
            "ss_emp": 5.1,
            "ss_emp_ytd": 5.1,
            "fed_emp": 30.0,
            "fed_ytd": 30.0,
            "med_emp": 5.1,
            "med_ytd": 5.1,
            "state_unemp_emplyr": 9.0,
            "state_unemp_ytd": 9.0,
            "state_emp": 30.0,
            "state_ytd": 30.0,
            "city_emp": 30.0,
            "city_ytd": 30.0,
            "subtotal_wages": 300.0,
            "subtotal_emp": 100.2,
            "subtotal_emplyr": 70.2,
            "netwages_wages": 199.8,
            "quarter": 2
        },
        {
            "regular_pay_wages": 300.0,
            "regular_pay_ytd": 300.0,
            "ss_emplyr": 5.1,
            "ss_emplyr_ytd": 5.1,
            "fedral_unmep_emplyr": 1.8,
            "fed_unmep_ytd": 1.8,
            "medicare_emplyr": 5.1,
            "medicare_emplyr_ytd": 5.1,
            "ss_emp": 5.1,
            "ss_emp_ytd": 5.1,
            "fed_emp": 30.0,
            "fed_ytd": 30.0,
            "med_emp": 5.1,
            "med_ytd": 5.1,
            "state_unemp_emplyr": 9.0,
            "state_unemp_ytd": 9.0,
            "state_emp": 30.0,
            "state_ytd": 30.0,
            "city_emp": 30.0,
            "city_ytd": 30.0,
            "subtotal_wages": 300.0,
            "subtotal_emp": 100.2,
            "subtotal_emplyr": 70.2,
            "netwages_wages": 199.8,
            "quarter": 3
        },
        {
            "regular_pay_wages": 300.0,
            "regular_pay_ytd": 300.0,
            "ss_emplyr": 5.1,
            "ss_emplyr_ytd": 5.1,
            "fedral_unmep_emplyr": 1.8,
            "fed_unmep_ytd": 1.8,
            "medicare_emplyr": 5.1,
            "medicare_emplyr_ytd": 5.1,
            "ss_emp": 5.1,
            "ss_emp_ytd": 5.1,
            "fed_emp": 30.0,
            "fed_ytd": 30.0,
            "med_emp": 5.1,
            "med_ytd": 5.1,
            "state_unemp_emplyr": 9.0,
            "state_unemp_ytd": 9.0,
            "state_emp": 30.0,
            "state_ytd": 30.0,
            "city_emp": 30.0,
            "city_ytd": 30.0,
            "subtotal_wages": 300.0,
            "subtotal_emp": 100.2,
            "subtotal_emplyr": 70.2,
            "netwages_wages": 199.8,
            "quarter": 4
        }
    ]);

    const navigate = useNavigate();

    const calculateQuarters = () => {
        if (firstCheckDate && lastCheckDate) {
            const firstMonth = new Date(firstCheckDate).getMonth();
            const lastMonth = new Date(lastCheckDate).getMonth();

            const quarterNames = ['q1', 'q2', 'q3', 'q4'];
            const startQuarter = Math.floor(firstMonth / 3);
            const endQuarter = Math.floor(lastMonth / 3);

            return quarterNames.slice(startQuarter, endQuarter + 1);
        }
        return [];
    };

    const onChange = (date: Moment | null, dateString: string) => {
        console.log(date, dateString);
    };

    const columns = [
        {
            title: 'Item',
            dataIndex: 'contact_name',
            key: 'contact_name',
            sorter: (a: any, b: any) => a.email.length - b.email.length,
        },
        {
            title: 'Wages',
            dataIndex: 'email',
            key: 'email',
            sorter: (a: any, b: any) => a.email.length - b.email.length,
        },
        {
            title: 'Employee Taxes',
            dataIndex: 'mobile_no',
            key: 'mobile_no',
        },
        {
            title: 'Employer Taxes*',
            dataIndex: 'mobile_no',
            key: 'mobile_no',
        },
        {
            title: 'Year to Date',
            dataIndex: 'mobile_no',
            key: 'mobile_no',
        },
    ];

    // const handleContinue = () => {
    //     if (step === 1 && firstCheckDate && lastCheckDate) {
    //         setFirstStepCompleted(true);
    //         setStep(2);
    //     }
    // };

    const showStage = async () => {
        const companyId = selectedCompany;
        if (!companyId) return
        try {
            const response = await DashboardAPI.getShowStage(companyId);
            if (response.success) {
                console.log(response)
                setAprrove(response.data.payroll_approve)
                setStep(response.data.stage + 1)
            } else {
                toast.error("Failed to fetch data from the API")
            }
        } catch (error: any) {
            toast.error('API request error: ' + error.message);
        }
    }

    const handleRestart = async() => {
        // const companyId = selectedCompany;
        // if (step === 2) {
        //     // Reload the page
            
        // }
        try {
            const response = await DashboardAPI.postPayrollRestart(selectedCompany);
            if (response.success) {
                window.location.reload();
            } else {
                toast.error("Failed to fetch data from the API")
            }
        } catch (error: any) {
            toast.error('API request error: ' + error.message);
        }
    };

    interface PayrollData {
        company_id: number;
        first_check_date: string | undefined;
        last_check_date: string | undefined;
        last_pay_date: string | undefined;
        stage: number;
    }
    interface CompanyData {
        regular_pay_Q1?: string;
        regular_pay_Q2?: string;
        regular_pay_Q3?: string;
        regular_pay_Q4?: string;
    }

    const payrollDate = async (data: PayrollData) => {
        setLoading(true);
        try {
            const response = await DashboardAPI.postPayrollDates(data.company_id, data);
            setLoading(false);
            return response.data;
        } catch (error) {
            console.error('Error sending data:', error);
            setLoading(false);
            throw error;
        }
    }

    const handleContinue = async () => {
        if (step === 1 && firstCheckDate && lastCheckDate) {
            setFirstStepCompleted(true);

            const dataToSend = {
                company_id: selectedCompany,
                first_check_date: firstPayrollDate,
                last_check_date: lastPayrollDate,
                last_pay_date: lastPaymentDate,
                stage: 1,
            };

            try {
                const response = await payrollDate(dataToSend);
                console.log('Response from the server:', response);
                setStep(2);
            } catch (error) {
                console.error('Error sending data:', error);
            }
        }
    };

    const PayrollStage2Save = async () => {
        try {
            const data = {
                emp_data: {
                    ...stage2Data,
                    stage: 2,
                    // company_id: selectedCompany,
                },

            };
            const response = await DashboardAPI.postPayrollStage2Save(selectedCompany, data);
            setLoading(false);
            setStep(3)
            return response.data;
        } catch (error) {
            console.error('Error sending data:', error);
            setLoading(false);
            throw error;
        }
    };


    const reviewTaxReturn = async (companyId: number) => {
        // setLoading(true);
        try {
            const response = await DashboardAPI.postPriorPayroll( companyId )

            if (response.success) {
                var abc = Object.entries(response.data.result).map(([quarter, data]) => ({ quarter, ...(data as Record<string, any>) }));
                console.log("abc ::: ", abc)
                setReviewTax(Object.entries(response.data.result).map(([quarter, data]) => ({ quarter, ...(data as Record<string, any>) })));
                setLoading(false);
            } else {
                toast.error("Failed to fetch data from the API")
                setLoading(false);
            }

        } catch (error: any) {
            toast.error('API request error: ' + error.message);
            setLoading(false);
        }
    }

    useEffect(() => {
        showStage();
    }, []);

    useEffect(() => {
        const companyId = selectedCompany;
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await DashboardAPI.postPayrollEmpList(companyId);

                if (response.success) {
                    const arr: any = [];
                    for (const i in response.data.result) {
                        arr.push({
                            emp_id: i,
                            emp_name: response.data.result[i]
                        })
                    }
                    console.log("company data ::: ", arr)
                    setCompanyData(arr)
                    const empIds = arr?.map((item: any) => item.emp_id)
                    let newStage2Data: any = {}
                    for (let i of empIds) {
                        newStage2Data[i] = {
                            emp_name: arr.find((item: any) => item.emp_id === i)?.emp_name,
                            regular_pay_q1: 0,
                            regular_pay_q2: 0,
                            regular_pay_q3: 0,
                            regular_pay_q4: 0,
                            fedral_income_q1: 0,
                            fedral_income_q2: 0,
                            fedral_income_q3: 0,
                            fedral_income_q4: 0,
                            city_tax_q1: 0,
                            city_tax_q2: 0,
                            city_tax_q3: 0,
                            city_tax_q4: 0,
                            state_tax_q1: 0,
                            state_tax_q2: 0,
                            state_tax_q3: 0,
                            state_tax_q4: 0,
                        }
                    }

                    setStage2Data(newStage2Data)


                    setLoading(false);
                } else {
                    toast.error('Failed to fetch data from the API');
                    setLoading(false);
                }
            } catch (error: any) {
                toast.error('API request error: ' + error.message);
                setLoading(false);
            }
        }

        if (step === 2) {
            fetchData();
        }
        if (step === 3) {
            reviewTaxReturn(companyId);
        }
    }, [step]);

    const handleClick = () => {
        setLoading(true);
        setTimeout(() => {
            navigate(PAYROLL_SETUP);
            window.location.reload();
        }, 1000);
    };

    return (

        <div className='container mx-auto pt-[15px] pb-[200px]'>
            <ToastContainer />
            {loading && <Loader />}
            <div className='mt-[20px] items-cetner flex'>
                <p className='font-semibold text-[18px] text-[var(--secondary)]'>
                    Payroll history import
                </p>
            </div>
            <div className='border-[#E9EAEC] border-[1px] pb-[32px] mt-[24px] rounded-[8px]'>
                <div className='p-[10px] bg-[#E6F4FF]'></div>
                <div className='steps mt-[20px] hidden md:flex flex-row justify-start gap-[20px] items-center'>
                    <div
                        className={
                            ' flex items-center gap-[10px] cursor-pointer justify-center px-[10px] py-[5px] '
                        }
                        onClick={() => setStep(1)}>
                        <p
                            className={
                                'font-semibold m-0' +
                                (step === 1
                                    ? ' text-[#000] border-b-[2px] border-[var(--primary)]'
                                    : ' text-[#7C888D]/[0.5]')
                            }>
                            Enter prior payroll dates
                        </p>
                    </div>
                    <div
                        className={
                            ' rounded-[15px] flex cursor-pointer items-center gap-[10px] justify-center px-[10px] py-[5px] '
                        }
                        onClick={() => setStep(2)}>
                        <p
                            className={
                                'font-semibold m-0' +
                                (step === 2
                                    ? ' text-[#000] border-b-[2px] border-[var(--primary)]'
                                    : ' text-[#7C888D]/[0.5]')
                            }>
                            Enter totals by quarter
                        </p>
                    </div>

                    <div
                        className={
                            ' rounded-[15px] flex cursor-pointer items-center gap-[10px] justify-center px-[10px] py-[5px] '
                        }
                        onClick={() => setStep(3)}>
                        <p
                            className={
                                'font-semibold m-0' +
                                (step === 3
                                    ? ' text-[#000] border-b-[2px] border-[var(--primary)]'
                                    : ' text-[#7C888D]/[0.5]')
                            }>
                            Review Tax Returns
                        </p>
                    </div>
                </div>

                {/* Enter prior payroll dates*/}
                {step === 1 && (
                    <div className='grid-row grid-cols-3 ml-4'>
                        <div className='col-span-1 mt-[30px] w-[100%]'>
                            <div className='flex gap-4'>
                                <div>
                                    <p className='text-[#333333] opacity-70 font-semibold text-[15px]'>
                                        First check date in 2023 prior to using Payweek
                                        <span className='text-red'>*</span>
                                    </p>
                                </div>
                                <div>
                                    <DatePicker
                                        size={'large'}
                                        onChange={(date, dateString) => {
                                            setFirstPayrollDate(() => {
                                                let date = new Date(dateString);
                                                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                                            })
                                            setFirstCheckDate(new Date(dateString).getTime())
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1 mt-[30px] w-[100%]'>
                            <div className='flex gap-4'>
                                <div>
                                    <p className='text-[#333333] opacity-70 font-semibold text-[15px]'>
                                        Last check date in 2023 prior to using Payweek
                                        <span className='text-red'>*</span>
                                    </p>
                                </div>
                                <div>
                                    <DatePicker
                                        size={'large'}
                                        onChange={(date, dateString) => {
                                            setLastPayrollDate(() => {
                                                let date = new Date(dateString);
                                                return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                                            })
                                            setLastCheckDate(new Date(dateString).getTime())
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-span-1 mt-[30px] w-[100%]'>
                            <div className='flex gap-4'>
                                <div>
                                    <p className='text-[#333333] opacity-70 font-semibold text-[15px]'>
                                        Last pay period finish date in 2023 prior to using Payweek
                                        <span className='text-red'>*</span>
                                    </p>
                                </div>
                                <div>
                                    <DatePicker size={'large'}
                                        onChange={(date, dateString) => setLastPaymentDate(() => {
                                            let date = new Date(dateString);
                                            return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`
                                        })} />
                                </div>
                            </div>
                        </div>
                        <div className='flex mt-8'>
                            <button
                                className='bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[25px] py-[10px] w-[fit-content] text-[14px]'
                                onClick={handleContinue}>
                                Continue
                            </button>
                        </div>
                    </div>
                )}

                {/* Enter totals by quarter start*/}

                {step === 2 && (
                    <div>
                        {
                            companyData.map((cmp: any, i: number) => (
                                <div className='m-4' key={i}>
                                    <p className='text-[#333333] my-4 opacity-70 font-bold text-[16px]'>{cmp.emp_name}</p>
                                    {calculateQuarters().map((quarter, index) => (
                                        <div key={index}>
                                            <div className='grid grid-cols-4 gap-4'>
                                                <div className='col-span-1'>
                                                    <label className='font-semibold'>{`Regular Pay ${quarter}`}</label>
                                                    <Input
                                                        required
                                                        type='number'
                                                        name={"regular_pay_" + quarter}
                                                        placeholder={`Enter Regular Pay for ${quarter}`}

                                                        value={stage2Data[cmp?.emp_id]["regular_pay_" + quarter]}
                                                        onChange={(e) => {

                                                            setStage2Data((prev: any) => ({
                                                                ...prev,
                                                                [cmp.emp_id]: {
                                                                    ...prev[cmp.emp_id],
                                                                    ["regular_pay_" + quarter]: e.target.value
                                                                }
                                                            }))
                                                        }}

                                                    />
                                                </div>
                                                <div className='col-span-1'>
                                                    <label className='font-semibold'>{`Federal Income Tax ${quarter}`}</label>
                                                    <Input
                                                        required
                                                        type='number'
                                                        name={`fedral_income_${quarter}`}
                                                        value={stage2Data[cmp?.emp_id]["fedral_income_" + quarter]}
                                                        onChange={(e) => {
                                                            setStage2Data((prev: any) => ({
                                                                ...prev,
                                                                [cmp.emp_id]: {
                                                                    ...prev[cmp.emp_id],
                                                                    ["fedral_income_" + quarter]: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                        placeholder={`Federal Income Tax for ${quarter}`}
                                                    />
                                                </div>
                                                <div className='col-span-1'>
                                                    <label className='font-semibold'>{`City Tax ${quarter}`}</label>
                                                    <Input
                                                        required
                                                        type='number'
                                                        name={`city_tax_${cmp}_${quarter}`}
                                                        value={stage2Data[cmp?.emp_id]["city_tax_" + quarter]}
                                                        onChange={(e) => {
                                                            setStage2Data((prev: any) => ({
                                                                ...prev,
                                                                [cmp.emp_id]: {
                                                                    ...prev[cmp.emp_id],
                                                                    ["city_tax_" + quarter]: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                        placeholder={`Enter City Tax for ${quarter}`}
                                                    />
                                                </div>
                                                <div className='col-span-1'>
                                                    <label className='font-semibold'>{`State Tax ${quarter}`}</label>
                                                    <Input
                                                        required
                                                        type='number'
                                                        name={`state_tax_${cmp}_${quarter}`}
                                                        placeholder={`Enter State Tax for ${quarter}`}
                                                        value={stage2Data[cmp?.emp_id]["state_tax_" + quarter]}
                                                        onChange={(e) => {
                                                            setStage2Data((prev: any) => ({
                                                                ...prev,
                                                                [cmp.emp_id]: {
                                                                    ...prev[cmp.emp_id],
                                                                    ["state_tax_" + quarter]: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {calculateQuarters().length === 0 && (
                                        <p>No quarters to display. Please select valid dates.</p>
                                    )}
                                </div>
                            ))
                        }
                        <div className='flex justify-between mt-[32px] px-4'>
                            <button
                                className='bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[25px] py-[10px] w-[fit-content] text-[14px]'
                                onClick={() => PayrollStage2Save()}>
                                Continue
                            </button>
                            {firstStepCompleted && approve === true ? null : (
                                <button
                                    className='bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[25px] py-[10px] w-[fit-content] text-[14px]'
                                    onClick={handleRestart}>
                                    Restart
                                </button>
                            )}
                        </div>
                    </div>
                )}
                {step === 3 && (
                    <div className='w-full'>
                        {
                            reviewTax.map((tax: any, i: number) => (
                                <div key={i}>
                                    {
                                        tax.netwages_wages !== 0 && (
                                            <div className='p-4'>
                                                <p className='text-[#333333] my-1 opacity-70 font-bold text-xl'>q{tax.quarter} wages and taxes</p>
                                                <p>* Some taxes are automatically calculated</p>
                                                <table className='tax-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>Item</th>
                                                            <th>Wages</th>
                                                            <th>Employee Taxes</th>
                                                            <th>Employer Taxes*</th>
                                                            <th>Year to Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Regular Pay</td>
                                                            <td>${tax.regular_pay_wages}</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>${tax.regular_pay_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Employer Social Security</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>${tax.ss_emplyr}</td>
                                                            <td>${tax.ss_emplyr_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Federal Unemployment Tax</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>${tax.fedral_unmep_emplyr}</td>
                                                            <td>${tax.fed_unmep_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Employer Medicare Tax</td>
                                                            <td></td>
                                                            <td>${tax.med_emp}</td>
                                                            <td></td>
                                                            <td>${tax.med_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Social Security</td>
                                                            <td></td>
                                                            <td>${tax.ss_emp}</td>
                                                            <td></td>
                                                            <td>${tax.ss_emp_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Federal Income Tax</td>
                                                            <td></td>
                                                            <td>${tax.fed_emp}</td>
                                                            <td></td>
                                                            <td>${tax.fed_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Medicare</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>${tax.medicare_emplyr}</td>
                                                            <td>${tax.medicare_emplyr_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Additional Medicare</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>State Unemployment Tax</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>${tax.state_unemp_emplyr}</td>
                                                            <td>${tax.state_unemp_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>State Tax</td>
                                                            <td></td>
                                                            <td>${tax.state_emp}</td>
                                                            <td></td>
                                                            <td>${tax.state_ytd}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>City Tax</td>
                                                            <td></td>
                                                            <td>${tax.city_emp}</td>
                                                            <td></td>
                                                            <td>${tax.city_ytd}</td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot>
                                                        <tr>
                                                            <th>Subtotals</th>
                                                            <th>${tax.subtotal_wages}</th>
                                                            <th>${tax.subtotal_emp}</th>
                                                            <th>${tax.subtotal_emplyr}</th>
                                                            <th></th>
                                                        </tr>
                                                        <tr>
                                                            <th>Net wages</th>
                                                            <th>${tax.netwages_wages}</th>
                                                            <th></th>
                                                            <th></th>
                                                            <th></th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        )
                                    }
                                </div>
                            ))
                        }
                        <div className='flex items-center justify-between p-4'>
                        <div className=''>
                            <button
                                className='bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[25px] py-[10px] w-[fit-content] text-[14px]'
                                onClick={handleClick}>
                                Go to initial Setup
                            </button>
                        </div>
                        {approve === true ? null : (
                            <button
                                className='bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[25px] py-[10px] w-[fit-content] text-[14px]'
                                onClick={handleRestart}>
                                Restart
                            </button>
                        )}
                    </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PriorPayrolls;
