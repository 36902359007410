import { api } from "./config/axiosConfig";
import { defineCancelApiObject } from "./config/axiosUtils";


export const AuthAPI = {
    postRegister: async (register, cancel = false) => {
        console.log("userregistrationapi");
        const response = await api.request({
            url: "payroll/registration",
            method: "POST",
            headers: { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('fbToken'))}` },
            data: register,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postLogin: async (login, cancel = false) => {
        const response = await api.request({
            url: "payroll/login",
            method: "POST",
            data: login,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postVerifyOtp: async (otp, cancel = false) => {
        const response = await api.request({
            url: "payroll/otp",
            method: "POST",
            data: otp,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postForgotPassword: async (otp, cancel = false) => {
        const response = await api.request({
            url: "payroll/forgot_password ",
            method: "POST",
            data: otp,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postResetPasswordEmailOTP: async (otp, cancel = false) => {
        const response = await api.request({
            url: "payroll/reset_password_email_otp_check ",
            method: "POST",
            data: otp,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postResetPassword: async (otp, cancel = false) => {
        const response = await api.request({
            url: "payroll/password_reset",
            method: "POST",
            data: otp,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },

    postChangePassword: async (otp, cancel = false) => {
        const response = await api.request({
            url: "payroll/change_password",
            method: "POST",
            data: otp,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },
  
   

    getExtentionList: async (cancel = false) => {
        const response = await api.request({
            url: "payroll/extension_list",
            method: "GET",
            headers: { 'Authorization': `Bearer ${JSON.parse(localStorage.getItem('fbToken'))}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    },
    postLogout: async (cancel = false) => {
        const response = await api.request({
            url: "payroll/logout",
            method: "GET",
            headers:  { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined
        });

        return response.data;
    }
}

const cancelApiObject = defineCancelApiObject(AuthAPI);
