import React, { useState } from "react";

import { Route, Routes } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Register from "../pages/Auth/Register";
import VerifyOtp from "../pages/Auth/VerifyOtp";
import {
  ADD_COMPANY,
  ADD_EMPLOYEE,
  EMPLOYEE,
  LOGIN,
  REGISTER,
  ROOT,
  VERIFY_OTP,
  FORGOT,
  RESET,
  EDIT_EMPLOYEE,
  EDIT_COMPANY,
  PAYROLL,
  CUSTOMER,
  DASHBOARD,
  INVOICE,
  ADD_INVOICE,
  ADD_CUSTOMER,
  DASHBOARD_HOME,
  EDIT_INVOICE,
  EDIT_CUSTOMER,
  CHANGE_PASSWORD,
  TIME_SHEET,
  RUN_PAYROLL,
  APPROVED_PAYROLLS,
  APPROVED_PAYROLL_DETAILS,
  PRINT_SLIP,
  REPORTS,
  FORM,
  FORM_940,
  WAGES_TAX,
  WAGES_TAX_EMPLIST,
  PAYROLL_SETUP,
  PAYSCHEDULE,
  PRIOR_PAYROLLS,
  FORM_1028
} from "./Constant";

// import Employee from "../pages/Employee/Employee";
import Forgot from "../pages/Auth/Forgot";
import Reset from "../pages/Auth/Reset";
// import { EMPLOYEE, LOGIN, REGISTER, ROOT, VERIFY_OTP } from "./Constant";
import NotFound from "./NotFound";
import Employee from "../pages/Dashboard/Employee";
import AddEmployee from "../pages/Dashboard/Employee/AddEmployee";
import AddCompany from "../pages/Dashboard/Company/AddCompany";
import EditEmployee from "../pages/Dashboard/Employee/EditEmployee";

import withDashboard from "../hoc/DashboardHoc";
import EditCompany from "../pages/Dashboard/Company/EditCompany";
import Customer from "../pages/Dashboard/Customer";
import Payroll from "../pages/Dashboard/Payroll";
import DashboardHome from "../pages/Dashboard/Home";
import Invoice from "../pages/Dashboard/Invoice";
import AddInvoice from "../pages/Dashboard/Invoice/AddInvoice";
import AddCustomer from "../pages/Dashboard/Customer/AddCustomer";
import Timesheet from "../pages/Dashboard/Timesheet";
import DashboardLayout from "../components/Dashboard/DashboardLayout";
import ChangePassword from "../pages/Auth/ChangePassword";
import TimeSheet from "../pages/TimeSheet/TimeSheet";
import RunPayroll from "../pages/Dashboard/RunPayroll/RunPayroll";
import ApprovedPayrolls from "../pages/Dashboard/RunPayroll/ApprovedPayrolls";
import ApprovedPayrollDetails from "../pages/Dashboard/RunPayroll/ApprovedPayrollDetails";
import PrintSlip from "../pages/Dashboard/RunPayroll/PrintSlip";
import Reports from "../pages/Dashboard/Reports/Reports";
import Form from "../pages/Dashboard/Reports/Form";
import Form1024 from "../pages/Dashboard/Reports/Form1028";
import Form940 from "../pages/Dashboard/Reports/Form940";
import WagesTax from "../pages/Dashboard/Reports/WagesTax";
import WagesTaxEmplist from "../pages/Dashboard/Reports/WagesTaxEmplist";
import PayrollSetup from "../pages/Dashboard/Home/PayrollSetup";
import PaySchedule from "../pages/Dashboard/Home/PaySchedule";
import PriorPayrolls from "../pages/Dashboard/Home/PriorPayrolls";

export const RouterConfig = () => {
  const EmployeeWithAccess = withDashboard(AddEmployee);
  const EditEmployeeWithAccess = withDashboard(EditEmployee);

  const [company, setCompany] = React.useState<any>(false);

  

  return (
    <Routes>
      <Route path={LOGIN} element={<Login />} />
      <Route path={REGISTER} element={<Register />} />
      <Route path={VERIFY_OTP} element={<VerifyOtp />} />
      <Route path={CHANGE_PASSWORD} element={<ChangePassword />} />

      <Route path={ROOT} element={<Login />} />

      <Route path={DASHBOARD} element={<DashboardLayout company={company} />}>
        <Route path={DASHBOARD_HOME} element={<DashboardHome />} />
        <Route path={EMPLOYEE} element={<Employee />} />

        <Route path={ADD_EMPLOYEE} element={<EmployeeWithAccess />} />
        <Route path={EDIT_EMPLOYEE} element={<EditEmployeeWithAccess />} />

        <Route
          path={ADD_COMPANY}
          element={<AddCompany setCompany={setCompany} />}
        />
        <Route path={EDIT_COMPANY} element={<EditCompany />} />

        <Route path={INVOICE} element={<Invoice />} />
        <Route path={PRINT_SLIP} element={<PrintSlip company={company}  />} />
        <Route path={REPORTS} element={<Reports />} />
        <Route path={FORM} element={<Form company={company} />} />

        <Route path={FORM_1028} element={<Form1024 company={company} />} />
        <Route path={FORM_940} element={<Form940 company={company}/>} />
        <Route
          path={WAGES_TAX}
          element={<WagesTax />}
        />
        <Route
          path={WAGES_TAX_EMPLIST}
          element={<WagesTaxEmplist company={company}/>}
        />
        <Route path={ADD_INVOICE} element={<AddInvoice />} />
        <Route path={EDIT_INVOICE} element={<AddInvoice />} />

        <Route path={CUSTOMER} element={<Customer />} />
        <Route path={ADD_CUSTOMER} element={<AddCustomer />} />
        <Route path={EDIT_CUSTOMER} element={<AddCustomer />} />

        <Route path={TIME_SHEET} element={<TimeSheet />} />
        <Route path={RUN_PAYROLL} element={<RunPayroll />} />
        <Route path={APPROVED_PAYROLLS} element={<ApprovedPayrolls />} />
        <Route path={PAYROLL_SETUP} element={<PayrollSetup />} />
        <Route path={PAYSCHEDULE} element={<PaySchedule />} />
        <Route path={PRIOR_PAYROLLS} element={<PriorPayrolls />} />

        <Route
          path={APPROVED_PAYROLL_DETAILS}
          element={<ApprovedPayrollDetails />}
        />
      </Route>

      <Route path={FORGOT} element={<Forgot />} />
      <Route path={RESET} element={<Reset />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
