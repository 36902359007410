import React, { useEffect, useState } from 'react'
import Input from '../../components/Input/Input'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/index'
import { useNavigate } from 'react-router-dom'
import { LOGIN, VERIFY_OTP } from '../../navigation/Constant'
import { EMAIL_REGEX, PHONE_REGEX } from '../../constants/regex'
import { AuthAPI } from '../../apis/authAPI'
import { Select } from 'antd'

const logo = require('../../assets/images/payweek.png')

const initialErrorState = {
  error: false,
  msg: ''
}

const initialData = {
  email: '',
  password: '',
  phone: '',
  firstName: '',
  lastName: '',
  confirmPassword: '',
  extention: '+1'
}

const Register = () => {


  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [formData, setFormData] = useState(initialData)

  const [extention, setExtention] = useState([])


  const navigate = useNavigate()

  const handleLogin = async () => {


    if (!formData.email || !formData.password || !formData.firstName || !formData.lastName || !formData.phone || !formData.confirmPassword || !formData.extention) {
      return toast.error('Please fill all the fields')
    }

    if (validateSubmit()) {
      return toast.error('Please fill all the details correctly')
    }



    try {
      setLoading(true)
      const data = await AuthAPI.postRegister({
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        mobile_no: Number(formData.phone),
        extension: formData.extention,
        password: formData.password,
        confirm_password: formData.confirmPassword
      })

      if (data.success) {
        navigate(VERIFY_OTP)
        toast.success(data.message);
        localStorage.setItem('regsiteredUser', JSON.stringify(data.data.values))
      }

    } catch (error: any) {
      console.log('here')
      const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
      console.log(errMsg)
      return toast.error(errMsg, {
        position: "top-right",
        // autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false)
    }
  }


  const getExtention = async () => {
    try {
      const data = await AuthAPI.getExtentionList();
      if (data.success) {
        // Exclude the option with value "+91" from the extension list
        const extensionList = data?.data?.extension.filter((item: string) => item !== "+91");

        // Set the modified extension list with label and value properties
        setExtention(extensionList.map((item: string) => ({ label: item, value: item })));
      }
    } catch (error: any) {
      console.log('here');
      const errMsg = error?.response?.data?.message || error?.message || "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        // autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };




  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const truncatedValue = value.slice(0, 12);
      setFormData((prev: any) => ({ ...prev, [name]: truncatedValue }));
    } else {
      setFormData((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const [formErr, setFormErr] = useState({
    firstName: initialErrorState,
    lastName: initialErrorState,
    email: initialErrorState,
    phone: initialErrorState,
    password: initialErrorState,
    confirmPassword: initialErrorState,
  })

  const validate = (e: any) => {
    const { name, value } = e.target
    if (name === 'email') {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev, email: {
            error: true,
            msg: 'Please enter email'
          }
        }))
      } else if (!EMAIL_REGEX.test(value)) {
        setFormErr((prev: any) => ({
          ...prev, email: {
            error: true,
            msg: 'Please enter valid email'
          }
        }))
      } else {
        setFormErr((prev: any) => ({ ...prev, email: initialErrorState }))
      }

    } else if (name === 'password') {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev, password: {
            error: true,
            msg: 'Please enter password'
          }
        }))
      } else {
        setFormErr((prev: any) => ({ ...prev, password: initialErrorState }))
      }
    } else if (name === 'firstName') {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev, firstName: {
            error: true,
            msg: 'Please enter first name'
          }
        }))
      } else {
        setFormErr((prev: any) => ({ ...prev, firstName: initialErrorState }))
      }
    } else if (name === 'lastName') {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev, lastName: {
            error: true,
            msg: 'Please enter last name'
          }
        }))
      } else {
        setFormErr((prev: any) => ({ ...prev, lastName: initialErrorState }))
      }
    } else if (name === 'phone') {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev, phone: {
            error: true,
            msg: 'Please enter phone number'
          }
        }))
      } else if (!PHONE_REGEX.test(value) || value.length !== 10) {
        setFormErr((prev: any) => ({
          ...prev,
          phone: {
            error: true,
            msg: 'Please enter a valid 10-digit phone number'
          }
        }))
      } else {
        setFormErr((prev: any) => ({ ...prev, phone: initialErrorState }))
      }
    }
    else if (name == 'confirmPassword') {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev, confirmPassword: {
            error: true,
            msg: 'Please enter confirm password'
          }
        }))
      } else if (value != formData.password) {
        setFormErr((prev: any) => ({
          ...prev, confirmPassword: {
            error: true,
            msg: 'Password and confirm password should be same'
          }
        }))
      } else {
        setFormErr((prev: any) => ({ ...prev, confirmPassword: initialErrorState }))
      }
    }
    else if (name == 'password') {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev, password: {
            error: true,
            msg: 'Please enter password'
          }
        }))
      } else {
        setFormErr((prev: any) => ({ ...prev, password: initialErrorState }))
      }
    }
  }


  const validateSubmit = () => {
    if (formErr.firstName.error || formErr.lastName.error || formErr.email.error || formErr.phone.error || formErr.password.error || formErr.confirmPassword.error) {
      return true
    }
  }

  useEffect(() => {
    getExtention()
  }, [])

  useEffect(() => {
    localStorage.clear()
  }, [])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='bg-[#F4F4F496]'>

        <div className='min-h-[100vh] container py-[30px] mx-auto flex flex-col items-center'>

          <ToastContainer />
          {
            loading && <Loader />
          }
          <div className='flex w-[100%] items-start sm:items-center justify-center sm:justify-start sm:mb-[60px] mb-[25px]'>
            <img src={logo} alt="" className='sm:w-[180px] w-[140px]' />
          </div>
          <div className='flex flex-col gap-[10px] card sm:w-[50%] w-[90%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#ffffff] py-[30px] px-[20px] rounded-[8px] mx-auto'>
            <h1 className='text-center text-[#5ABC4BE0] sm:text-3xl text-2xl font-bold'>CREATE YOUR PAYWEEK ACCOUNT</h1>

            <div className='grid grid-cols-2 gap-[15px] sm:w-[70%] w-[95%] mt-[30px] mx-auto'>

              <Input label="First Name" name="firstName" type="text" placeholder="Enter First Name" handleChange={handleChange} className="bg-[#F4F4F4] rounded-[12px]" value={formData?.firstName} onBlur={validate} />

              <Input label="Last Name" name="lastName" type="text" placeholder="Enter Last Name" handleChange={handleChange} className="bg-[#F4F4F4] rounded-[12px]" value={formData?.lastName} onBlur={validate} errTxt={formErr?.lastName?.msg} />

              <div className='col-span-2'>
                <Input label="Email" name="email" type="email" placeholder="Enter your Email" handleChange={handleChange} className="bg-[#F4F4F4] rounded-[12px]" value={formData?.email} onBlur={validate} errTxt={formErr?.email?.msg} />
              </div>

              <div className="col-span-2">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">Mobile No.</label>
                <div className="grid grid-cols-6 gap-[4px]">
                  <div className='col-span-1'>
                    <Select
                      defaultValue={formData?.extention}
                      style={{ marginTop: '5px', backgroundColor: '#F4F4F4' }}
                      className='border-[1px] text-[14px] rounded-[4px] p-[0] mt-[5px] sm:w-full h-[90%]'
                      options={[...extention]}
                      onChange={(e) => {
                        setFormData((prev: any) => ({ ...prev, extention: e }))
                      }
                      }
                    />
                  </div>
                  <div className='col-span-5'>
                    <Input name={'phone'} type={'number'} className={"border-[1px] w-auto text-[14px] p-[10px] mt-[5px] bg-[#F4F4F4] rounded-[12px]"} placeholder={'Enter your Mobile Number'} value={formData?.phone} handleChange={handleChange} onBlur={validate} errTxt={formErr?.phone?.msg} />
                  </div>
                </div>
              </div>

              <div className="col-span-2">


                <Input label="Password" name="password" type="password" placeholder="Enter your Password" className="bg-[#F4F4F4] rounded-[12px]" handleChange={handleChange} value={formData?.password} onBlur={validate} errTxt={formErr?.password?.msg} />
              </div>
              <div className="col-span-2">

                <Input label="Confirm Password" name="confirmPassword" type="password" placeholder="Enter your Password" className="bg-[#F4F4F4] rounded-[12px]" handleChange={handleChange} value={formData?.confirmPassword} onBlur={validate} errTxt={formErr?.confirmPassword?.msg} />
              </div>


            </div>

            <button className="bg-[#447EC1]  text-[#fff] text-[14px] px-[45px] mx-auto font-semibold rounded-[12px]  py-[8px] mt-[15px]"
              type='submit'
            >Sign up</button>

            <p className='text-[#000] text-center text-[#000]/[0.7] mt-[15px] font-medium'>Have a Payweek account? <span className='text-right text-[#5ABC4BE0] hover:underline cursor-pointer' onClick={() => {
              navigate(LOGIN)
            }}>Sign In</span></p>
          </div>

        </div>
      </div>
    </form>
  )
}

export default Register