import React, { useEffect } from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import { Select } from "antd";
import Input from "../../../components/Input/Input";
import { useNavigate, useParams } from "react-router-dom";
import { INVOICE } from "../../../navigation/Constant";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ToastContainer, toast } from "react-toastify";

const initialData = {
  company_id: "",
  customer_id: "",
  po_no: "",
  invoice_date: "",
  due_date: "",
  invoice_subtotal: 0,
  discount: 0,
  total: 0,
  sales_tax: 0,
  items: [],
};

const AddInvoice = () => {
  const [data, setData] = React.useState<any>({ ...initialData });

  const [edit, setEdit] = React.useState<boolean>(false);

  const [items, setItems] = React.useState<any>({
    item_name: "",
    item_quantity: 0,
    item_price: 0,
    amount: 0,
  });
  const [formErrors, setFormErrors] = React.useState<any>({});

  const [itemDeleted, setItemDeleted] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChange = (e: any) => {
    const { name, value, type } = e.target;

    if (type == "number") {
      const floatValue = parseFloat(e.target.value);
      setData((prev: any) => ({
        ...prev,
        [name]: isNaN(floatValue) ? 0.0 : floatValue,
      }));
      setFormErrors((prev: any) => ({ ...prev, [name]: "" }));

      return;
    }
    setData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev: any) => ({ ...prev, [name]: "" }));
  };

  const addItem = () => {
    if (items.item_name === "") {
      setFormErrors((prev: any) => ({ ...prev, item_name: "Required" }));
      return;
    }

    if (items.item_quantity === 0) {
      setFormErrors((prev: any) => ({
        ...prev,
        item_quantity: "Required",
      }));
      return;
    }

    if (items.item_price === 0) {
      setFormErrors((prev: any) => ({ ...prev, item_price: "Required" }));
      return;
    }

    setData((prev: any) => ({
      ...prev,
      items: [
        ...prev.items,
        { ...items, amount: items.item_quantity * items.item_price },
      ],
    }));

    setItems({
      item_name: "",
      item_quantity: 0,
      item_price: 0,
      amount: 0,
    });
  };

  const resetForm = () => {
    setData({ ...initialData });
    setItems({
      item_name: "",
      item_quantity: 0,
      item_price: 0,
      amount: 0,
    });
  };

  const navigate = useNavigate();

  const [company, setCompany] = React.useState<any>([]);
  const [customer, setCustomer] = React.useState<any>([]);

  const { id } = useParams();

  const getMasterData = async () => {
    try {
      const [data1, data2] = await Promise.all([
        DashboardAPI.getCompany().catch((err) => {
          console.log(err);
          return [];
        }),
        DashboardAPI.getCustomer().catch((err) => {
          console.log(err);
          return [];
        }),
      ]);

      setCompany(
        data1?.data?.values?.map((item: any) => {
          return {
            label: item.company_name,
            value: item.company_id,
          };
        }) || []
      );
      setCustomer(
        data2?.data?.values?.map((item: any) => {
          return {
            label: item.name,
            value: item.customer_id,
            state: item.bill_state,
          };
        }) || []
      );
    } catch (err: any) {
      console.log(err);
      // const errMsg = err?.response?.data?.message || 'Something went wrong'
      //     toast.error(errMsg)
    } finally {
      // setLoading(false)
    }
  };

  const getSalesInfo = async (state: string) => {
    try {
      setLoading(true);
      const data = await DashboardAPI.postSalesTax({
        state_name: state,
      });

      if (data.success) {
        setData((prev: any) => ({
          ...prev,
          sales_tax: data.data.values,
        }));
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMasterData();
  }, []);

  const validateForm = () => {
    if (data.company_id === "") {
      setFormErrors((prev: any) => ({ ...prev, company_id: "Required" }));
      return false;
    } else if (data.customer_id === "") {
      setFormErrors((prev: any) => ({ ...prev, customer_id: "Required" }));
      return false;
    } else if (data.po_no === "") {
      setFormErrors((prev: any) => ({ ...prev, po_no: "Required" }));
      return false;
    } else if (data.invoice_date === "") {
      setFormErrors((prev: any) => ({ ...prev, invoice_date: "Required" }));
      return false;
    } else if (data.due_date === "") {
      setFormErrors((prev: any) => ({ ...prev, due_date: "Required" }));
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (!validateForm()) {
        return;
      }

      if (data.items.length === 0) {
        if (items.item_name === "") {
          setFormErrors((prev: any) => ({ ...prev, item_name: "Required" }));
          return;
        } else if (items.item_quantity === 0) {
          setFormErrors((prev: any) => ({
            ...prev,
            item_quantity: "Required",
          }));
          return;
        } else if (items.item_price === 0) {
          setFormErrors((prev: any) => ({ ...prev, item_price: "Required" }));
          return;
        }
      }

      if (items.item_name) {
        if (items.item_quantity === 0) {
          setFormErrors((prev: any) => ({
            ...prev,
            item_quantity: "Required",
          }));
          return;
        } else if (items.item_price === 0) {
          setFormErrors((prev: any) => ({ ...prev, item_price: "Required" }));
          return;
        }
      }

      const payload: any = {
        invoice: {
          company_id: Number(data.company_id),
          customer_id: Number(data.customer_id),
          invoice_subtotal: Number(data.invoice_subtotal),
          discount: Number(data.discount),
          total: Number(data.total),
          po_no: Number(data.po_no),
          invoice_date: data.invoice_date,
          due_date: data.invoice_date,
        },
        items: [
          ...data.items.map((i: any) => ({
            item_id: i.item_id,
            item_name: i.item_name,
            item_quantity: Number(i.item_quantity),
            item_price: Number(i.item_price),
          })),
        ],
      };

      if (edit && itemDeleted.length > 0) {
        payload.items_delete = itemDeleted;
      }

      if (items.item_name && items.item_quantity && items.item_price) {
        payload.items.push({
          item_name: items.item_name,
          item_quantity: Number(items.item_quantity),
          item_price: Number(items.item_price),
        });
      }
      const dataum = !edit
        ? await DashboardAPI.postAddInvoice(payload)
        : await DashboardAPI.postUpdateInvoiceById(id, payload);

      if (dataum.success) {
        toast.success(dataum.message);
        resetForm();
        setTimeout(() => navigate(INVOICE), 1000);
      }
    } catch (err: any) {
      console.log(err);

      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
    }
  };

  useEffect(() => {
    let subtotal = 0;
    data.items.forEach((item: any) => {
      if (itemDeleted.includes(item.item_id)) {
        return;
      }
      subtotal += item.amount;
    });

    if (items.item_price && items.item_quantity) {
      subtotal += items.item_price * items.item_quantity;
    }
    setData((prev: any) => ({ ...prev, invoice_subtotal: subtotal }));
  }, [data.items, items, itemDeleted]);

  useEffect(() => {
    let total = 0;
    total = data.invoice_subtotal - data.discount;
    total -= total * (data?.sales_tax / 100);

    if (total < 0) {
      setData((prev: any) => ({ ...prev, total: 0, discount: 0 }));
      toast.error("Discount can't be greater than subtotal");
      return;
    }

    setData((prev: any) => ({ ...prev, total }));
  }, [data.invoice_subtotal, data.discount, data?.sales_tax]);

  // EDIT PART

  const getInvoiceById = async () => {
    try {
      setLoading(true);
      const dataum = await DashboardAPI.getInvoiceById(id);
      if (dataum.success) {
        console.log(dataum.data);
        setData((prev: any) => ({
          ...prev,
          ...dataum.data?.values?.invoice[0],
          items: dataum.data?.values?.items.map((item: any) => ({
            ...item,
            amount: item.item_price * item.item_quantity,
          })),
        }));
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      setEdit(true);
      getInvoiceById();
    }
  }, []);

  return (
    <div className="mt-[20px] pb-[150px]">
      <ToastContainer />
      <div className="flex justify-between">
        <h2 className="font-semibold text-[var(--secondary)] text-[18px]">
          {" "}
          <span
            className="hover:underline cursor-pointer"
            onClick={() => navigate(INVOICE)}
          >
            Invoice{" "}
          </span>{" "}
          / {edit ? "Edit" : "New"}
        </h2>
        <i
          className="bi font-semibold bi-arrow-counterclockwise cursor-pointer text-[20px]"
          title="Discard"
          onClick={() => {
            resetForm();
          }}
        ></i>
      </div>

      <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
        <div className="content_box">
          {/* <div className="header p-[10px] bg-[#4784CA]">
              <p className="font-semibold m-0 text-[#fff]">Create Invoice</p>
            </div> */}
          <div className="body p-[10px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[15px] mb-[20px]">
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Select Company <span className="text-[red]">*</span>
                </label>
                <div className="flex gap-[10px] mt-[5px] flex-col">
                  <Select
                    // defaultValue={data?.company_id}
                    className="border-[1px] text-[14px] w-[100%] h-[40px]  rounded-[4px] p-[0] "
                    options={company}
                    value={data?.company_id}
                    onChange={(e) => {
                      setData((prev: any) => ({ ...prev, company_id: e }));
                      setFormErrors((prev: any) => ({
                        ...prev,
                        company_id: "",
                      }));
                    }}
                  />
                  {formErrors?.company_id && (
                    <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                      {formErrors?.company_id}{" "}
                    </label>
                  )}
                </div>
              </div>

              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Select Customer <span className="text-[red]">*</span>
                </label>
                <div className="flex gap-[10px] mt-[5px] flex-col">
                  <Select
                    defaultValue={data?.customer_id}
                    className="border-[1px] text-[14px] w-[100%] h-[40px]  rounded-[4px] p-[0] "
                    options={customer}
                    value={data?.customer_id}
                    onChange={(e) => {
                      getSalesInfo(
                        customer?.find((item: any) => item?.value === e)?.state
                      );
                      setData((prev: any) => ({ ...prev, customer_id: e }));
                      setFormErrors((prev: any) => ({
                        ...prev,
                        customer_id: "",
                      }));
                    }}
                  />
                  {formErrors?.customer_id && (
                    <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                      {formErrors?.customer_id}{" "}
                    </label>
                  )}
                </div>
              </div>

              <div className="col-span-1">
                <Input
                  label="P.O. / S.O. Number"
                  type={"number"}
                  placeholder="Enter P.O. / S.O. Number"
                  value={data?.po_no}
                  name="po_no"
                  handleChange={handleChange}
                  errTxt={formErrors.po_no}
                  required
                />
              </div>
              <div className="col-span-1"></div>
              <div className="col-span-1">
                <Input
                  label="Invoice Date"
                  type={"date"}
                  placeholder="Enter Invoice Date"
                  value={data?.invoice_date}
                  name="invoice_date"
                  required
                  handleChange={handleChange}
                  errTxt={formErrors.invoice_date}
                />
              </div>
              <div className="col-span-1">
                <Input
                  label="Due Date"
                  type={"date"}
                  placeholder="Enter Due Date"
                  value={data?.due_date}
                  name="due_date"
                  required
                  handleChange={handleChange}
                  errTxt={formErrors.invoice_date}
                />
              </div>
            </div>

            <hr className="" />

            <div className="bg-[#e5e5e5]/[0.5] p-[10px]">
              <h2 className="text-semibold text-[18px] text-[var(--primary)] font-semibold ">
                Items
              </h2>

              <div className="grid bg-[#000]/[0.05] w-[100%] p-[5px] rounded-[4px] grid grid-cols-12 gap-[10px] mt-[15px]">
                <div className="col-span-5">
                  <p>Item Name</p>
                </div>
                <div className="col-span-2">
                  <p>Item Quantity</p>
                </div>
                <div className="col-span-2">
                  <p>Item Price ( $ )</p>
                </div>
                <div className="col-span-2">
                  <p>Amount</p>
                </div>
              </div>

              {data?.items?.map((item: any, index: number) => {
                return (
                  <div className="grid bg-[#000]/[0.05] p-[5px] rounded-[4px] grid grid-cols-12 gap-[10px] mt-[15px]">
                    <div className="col-span-5">
                      <Input
                        // label="Item Name"
                        disabled
                        placeholder="Enter Item Name"
                        value={item?.item_name}
                        name="item_name"
                        handleChange={(e: any) => {
                          setItems((prev: any) => ({
                            ...prev,
                            item_name: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-span-2">
                      <Input
                        // label="Item Quantity"
                        disabled
                        type={"number"}
                        placeholder="Enter Item Quantity"
                        value={item?.item_quantity}
                        name="item_quantity"
                        handleChange={(e: any) => {
                          setItems((prev: any) => ({
                            ...prev,
                            item_quantity: e.target.value,
                          }));
                        }}
                      />
                    </div>
                    <div className="col-span-2">
                      <Input
                        // label="Item Price ( $ )"
                        disabled
                        type={"number"}
                        placeholder="Enter Item price"
                        value={Number(item?.item_price)?.toFixed(2)}
                        name="item_price"
                        handleChange={(e: any) => {
                          setItems((prev: any) => ({
                            ...prev,
                            item_price: e.target.value,
                          }));
                        }}
                      />
                    </div>

                    <div className="col-span-2">
                      <Input
                        // label="Amount"
                        type={"number"}
                        className={"bg-[#fff]/[0.8] cursor-not-allowed"}
                        placeholder="Enter Amount"
                        value={Number(item?.amount)?.toFixed(2)}
                        name="amount"
                        disabled
                      />
                    </div>
                    <div className="col-span-1 flex items-center ">
                      <i
                        className="bi bi-trash text-[20px] text-[red] cursor-pointer"
                        onClick={() => {
                          let temp = data?.items;
                          temp?.splice(index, 1);
                          setData((prev: any) => ({ ...prev, items: temp }));
                          edit &&
                            setItemDeleted((prev: any) => [
                              ...prev,
                              item?.item_id,
                            ]);
                        }}
                      ></i>
                    </div>
                  </div>
                );
              })}

              <div className="grid bg-[#000]/[0.1] p-[5px] rounded-[4px] grid-cols-12 gap-[10px] mt-[15px]">
                <div className="col-span-5">
                  <Input
                    // label="Item Name"
                    placeholder="Enter Item Name"
                    value={items?.item_name}
                    name="item_name"
                    handleChange={(e: any) => {
                      setFormErrors((prev: any) => ({
                        ...prev,
                        item_name: "",
                      }));
                      setItems((prev: any) => ({
                        ...prev,
                        item_name: e.target.value,
                      }));
                    }}
                    errTxt={formErrors?.item_name}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    // label="Item Quantity"
                    type={"number"}
                    placeholder="Enter Item Quantity"
                    value={items?.item_quantity}
                    name="item_quantity"
                    handleChange={(e: any) => {
                      setFormErrors((prev: any) => ({
                        ...prev,
                        item_quantity: "",
                      }));
                      setItems((prev: any) => ({
                        ...prev,
                        item_quantity: e.target.value,
                      }));
                    }}
                    errTxt={formErrors?.item_quantity}
                  />
                </div>
                <div className="col-span-2">
                  <Input
                    // label="Item Price ( $ )"
                    type={"number"}
                    placeholder="Enter Item price"
                    value={Number(items?.item_price)?.toFixed(2)}
                    name="item_price"
                    handleChange={(e: any) => {
                      setFormErrors((prev: any) => ({
                        ...prev,
                        item_price: "",
                      }));
                      setItems((prev: any) => ({
                        ...prev,
                        item_price: e.target.value,
                      }));
                    }}
                    errTxt={formErrors?.item_price}
                  />
                </div>

                <div className="col-span-2">
                  <Input
                    // label="Amount"
                    type={"number"}
                    className={"bg-[#fff]/[0.8] cursor-not-allowed"}
                    placeholder="Enter Amount"
                    value={Number(
                      items?.item_quantity * items?.item_price || 0
                    )?.toFixed(2)}
                    name="amount"
                    disabled
                  />
                </div>
              </div>

              <p
                className="text-[var(--primary)] w-[fit-content] mt-[15px] cursor-pointer"
                onClick={() => {
                  addItem();
                }}
              >
                + Add more
              </p>
            </div>
            <hr className="pb-[20px]" />

            <div className="grid grid-cols-1 md:grid-cols-2 gap-[15px] mb-[20px]">
              <div className="col col-span-1"></div>
              <div className="col-span-1">
                <Input
                  label="Invoice Subtotal"
                  disabled
                  type={"number"}
                  horizontal={true}
                  className={"ml-auto"}
                  placeholder="Enter Invoice Subtotal"
                  value={data?.invoice_subtotal?.toFixed(2)}
                  name="invoice_subtotal"
                  handleChange={handleChange}
                />
              </div>
              <div className="col-span-1"></div>
              <div className="col-span-1">
                <Input
                  label="Discount"
                  step={0.01}
                  type={"number"}
                  horizontal={true}
                  className={"ml-auto"}
                  placeholder="Enter Discount"
                  value={data?.discount?.toFixed(2)}
                  name="discount"
                  handleChange={handleChange}
                />
              </div>
              <div className="col-span-1"></div>
              <div className="col-span-1">
                <Input
                  label="Sales Tax ( % )"
                  type={"number"}
                  placeholder="Enter Sales tax"
                  value={data?.sales_tax}
                  name="sales_tax"
                  horizontal={true}
                  className={"ml-auto"}
                  handleChange={(e: any) => {
                    setData((prev: any) => ({
                      ...prev,
                      sales_tax: parseInt(e.target.value),
                    }));
                    setFormErrors((prev: any) => ({
                      ...prev,
                      sales_tax: "",
                    }));
                  }}
                />
              </div>
              <div className="col-span-1"></div>
              <div className="col-span-1">
                <Input
                  label="Total"
                  type={"number"}
                  horizontal={true}
                  disabled
                  className={"ml-auto"}
                  placeholder="Enter Total"
                  value={data?.total?.toFixed(2)}
                  name="total"
                  handleChange={handleChange}
                />
              </div>
            </div>

            <div className="relative ">
              <div className="footer bg-[#e5e5e5] border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md  right-[0px] ">
                <div className="flex justify-between gap-[10px] p-[10px]">
                  <button
                    className="  text-black rounded-[8px] px-[20px] py-[10px]"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(INVOICE);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    className="bg-[var(--primary)] ml-auto text-[#fff] rounded-[8px] px-[20px] py-[10px]"
                    // onClick={(e) => {
                    //     setStep(step + 1)
                    // }}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddInvoice;
