import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import Input from "../../../components/Input/Input";
import { useNavigate, useOutletContext } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../../components/Loader/index";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ADD_EMPLOYEE, LOGIN, PAYROLL_SETUP, VERIFY_OTP } from "../../../navigation/Constant";
import { EMPLOYEE } from "../../../navigation/Constant";
// import Select from "../../../components/Select/Select";

import { Modal, Select, Upload } from "antd";
import { CommonApi } from "../../../apis/commonApi";
import ImageUpload from "../../../components/ImageUpload/ImageUpload";

const initialErrorState = {
  error: false,
  msg: "",
};

const initialData = {
  address: "",
  business_currency: "",
  business_type: "",
  city: "",
  company_name: "",
  country: "",
  fei_no: "",
  org_type: "",
  state: "",
  uia_no: "",
  zip_code: "",
  photo_url: "",
};

const AddCompany = ({ setCompany }: any) => {
  const [company_name, setCompanyName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [master, setMaster] = React.useState<any>({});

  const [city, setCity] = React.useState<any>([]);
  const [state, setState] = React.useState<any>([]);

  const [zipcode, setZipcode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [business_currency, setBusCurr] = React.useState("");
  const [type_of_org, setOrgType] = React.useState("");
  const [company_type, setCompType] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = useState(initialData);

  const navigate = useNavigate();

  const setSlectedCompany = useOutletContext<any>().setSlectedCompany;

  const handlecomp = async () => {
    if (
      !formData.company_name ||
      !formData.country ||
      !formData.business_currency ||
      !formData.business_type ||
      !formData.uia_no ||
      !formData.org_type ||
      !formData.fei_no
    ) {
      return toast.error("Please fill all the fields");
    }

    if (validateSubmit()) {
      return toast.error("Please fill all the details correctly");
    }

    const newFormData = new FormData();

    for (const key in formData) {
      newFormData.append(key, formData[key as keyof typeof formData]);
    }

    try {
      setLoading(true);
      const data = await DashboardAPI.postAddComp(newFormData);

      if (data.success) {
        console.log(setSlectedCompany);
        
        setSlectedCompany(data.data.values.company_id)
        localStorage.setItem("company_id", data.data.values.company_id);
        navigate(PAYROLL_SETUP);
        toast.success(data.message);
        setCompany((prev: any) => !prev)
        localStorage.setItem(
          "registeredCompany",
          JSON.stringify(data.data.values)
        );
      }
    } catch (error: any) {
      console.log("here");
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg, {
        position: "top-right",
        // autoClose: 5000,
        closeOnClick: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  const [formErr, setFormErr] = useState({
    address: initialErrorState,
    business_currency: initialErrorState,
    business_type: initialErrorState,
    city: initialErrorState,
    company_name: initialErrorState,
    country: initialErrorState,
    fei_no: initialErrorState,
    org_type: initialErrorState,
    state: initialErrorState,
    uia_no: initialErrorState,
    zip_code: initialErrorState,
    photo_url: initialErrorState,
  });

  const validate = (e: any) => {
    const { name, value } = e.target;

    if (name === "company_name") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          company_name: {
            error: true,
            msg: "Please enter company name",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          company_name: initialErrorState,
        }));
      }
    } else if (name === "address") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          address: {
            error: true,
            msg: "Please enter address",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, address: initialErrorState }));
      }
    } else if (name === "country") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          country: {
            error: true,
            msg: "Please enter country",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, country: initialErrorState }));
      }
    } else if (name === "state") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          state: {
            error: true,
            msg: "Please enter  state",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, state: initialErrorState }));
      }
    } else if (name === "city") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          city: {
            error: true,
            msg: "Please enter city",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, city: initialErrorState }));
      }
    } else if (name === "zip_code") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          zip_code: {
            error: true,
            msg: "Please enter zipcode",
          },
        }));
      } else {
        setFormErr((prev: any) => ({ ...prev, zip_code: initialErrorState }));
      }
    } else if (name === "business_currency") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          business_currency: {
            error: true,
            msg: "Please enter business currency",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          business_currency: initialErrorState,
        }));
      }
    } else if (name === " type_of_org") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          type_of_org: {
            error: true,
            msg: "Please enter type of organisation",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          type_of_org: initialErrorState,
        }));
      }
    } else if (name === "company_type") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          company_type: {
            error: true,
            msg: "Please enter type of business",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          company_type: initialErrorState,
        }));
      }
    } else if (name === "fei_no") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          fei_no: {
            error: true,
            msg: "Please enter Fei No",
          },
        }));
      } else if (value?.length < 9) {
        setFormErr((prev: any) => ({
          ...prev,
          fei_no: {
            error: true,
            msg: "Fei No must be 9 digits",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          fei_no: initialErrorState,
        }));
      }
    } else if (name === "uia_no") {
      if (!value) {
        setFormErr((prev: any) => ({
          ...prev,
          uia_no: {
            error: true,
            msg: "Please enter Uia no",
          },
        }));
      } else if (value?.length < 10) {
        setFormErr((prev: any) => ({
          ...prev,
          uia_no: {
            error: true,
            msg: "Uia no must be 10 digits",
          },
        }));
      } else {
        setFormErr((prev: any) => ({
          ...prev,
          uia_no: initialErrorState,
        }));
      }
    }
  };

  const validateSubmit = () => {
    if (
      formErr.company_name.error ||
      formErr.country.error ||
      formErr.business_currency.error ||
      formErr.business_type.error ||
      formErr.uia_no.error ||
      formErr.org_type.error ||
      formErr.fei_no.error
    ) {
      return true;
    }
  };

  const getCityList = async (state: any) => {
    try {
      setLoading(true);
      const dataum = await CommonApi.getCityList({ state: formData?.state });
      if (dataum.success) {
        setCity(
          dataum?.data?.city_list.map((item: any) => ({
            label: item,
            value: item,
          }))
        );
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const getStateList = async () => {
    try {
      setLoading(true);
      const dataum = await CommonApi.getStateList();
      if (dataum.success) {
        setState(
          dataum?.data?.state_list.map((item: any) => ({
            label: item,
            value: item,
          }))
        );
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const getDetails = async () => {
    try {
      setLoading(true);
      const dataum = await DashboardAPI.getDetails();
      if (dataum.success) {
        setMaster(dataum.data.form_details);
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (formData?.state) {
      getCityList(formData?.state);
    }
  }, [formData?.state]);

  return (
    <div className="container mx-auto pt-[15px] pb-[200px]">
      <ToastContainer />
      {loading && <Loader />}
      <h2 className="font-semibold text-[var(--secondary)]">
        Add New Business
      </h2>

      <div className="grid grid-cols-2 gap-[10px] mt-[30px] w-[100%]">
        <div className="col-span-2">
          <div className="grid grid-cols-3 gap-[20px]">
            <div className="col-span-2 flex flex-col gap-[10px]">
              <div className="col-span-1">

                <Input
                  required
                  type={"text"}
                  name={"company_name"}
                  label={"Company Name"}
                  placeholder={"Enter Company Name"}
                  handleChange={handleChange}
                  value={formData?.company_name}
                  onBlur={validate}
                />

              </div>
              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Type of Organization <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                  placeholder="Search to Select"
                  options={master?.organisation_type}
                  onChange={(e) => {
                    setFormData((prev: any) => ({ ...prev, org_type: e }));
                  }}
                />
              </div>

              <div className="col-span-1 flex flex-col">
                <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                  Type of Business <span className="text-[red]">*</span>
                </label>
                <Select
                  showSearch
                  className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                  placeholder="Search to Select"
                  options={master?.business_type}
                  onChange={(e) => {
                    setFormData((prev: any) => ({
                      ...prev,
                      business_type: e,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="col-span-1 flex flex-col gap-[10px]">
              <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                Logo
              </label>

              <ImageUpload
                setData={setFormData}
                data={formData}
                key={"photo"}
              />
            </div>
          </div>
        </div>

        <div className="col-span-2">
          <Input
            required
            type={"text"}
            name={"address"}
            label={"Street Address"}
            placeholder={"Enter Street Address"}
            value={formData?.address}
            handleChange={handleChange}
            onBlur={validate}
          />
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Country <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            options={[
              {
                label: "US",
                value: "US",
              },
            ]}
            onChange={(e) => {
              setFormData((prev: any) => ({ ...prev, country: e }));
            }}
          />
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            State <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            options={state}
            onChange={(e) => {
              setFormData((prev: any) => ({ ...prev, state: e }));
            }}
          />
        </div>
        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            City <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            options={city}
            onChange={(e) => {
              setFormData((prev: any) => ({ ...prev, city: e }));
            }}
          />
        </div>

        <div className="col-span-1">
          <Input
            required
            type={"number"}
            name={"zip_code"}
            label={"Zipcode"}
            placeholder={"Enter Zipcode"}
            value={formData?.zip_code}
            handleChange={handleChange}
            onBlur={validate}
          />
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Business Currency <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[45px] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            options={[
              {
                label: "USD",
                value: "US Doller",
              },
            ]}
            onChange={(e) => {
              setFormData((prev: any) => ({ ...prev, business_currency: e }));
            }}
          />
        </div>

        <div className="col-span-1">
          <Input
            required
            type={"number"}
            name={"fei_no"}
            label={"FEI Number"}
            placeholder={"Enter FEI Number (9 digit)"}
            value={formData?.fei_no}
            handleChange={(e: any) => {
              e.target.value?.length <= 9 && handleChange(e);
            }}
            onBlur={validate}
            errTxt={formErr?.fei_no?.msg}
          />
        </div>

        <div className="col-span-1">
          <Input
            type={"number"}
            name={"uia_no"}
            required
            label={"UIA Employer Number"}
            placeholder={"Enter UIA Employer Number(10 digit)"}
            value={formData?.uia_no}
            handleChange={(e: any) => {
              e.target.value?.length <= 10 && handleChange(e);
            }}
            onBlur={validate}
            errTxt={formErr?.uia_no?.msg}
          />
        </div>
      </div>

      <div className="relative ">
        <div className="footer bg-[#e5e5e5] border-[1px] max-width-[100%] md:w-[100%] w-[100%] fixed bottom-0 shadow-md  right-[0px] ">
          <div className="flex justify-between gap-[10px] p-[10px]">
            {/* <button className="  text-black rounded-[8px] px-[20px] py-[10px]" onClick={(e) => {
                                // setStep(step - 1)
                            }}>Back</button> */}

            <button
              className="bg-[var(--primary)] ml-auto text-[#fff] rounded-[8px] px-[20px] py-[10px]"
              // onClick={(e) => {
              //     setStep(step + 1)
              // }}
              onClick={() => {
                handlecomp();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCompany;
