import React, { useState } from 'react';
import { Select, DatePicker } from "antd";
import { DashboardAPI } from '../../../apis/dashboardApi';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import dayjs from 'dayjs'; // Import dayjs library
import moment from 'moment';

import { WAGES_TAX_EMPLIST } from '../../../navigation/Constant';
import { stat } from 'fs';
import Loader from '../../../components/Loader';


function WagesTax() {
    const [loading, setLoading] = useState<boolean>(false);
    const { selectedCompany } = useOutletContext<any>();
    const [data, setData] = useState<any>({ start_date: null, end_date: null });

    const navigate = useNavigate();

    const postWagesTaxEmpList = async () => {
        try {
            setLoading(true);
            const startDate = data?.start_date ? moment(data.start_date, "YYYY-MM-DD").format("DD-MM-YYYY") : null;
            const endDate = data?.end_date ? moment(data.end_date, "YYYY-MM-DD").format("DD-MM-YYYY") : null;
            if (startDate && endDate) {
                const res = await DashboardAPI?.postWagesTaxEmpList(selectedCompany, {
                    start_date: startDate,
                    end_date: endDate,
                });
                console.log(res);
                if (res.success) {
                    toast.success("Employee list generated successfully");
                    setData(res.data);
                    navigate(WAGES_TAX_EMPLIST, { state: { state: res.data, startDate, endDate } })
                }
            } else {
                toast.error("Start date or end date is not provided.");
            }
        } catch (err: any) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='w-[100%] max-h-[100vh]'>
            <ToastContainer />
            {loading && <Loader />}
            <div className="mt-[20px] items-cetner flex">
                <p className="font-semibold text-[18px] text-[var(--secondary)]">
                    Wage & Tax Report
                </p>
            </div>
            <div className='border rounded-[4px] p-[28px] my-[26px] shadow'>
                <div className='flex gap-[32px] justify-center items-center mb-4'>
                    <h3 className='font-semibold text-[16px] text-[var(--secondary)] opacity-[70%]'>Period</h3>
                    <Select
                        showSearch
                        style={{ width: '350px' }}
                        className="border-[1px] text-[16px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                        placeholder="Search to Select"
                        defaultValue={'1'}
                        options={[
                            { value: '1', label: 'Other (Please specify)' },
                            { value: '2', label: 'Current Quarter' },
                            { value: '3', label: 'Previous Quarter' },
                        ]}
                    />
                </div>
                <div className='flex gap-[32px] justify-center items-center mb-4'>
                    <h3 className='font-semibold text-[16px] text-[var(--secondary)] opacity-[70%] whitespace-nowrap'>Start Date</h3>
                    <DatePicker
                        style={{ width: '330px' }}
                        className='border-[1px] text-[16px] h-[100%] mt-[5px] rounded-[4px] p-[8px]'
                        value={data?.start_date ? dayjs(data.start_date) : null}
                        onChange={(date, dateString) => setData({ ...data, start_date: dateString })}
                    />
                </div>
                <div className='flex gap-[32px] justify-center items-center mb-4'>
                    <h3 className='font-semibold text-[16px] text-[var(--secondary)] opacity-[70%] whitespace-nowrap'>End Date</h3>
                    <DatePicker
                        style={{ width: '330px' }}
                        className='border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[8px]'
                        value={data?.end_date ? dayjs(data.end_date) : null}
                        onChange={(date, dateString) => setData({ ...data, end_date: dateString })}
                    />
                </div>
                <div className='flex gap-[32px] justify-center items-center'>
                    <h3 className='font-semibold text-[16px] text-[var(--secondary)] opacity-[70%]'>Display</h3>
                    <Select
                        showSearch
                        style={{ width: '350px' }}
                        className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                        placeholder="Search to Select"
                        defaultValue={'1'}
                        options={[
                            { value: '1', label: 'All taxes' },
                            { value: '2', label: 'Federal taxes only' },
                            { value: '3', label: 'State taxes only' },
                            { value: '4', label: 'Local taxes only' },
                        ]}
                    />
                </div>
            </div>
            <div className='flex justify-center'>
                <button className='bg-[#4784ca] text-white font-semibold text-[14px] rounded-[4px] px-[32px] py-[8px]'
                    onClick={postWagesTaxEmpList}
                >
                    Generate Report
                </button>
            </div>
        </div>
    )
}

export default WagesTax;
