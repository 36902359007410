import React from "react";
import Input from "../Input/Input";
import CustomSelect from "../Select/Select";
import { Select } from "antd";
interface workInfoProps {
  data?: any;
  setData?: any;
  masterData?: any;
  formErrors?: any;
  setFormErrors?: any;
}

const EmployeType = [
  { value: "per-hour", label: "Per Hour" },
  { value: "per-month", label: "Per Month" },
];

const AccessRight = [
    { value: "owner", label: "Owner" },
    { value: "hr", label: "HR" },
    { value: "accounts", label: "Accounts" },
    { value: "employee", label: "Employee" },
    ];

    const workLocation = [
        { value: "office", label: "Office" },
        { value: "remote", label: "Remote" },
        { value: "both", label: "Both" },
        ];

const WorkInfo = ({ data, setData, masterData,formErrors, setFormErrors}: workInfoProps) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: { error: false, msg: `` } });
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="col-span-1">
          <Input
            type="text"
            name={"emp_code"}
            label={"Employee Code"}
            required
            placeholder={"Enter Code"}
            handleChange={handleChange}
            value={data?.emp_code}
            errTxt={formErrors?.emp_code?.msg}
          />
        </div>
        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Access Right <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            options={masterData?.access_list?.filter((item: any) => item.value != 1)}
            value={data?.access_id}
            onChange={(e) => {
              setFormErrors({ ...formErrors, access_id: { error: false, msg: `` } });
              setData((prev: any) => ({ ...prev, access_id: e }));
            }}
          />
          {formErrors?.access_id?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600"> 
              {formErrors?.access_id?.msg}
            </label>
          )}
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Employment Type <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            value={data?.emp_type}
            options={masterData?.employment_type}
            onChange={(e) => {
               
                setFormErrors({ ...formErrors, emp_type: { error: false, msg: `` } });
              setData((prev: any) => ({ ...prev, emp_type: e }));
            }}
          />
          {formErrors?.emp_type?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.emp_type?.msg}
            </label>
          )}
        </div>

        
          {data?.emp_type ? (
            data?.emp_type === "Per Hours" ? (
              <div className="col-span-1">
              <Input
                type="text"
                name={"amount"}
                label={"Amount"}
                placeholder={"Enter Amount"}
                handleChange={handleChange}
                value={data?.amount}
                errTxt={formErrors?.amount?.msg}
              />
            </div>
            ) : (
              <>
              <div className="col-span-1">
              <Input
                type="number"
                name={"hrs_per_week"}
                label={"Work Hours Per Week"}
                required
                placeholder={"Enter Work Hours"}
                handleChange={handleChange}
                value={data?.hrs_per_week}
                errTxt={formErrors?.hrs_per_week?.msg}
                />
            </div>
            <div className="col-span-1">
                 <Input
                type="text"
                name={"amount"}
                label={"Amount"}
                required
                placeholder={"Enter Amount"}
                handleChange={handleChange}
                value={data?.amount}
                errTxt={formErrors?.amount?.msg}
              />
            </div>
                </>
              
            )
          ) : null}
       

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Work Location <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            value={data?.work_loc}
            options={masterData?.work_location}
            onChange={(e) => {
              setFormErrors({ ...formErrors, work_loc: { error: false, msg: `` } });
              setData((prev: any) => ({ ...prev, work_loc: e }));
            }}
          />
          {formErrors?.work_loc?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.work_loc?.msg}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkInfo;
