import { Modal, Upload } from 'antd';
import React, { useState } from 'react'

import { PlusOutlined } from '@ant-design/icons';

import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { RcFile } from 'antd/lib/upload';
import { toast } from 'react-toastify';
interface Props {
    data: any;
    setData: any;
    key: string;
    }


    const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });


const ImageUpload = ({data, setData, key}: Props) => {

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState<UploadFile[]>([]);
  
    const handleCancel = () => setPreviewOpen(false);
  
    const handlePreview = async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
  
      setPreviewImage(file.url || (file.preview as string));
      setPreviewOpen(true);
      setPreviewTitle(
        file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
      );
    };
  
    const handleChangeImage: UploadProps["onChange"] = ({
      fileList: newFileList,
    }) => {
  
      if(newFileList[0]?.originFileObj){
        if(newFileList[0]?.originFileObj?.size > 200000){
            console.log(newFileList[0]?.originFileObj?.size)
          toast.error('File size should be less than 200KB')
          return
        }
      }
      
      setFileList(newFileList);
  
      setData({ ...data, photo: newFileList[0]?.originFileObj });
    };
  
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

  return (
    <>
                  <Upload
                    listType="picture-card"
                    className="w-[fit-content]"
                    fileList={fileList}
                    onPreview={handlePreview}
                    beforeUpload={()=> {
                      return false; }}
                    onChange={handleChangeImage}
                    maxCount={1}
                    accept="image/png, image/jpeg, image/jpg"
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                  <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={handleCancel}
                  >
                    <img
                      alt="example"
                      className="w-[160px] h-[160px] object-cover"
                      style={{ width: "160px", height: "160px" }}
                      src={previewImage}
                    />
                  </Modal>
                </>
  )
}

export default ImageUpload