import React, { useEffect } from 'react'
import Input from '../../components/Input/Input'
import { toast, ToastContainer } from 'react-toastify'
import Loader from '../../components/Loader/index'
import { useNavigate } from 'react-router-dom'
import { CHANGE_PASSWORD, EMPLOYEE, PAYROLL_SETUP, REGISTER } from '../../navigation/Constant'
import { EMAIL_REGEX } from '../../constants/regex'
import { AuthAPI } from '../../apis/authAPI'

import { FORGOT } from '../../navigation/Constant'
// import Employee from '../Dashboard/Employee/Employee'
const logo = require('../../assets/images/payweek.png')

const initialErrorState = {
    error: false,
    msg: ''
}

const Login = () => {


    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const [formErr, setFormErr] = React.useState({
        email: initialErrorState,
        password: initialErrorState
    })

    const navigate = useNavigate()



    const handleLogin = async () => {

        if (!email || !password) {
            return toast.error('Please fill all the details', {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        }

        try {
            setLoading(true)
            const data = await AuthAPI.postLogin({ username: email, password })

            if (data.success) {

                if (data.data?.is_emp_created === false) {
                    navigate(CHANGE_PASSWORD + "?email=" + email)
                    return
                }
                toast.success(data.message || 'User logged in successfully');
                localStorage.setItem('token', data.data.token.access)
                localStorage.setItem('refreshToken', data.data.token.access)
                localStorage.setItem('user', JSON.stringify(data.data.emp_details))
                navigate(PAYROLL_SETUP)
            }

        } catch (error: any) {
            console.log('here')
            const errMsg = error?.response?.data?.message || error?.message || "something went wrong"
            console.log(errMsg)
            return toast.error(errMsg, {
                position: "top-right",
                autoClose: 5000,
                closeOnClick: true,
            });
        } finally {
            setLoading(false)
        }
    }


    const handleChange = (e: any) => {
        const { name, value } = e.target
        if (name === 'email') {
            setEmail(value)
        } else if (name === 'password') {
            setPassword(value)
        }
    }


    const validate = (e: any) => {
        const { name, value } = e.target

        if (name === 'email') {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev, email: {
                        error: true,
                        msg: 'Please enter email'
                    }
                }))
            } else if (!EMAIL_REGEX.test(email)) {
                setFormErr((prev: any) => ({
                    ...prev, email: {
                        error: true,
                        msg: 'Please enter valid email'
                    }
                }))
            } else {
                setFormErr((prev: any) => ({ ...prev, email: initialErrorState }))
            }

        } else if (name === 'password') {
            if (!value) {
                setFormErr((prev: any) => ({
                    ...prev, password: {
                        error: true,
                        msg: 'Please enter password'
                    }
                }))
            } else {
                setFormErr((prev: any) => ({ ...prev, password: initialErrorState }))
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    });

    const handleKeyPress = (event: any) => {
        if (event.keyCode === 13) {
            handleLogin();
        }
    }

    useEffect(() => {
        localStorage.clear()
    },[])


    return (
        <div className='bg-[#F4F4F496]'>

            <div className='min-h-[100vh] container py-[30px]  mx-auto flex flex-col items-center'>

                <ToastContainer />

                {
                    loading && <Loader />
                }
                <div className='flex w-[100%] items-start sm:items-center justify-center sm:justify-start sm:mb-[60px] mb-[25px]'>
                    <img src={logo} alt="" className='sm:w-[180px] w-[140px]' />
                </div>
                <div className='flex flex-col gap-[10px] card w-[80%] md:w-[40%] shadow-[0px_0px_8px_rgba(0,0,0,0.1)] bg-[#ffffff] py-[30px] px-[20px] rounded-[8px]  mx-auto'>
                    <h1 className='text-center text-[#5ABC4BE0] sm:text-3xl text-2xl font-bold'>LOGIN</h1>
                    <h3 className='text-center text-2xl font-bold'>
                        SIGN IN TO YOUR ACCOUNT
                    </h3>
                    <div className='flex flex-col gap-[10px] w-[70%] mt-[30px] mx-auto'>
                        <Input label="Email" name="email" type="email" placeholder="Enter your email" handleChange={handleChange} onBlur={validate} className="bg-[#F4F4F4] rounded-[12px]" value={email} errTxt={formErr?.email?.msg} />
                        <Input label="Password" name="password" type="password" placeholder="Enter your password" className="bg-[#F4F4F4] rounded-[12px]" handleChange={handleChange} value={password} onBlur={validate} errTxt={formErr?.password?.msg} />

                        <p className='text-right hover:underline cursor-pointer' onClick={() => { navigate(FORGOT) }} >Forgot Password?</p>
                    </div><button className="bg-[#447EC1] text-[#fff] text-[14px] px-[45px] mx-auto font-semibold rounded-[12px]  py-[8px] mt-[15px]"
                        onClick={() => { handleLogin() }}
                    >Sign In</button>

                    <p className='text-[#000] text-center text-[#000]/[0.7] mt-[15px] font-medium'>Don't have a Payweek account yet? <span className='text-right text-[#5ABC4BE0] hover:underline cursor-pointer' onClick={() => {
                        navigate(REGISTER)
                    }}>Sign up</span>.</p>
                </div>

            </div>
        </div>
    )
}

export default Login