import React from "react";
import Input from "../Input/Input";
import CustomSelect from "../Select/Select";
import { Select } from "antd";
import { parse } from "path/win32";
interface taxInfoProps {
  data?: any;
  setData?: any;
  masterData?: any;
  formErrors?: any;
  setFormErrors?: any;
}

const EmployeType = [
  { value: "per-hour", label: "Per Hour" },
  { value: "per-month", label: "Per Month" },
];

const AccessRight = [
  { value: "owner", label: "Owner" },
  { value: "hr", label: "HR" },
  { value: "accounts", label: "Accounts" },
  { value: "employee", label: "Employee" },
];

const workLocation = [
  { value: "office", label: "Office" },
  { value: "remote", label: "Remote" },
  { value: "both", label: "Both" },
];

const filingStatusOption = [
  { value: "Single or Married", label: "Single or Married" },
  {
    value: "Married filing jointly or Qualifying surviving Spouse",
    label: "Married filing jointly or Qualifying surviving Spouse",
  },
  {
    value: "Head of household",
    label: "Head of household",
  },
  {
    label: "Nonresident Alien",
    value: "Nonresident Alien",
  },
];

const TaxInfo = ({
  data,
  setData,
  masterData,
  formErrors,
  setFormErrors,
}: taxInfoProps) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormErrors({ ...formErrors, [name]: { error: false, msg: `` } });
    setData({ ...data, [name]: value });
  };
  return (
    <div>
      <div className="grid grid-cols-2 gap-[10px]">
        <div className="col-span-1 relative">
          <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">$</p>

          <Input
            type="number"
            name={"deduct"}
            label={"Deductions"}
            required
            className={"pl-[20px]"}
            placeholder={"Enter Deductions"}
            handleChange={handleChange}
            value={parseFloat(data?.deduct)?.toFixed(4)}
            errTxt={formErrors?.deduct?.msg}
          />
        </div>
        <div className="col-span-1 relative">
          {/* <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">$</p> */}

          <Input
            type="number"
            name={"dependent_amt"}
            // className={"pl-[20px]"}
            label={"No. of Qualifying Children under age 17"}
            // required
            placeholder={"Enter No. of Qualifying Children under age 17"}
            handleChange={handleChange}
            value={data?.dependent_amt}
            errTxt={formErrors?.dependent_amt?.msg}
          />
        </div>
        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Filing Status <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Search to Select"
            options={filingStatusOption}
            value={data?.filing_status}
            onChange={(e) => {
              formErrors &&
                setFormErrors({
                  ...formErrors,
                  filing_status: { error: false, msg: `` },
                });
              setData((prev: any) => ({ ...prev, filing_status: e }));
            }}
          />
          {formErrors?.filing_status?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.filing_status?.msg}
            </label>
          )}
        </div>

        <div className="col-span-1 relative">
          {/* <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">$</p> */}
          <Input
            type="number"
            name={"nra_amt"}
            // className={"pl-[20px]"}
            label={"Enter the no. of other dependents"}
            // required
            placeholder={"Enter the no. of other dependents"}
            handleChange={handleChange}
            value={data?.nra_amt}
            errTxt={formErrors?.nra_amt?.msg}
          />
        </div>

        <div className="col-span-1 relative">
          <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">$</p>

          <Input
            type="number"
            name={"other_income"}
            label={"Other Income"}
            className={"pl-[20px]"}
            placeholder={"Enter Other Income"}
            handleChange={handleChange}
            value={parseFloat(data?.other_income)?.toFixed(4)}
            errTxt={formErrors?.other_income?.msg}
          />
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Multiple Jobs <span className="text-[red]">*</span>
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Select"
            value={data?.multiple_jobs}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            onChange={(e) => {
              setFormErrors({
                ...formErrors,
                multiple_jobs: { error: false, msg: `` },
              });
              setData((prev: any) => ({ ...prev, multiple_jobs: e }));
            }}
          />
          {formErrors?.multiple_jobs?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.multiple_jobs?.msg}
            </label>
          )}
        </div>

        <div className="col-span-2 py-[30px]">
          <hr className="pb-[10px]" />
          <h2 className="text-xl font-medium">Tax exemptions</h2>
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Federal income tax
          </label>
          <Select
            showSearch
            className="border-[1px] h-[40px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Select"
            value={data?.federal_inc_tax}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            onChange={(e) => {
              setFormErrors({
                ...formErrors,
                federal_inc_tax: { error: false, msg: `` },
              });
              setData((prev: any) => ({ ...prev, federal_inc_tax: e }));
            }}
          />
          <label className="text-xs  mt-[5px] text-[#000]/[0.5]">
            Select "Yes" if employee wrote "Exempt" under Step 4(c).
          </label>
          {formErrors?.federal_inc_tax?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.federal_inc_tax?.msg}
            </label>
          )}
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Social Security & Medicare
          </label>
          <Select
            showSearch
            className="border-[1px] h-[40px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Select"
            value={data?.soc_security}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            onChange={(e) => {
              setFormErrors({
                ...formErrors,
                soc_security: { error: false, msg: `` },
              });
              setData((prev: any) => ({ ...prev, soc_security: e }));
            }}
          />
          <label className="text-xs mt-[5px] text-[#000]/[0.5]">
            Select "Yes" if employee wrote "Exempt" under Section 15 of IRS Publication 15.
          </label>
          {formErrors?.soc_security?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.soc_security?.msg}
            </label>
          )}
        </div>

        <div className="col-span-2 py-[30px]">
          <hr className="pb-[10px]" />

          <h2 className="text-xl font-medium">State tax details</h2>
        </div>

        <div className="col-span-1 flex flex-col">
          <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
            Michigan State Tax
          </label>
          <Select
            showSearch
            className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
            placeholder="Select"
            value={data?.michigan_state_tax}
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            onChange={(e) => {
              setFormErrors({
                ...formErrors,
                michigan_state_tax: { error: false, msg: `` },
              });
              setData((prev: any) => ({ ...prev, michigan_state_tax: e }));
            }}
          />
          {formErrors?.michigan_state_tax?.msg && (
            <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
              {formErrors?.michigan_state_tax?.msg}
            </label>
          )}
        </div>

        <div className="col-span-1 relative">
          <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">$</p>

          <Input
            type="number"
            className={"pl-[20px]"}
            name={"add_michigan_tax"}
            label={"Additional Mechigan Income Tax With Holdings"}
            placeholder={"Enter Additional Mechigan Income Tax With Holdings"}
            handleChange={handleChange}
            value={parseFloat(data?.add_michigan_tax).toFixed(4)}
            errTxt={formErrors?.add_michigan_tax?.msg}
          />
        </div>

        <div className="col-span-1 relative">
          <div className="relative">

          <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">$</p>

          <Input
            type="number"
            className={"pl-[20px]"}
            name={"total_allowance"}
            label={"Total Dependent"}
            placeholder={"Enter Total Dependent"}
            handleChange={handleChange}
            value={parseFloat(data?.total_allowance).toFixed(4)}
            errTxt={formErrors?.total_allowance?.msg}
            />
            </div>
            <label className="text-xs mt-[5px] text-[#000]/[0.5]">
            Required in order to ensure accurate tax calculations
          </label>
          
        </div>
      </div>
    </div>
  );
};

export default TaxInfo;
