import React, { useEffect, useState } from "react";
import { Menu, MenuProps } from "antd";
import { Button, Dropdown, message, Space, Tooltip } from "antd";
import {
  DownOutlined,
  UserOutlined,
  PlusOutlined,
  DollarOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  ADD_COMPANY,
  CUSTOMER,
  EMPLOYEE,
  INVOICE,
  PAYROLL,
  RUN_PAYROLL,
  TIME_SHEET,
  REPORTS,
  PAYROLL_SETUP,
} from "../../navigation/Constant";
import { useNavigate } from "react-router-dom";
import { DashboardAPI } from "../../apis/dashboardApi";
import { toast } from "react-toastify";

import {
  DesktopOutlined,
  FileOutlined,
  PieChartOutlined,
  TeamOutlined,
} from "@ant-design/icons";

type MenuItem = Required<MenuProps>["items"][number];

const keyRouteMap: Record<string, string> = {
  "0": "/dashboard/home",
  "1": EMPLOYEE,
  "2": INVOICE,
  "3": CUSTOMER,
  "4": TIME_SHEET,
  "5": RUN_PAYROLL,
  "6": REPORTS,
  "7": PAYROLL_SETUP
};

const SidebarOptions = [
  {
    name: "Dashboard",
    icon: "/assets/dashboard/home.svg",
    route: "/dashboard/home",
  },
  {
    name: "Employees",
    icon: "/assets/dashboard/employees.svg",
    route: EMPLOYEE,
  },
  // {
  //   name: "Payroll",
  //   icon: "/assets/dashboard/employees.svg",
  //   route: PAYROLL,
  // },
  {
    name: "Invoices",
    icon: "/assets/dashboard/employees.svg",
    route: INVOICE,
  },
  {
    name: "Customers",
    icon: "/assets/dashboard/employees.svg",
    route: CUSTOMER,
  },
  {
    name: "Run Payroll",
    icon: "/assets/dashboard/employees.svg",
    route: RUN_PAYROLL,
  },
  {
    name: "Reports",
    icon: "/assets/dashboard/employees.svg",
    route: REPORTS,
  },
];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const Sidebar = (props: {
  toggle?: any;
  setToggle?: any;
  company?: any;
  selectedCompany?: any;
  setSlectedCompany?: any;
  isEmployeeCreated: boolean;
  isPayscheduleCreated: boolean;
  isPriorPayrollCreated: boolean;
  isNewBusiness: boolean;
}) => {
  // const router = useRouter()

  // const [company, setCompany] = useState<any>([]);

  const { selectedCompany, setSlectedCompany, company, isEmployeeCreated, isPayscheduleCreated, isPriorPayrollCreated, isNewBusiness  } = props;

  const handleClick = (e: any, route: string) => {
    e.preventDefault();
  };

  const navigate = useNavigate();

  const [selected, setSelected] = React.useState("Employees");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCompanyCreate, setIsCompanyCreate] = useState(false);
  const [selectedPage, setSelectedPage] = useState("");
  const items: MenuProps["items"] = [
    ...company.map((item: any) => {
      return {
        label: (
          <div
            className={
              "flex justify-between" +
              (item?.company_id == selectedCompany
                ? " bg-[#00000010] rounded-[4px] p-[7px] "
                : "")
            }
          >
            <p className="text-[#000] font-medium capitalize hover:underline">
              {item?.company_name}
            </p>
            <i
              className="bi bi-pencil"
              onClick={() =>
                navigate("/dashboard/company/edit" + "/" + item?.company_id)
              }
            ></i>
          </div>
        ),
        key: item?.company_id,
        icon: (
          <img
            src={
              item?.photo_url ||
              "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=1480&t=st=1682599573~exp=1682600173~hmac=70167dd96663e16bc7d1b9ea915c69f452455a2db0362cb2b6eff25ae440acbd"
            }
            className="h-[20px] w-[20px] border-[1px] rounded-[4px] contain"
            alt=""
          />
        ),
      };
    }),
    {
      label: (
        <p className="text-[var(--secondary)] font-semibold">
          Add New Business
        </p>
      ),
      key: "add",
      icon: <PlusOutlined />,
      // danger: true,
    },
  ];

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e?.key == "add") {
      navigate(ADD_COMPANY);
    } else {
      setSlectedCompany(e?.key);
      localStorage.setItem("company_id", e?.key);
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };


  useEffect(() => {
    console.log(
      Object.keys(keyRouteMap).find(
        (item) =>
          keyRouteMap[item] ==
          window.location.pathname?.split("/")?.splice(0, 3)?.join("/")
      ) || ""
    );
    setSelected(
      Object.keys(keyRouteMap).find(
        (item) =>
          keyRouteMap[item] ==
          window.location.pathname?.split("/")?.splice(0, 3)?.join("/")
      ) || ""
    );
  }, [window.location.pathname]);


  const itemsNew: MenuItem[] = [
    getItem(
      <p
        onClick={() => {
          navigate(keyRouteMap["0"]);
        }}
      >
        Dashboard
      </p>,
      "0",
      <PieChartOutlined
        style={{
          fontSize: "20px",
          color: selected == "0" ? "var(--primary)" : "var(--secondary)",
        }}
      />
    ),
    getItem(
      "Sales and Payment",
      "sub1",
      <UserOutlined
        style={{
          fontSize: "20px",
          color: selected == "2" ? "var(--primary)" : "var(--secondary)",
        }}
      />,
      [
        getItem(
          <p
            onClick={() => {
              navigate(keyRouteMap["2"]);
            }}
          >
            Invoices
          </p>,
          "2"
        ),
        getItem(
          <p
            onClick={() => {
              navigate(keyRouteMap["3"]);
            }}
          >
            Customers
          </p>,
          "3"
        ),
      ]
    ),

    getItem(
      "Payroll",
      "sub2",
      <DollarOutlined
        style={{
          fontSize: "20px",
          color: selected == "5" ? "var(--primary)" : "var(--secondary)",
        }}
      />,
      [
        getItem(
          <p
            onClick={() => {
              navigate(keyRouteMap["7"]);
            }}
          >
            Initial Setup
          </p>,
          "7"
        ),
        getItem(
          <p
            onClick={() => {
              navigate(keyRouteMap["1"]);
            }}
          >
            Employees
          </p>,
          "2"
        ),
        isEmployeeCreated && isPayscheduleCreated && (isPriorPayrollCreated || isNewBusiness) ?
        
          getItem(
            <p
              onClick={() => {
                navigate(keyRouteMap["4"]);
              }}
            >
              Timesheet
            </p>,
            "4"
          ) :
          getItem(
            <p
              style={{ cursor: "not-allowed", color: "#ccc" }}
            >
              Timesheet
            </p>,
            "4"
          ),
          isEmployeeCreated && isPayscheduleCreated && (isPriorPayrollCreated || isNewBusiness) ?
          getItem(
            <p
              onClick={() => {
                navigate(keyRouteMap["5"]);
              }}
            >
              Run Payroll
            </p>,
            "5"
          ) :
          getItem(
            <p
              style={{ cursor: "not-allowed", color: "#ccc" }}
            >
              Run Payroll
            </p>,
            "5"
          ),
      ].filter(Boolean)
    ),

    getItem(
      <p
        onClick={() => {
          navigate(keyRouteMap["6"]);
        }}
      >
        Reports
      </p>,
      "6",
      <FileTextOutlined
        style={{
          fontSize: "20px",
          color: selected == "6" ? "var(--primary)" : "var(--secondary)",
        }}
      />
    ),
  ];

  return (
    <div className="sm:p-[20px] p-[10px] h-[100%] border-r-[1px] border-[#000]/[0.2] relative shadow-md">
      <button className="md:hidden" onClick={props.toggle}>
        <i className="bi bi-list absolute hidden right-[10px] top-[20px] text-3xl font-bold"></i>
      </button>
      <Dropdown menu={menuProps}>
        <Button className="rounded-[4px] border-[0px] w-[100%] p-[7px] h-auto text-left">
          <Space>
            <p className=" w-[100%] text-[#00000080] font-[600] text-[16px] ">
              {company?.find((item: any) => item.company_id == selectedCompany)
                ?.company_name || "Select Company"}
            </p>
            <DownOutlined className="text-[var(--secondary)] font-semibold" />
          </Space>
        </Button>
      </Dropdown>
      <div className="sm:mt-[20px] mt-[6px] border-[0] min-h-[100%]">
        <Menu
          defaultSelectedKeys={[selected]}
          // selectedKeys={[selected]}
          mode="inline"
          items={itemsNew}
          className="custom-menu"
        />
      </div>
    </div>
  );
};

export default Sidebar;
