import React, { useEffect, useState } from "react";
import PersonalInfo from "../../../components/Employee/PersonalInfo";
import BankInfo from "../../../components/Employee/BankInfo";
import WorkInfo from "../../../components/Employee/WorkInfo";
import DashboardLayout from "../../../components/Dashboard/DashboardLayout";
import { EMPLOYEE } from "../../../navigation/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { DashboardAPI } from "../../../apis/dashboardApi";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../components/Loader";
import TaxInfo from "../../../components/Employee/TaxInfo";
import Input from "../../../components/Input/Input";
import { Select, Table } from "antd";

const personalInfoInitial = {
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  extension: "+1",
  mobile_no: "",
  address: "",
  city: "",
  state: "",
  zip_code: "",
  ssn: "",
  hire_date: "",
  driver_license: "",
  marital_status: "",
};

const bankInfoInitial = {
  account_type: "",
  routing_no: "",
  bank_name: "",
  account_no: "",
  confirm_account_no: "",
};

const workInfoInitial = {
  emp_code: "",
  access_id: "",
  emp_type: "",
  hrs_per_week: 0,
  amount: "",
  work_loc: "",
};

const EditEmployee = ({ masterData }: any) => {
  const navigate = useNavigate();
  const params = useParams();

  const [step, setStep] = useState<number>(1);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<any>([]);
  const [formErrors, setFormErrors] = React.useState<any>({});
  const [master, setMaster] = React.useState<any>({});
  const [data, setData] = React.useState<any>({});

  const [benefitsData, setBenefitsData] = React.useState<any>([]);
  const [compensationData, setCompensationData] = React.useState<any>([]);

  const [benefitsObj, setBenefitsObj] = React.useState<any>({
    emp_id: params?.id,
    amount: 0.0,
    add_deduct: "",
    add_benefits: "",
  });
  const [compensationObj, setCompensationObj] = React.useState<any>({
    emp_id: params?.id,
    amount: 0.0,
  });

  const [addCompensation, setAddCompensation] = useState<boolean>(false);
  const [benefitsState, setBenefitsState] = useState<string>("none");

  const getEmployeeDetails = async () => {
    try {
      setLoading(true);
      const data = await DashboardAPI.getEmployeeById(params?.id);

      setData(data?.data?.values);
      setFormData(data?.data?.values);
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const updateEmployee = async () => {
    const obj = { ...data };

    for (let key in formData) {
      if (formData[key] == obj[key]) {
        delete obj[key];
      }
    }

    obj.email = formData?.email;
    obj.first_name = formData?.first_name;
    obj.last_name = formData?.last_name;
    // obj.company_id = formData?.company_id

    const newFormData = new FormData();

    const infoKeys = Object.keys(obj);

    for (let key of infoKeys) {
      if (key === "company_id") {
      } else {
        newFormData.append(
          key,
          obj[
            (key as keyof typeof personalInfoInitial) ||
              (key as keyof typeof bankInfoInitial) ||
              (key as keyof typeof workInfoInitial)
          ]
        );
      }
    }

    console.log("newFormData", newFormData);
    const newCompId = data?.company_id;

    newFormData.append("company_id", JSON.stringify(newCompId));

    try {
      setLoading(true);
      const dataum = await DashboardAPI.updateEmployeeById(
        params?.id,
        newFormData
      );
      // setData(data?.data?.values)

      if (dataum?.success) {
        toast.success("Employee updated successfully");
        // navigate(EMPLOYEE)
      }
    } catch (err: any) {
      console.log(err);
      const errMsg =
        err?.response?.data?.message?.toString() ||
        err?.message ||
        "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (params?.id) {
      getEmployeeDetails();
      getBenefits();
      getCompensation();
    }
  }, [params?.id]);

  const getBenefits = async () => {
    try {
      setLoading(true);
      const data = await DashboardAPI.getEmployeeBenefits(params?.id);

      if (data.success) {
        console.log("benefits data", data);
        setBenefitsData(data?.data?.values);
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const addBenefits = async () => {
    const { add_benefits, add_deduct, pay_stub_label, amount, occurs } =
      benefitsObj;
    const obj = { ...benefitsObj };
    if (benefitsState === "add") {
      if (!add_benefits || !amount || !occurs || !pay_stub_label) {
        toast.error("Please fill in all details");
        return;
      }
    } else if (benefitsState === "deduct") {
      if (!add_deduct || !amount || !occurs || !pay_stub_label) {
        toast.error("Please fill in all details");
        return;
      }
    }

    try {
      const data = await DashboardAPI.postEmployeeBenefits(params?.id, obj);
      if (data?.success) {
        if (benefitsState === "add") {
          toast.success("Benefits added successfully");
        } else if (benefitsState === "deduct") {
          toast.success("Benefits deducted successfully");
        }
        getBenefits();
        setBenefitsObj({
          emp_id: params?.id,
          amount: 0.0,
          add_deduct: "",
          add_benefits: "",
        });
        setBenefitsState("none");
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const updateBenefits = async () => {
    const { add_benefits, pay_stub_label, amount, occurs } = benefitsObj;

    if (!add_benefits || !pay_stub_label || !amount || !occurs) {
      toast.error("Please fill in all details");
      return;
    }
    const obj = { ...benefitsObj };

    try {
      const data = await DashboardAPI.putEmployeeBenefits(obj);
      if (data?.success) {
        toast.success("Benefits updated successfully");
        getBenefits();
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const getCompensation = async () => {
    try {
      setLoading(true);
      const data = await DashboardAPI.getEmployeeCompensation(params?.id);
      if (data.success) {
        setCompensationData(data?.data?.values);
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const createCompensation = async () => {
    const { amount, emp_type, effective_date } = compensationObj;

    if (!amount || !emp_type || !effective_date) {
      toast.error("Please fill in all details");
      return;
    }
    const obj = {
      ...compensationObj,
      effective_date: new Date(compensationObj?.effective_date)?.toISOString(),
    };

    try {
      const data = await DashboardAPI.postEmployeeCompensation(params?.id, obj);
      if (data?.success) {
        toast.success("Compensation added successfully");
        setAddCompensation(false);
        setCompensationObj({
          emp_id: params?.id,
          amount: 0.0,
        });
        getCompensation();
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  const updateCompensation = async () => {
    const { amount, emp_type, effective_date } = compensationObj;

    if (!amount || !emp_type || !effective_date) {
      toast.error("Please fill in all details");
      return;
    }
    const obj = { ...compensationObj };

    try {
      const data = await DashboardAPI.putEmployeeCompensation(obj);
      if (data?.success) {
        toast.success("Compensation updated successfully");
        getCompensation();
      }
    } catch (err: any) {
      console.log(err);
      const errMsg = err?.response?.data?.message || "Something went wrong";
      toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };

  /*
  const handleCreateAndUpdateCompensation = async () => {
    if (compensationObj?.amount && compensationObj?.emp_type && compensationObj?.effective_date) {
      try {
        await updateCompensation();
      } catch (error) {
        console.log('An error occurred:', error);
      }
    } else {
      try {
        await createCompensation();
        await updateCompensation();
      } catch (error) {
        console.log('An error occurred:', error);
      }
    }
  };
 
  */

  const getDetails = async () => {
    try {
      setLoading(true);
      const dataum = await DashboardAPI.getDetails();
      if (dataum.success) {
        setMaster(dataum.data.form_details);
      }
    } catch (error: any) {
      const errMsg =
        error?.response?.data?.message ||
        error?.message ||
        "something went wrong";
      console.log(errMsg);
      return toast.error(errMsg);
    } finally {
      setLoading(false);
    }
  };
 
  useEffect(() => {
    getDetails();
  }, []);

  const [taxData, setTaxData] = useState<any>({
    regular_pay: "",
    overtime_pay: "",
    doubletime_pay: "",
    vacation_pay: "",
    sictime_pay: "",
    taxable_pay: "",
    fedral_income_tax: "",
    medicare: "",
    state_tax: "",
    city_tax: "",
    social_security: "",
    non_taxable_pay: "",
  });

  const handleChangeTaxAmount = (e: any) => {
    const { name, value } = e.target;
    setTaxData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="container  mx-auto">
      <ToastContainer />
      {loading && <Loader />}

      <h2 className="font-semibold text-[var(--secondary)] text-[18px]">
        {" "}
        <span
          className="hover:underline cursor-pointer"
          onClick={() => navigate(EMPLOYEE)}
        >
          Employee
        </span>{" "}
        / Edit
      </h2>

      <div className="steps mt-[20px] hidden md:flex flex-row justify-start gap-[20px] items-center">
        <div
          className={
            " flex items-center gap-[10px] cursor-pointer justify-center px-[10px] py-[5px] "
          }
          onClick={() => setStep(1)}
        >
          <p
            className={
              "font-semibold m-0" +
              (step == 1
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Basic Info
          </p>
        </div>
        <div
          className={
            "  rounded-[15px] flex cursor-pointer items-center gap-[10px] justify-center px-[10px] py-[5px] "
          }
          onClick={() => setStep(2)}
        >
          <p
            className={
              "font-semibold m-0" +
              (step == 2
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Compensation
          </p>
        </div>
        <div
          className={
            "  rounded-[15px] flex items-center cursor-pointer gap-[10px] justify-center px-[10px] py-[5px] "
          }
          onClick={() => setStep(3)}
        >
          <p
            className={
              "font-semibold m-0" +
              (step == 3
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Benefits & Deduction
          </p>
        </div>
        <div
          className={
            "  rounded-[15px] flex items-center cursor-pointer gap-[10px] justify-center px-[10px] py-[5px] "
          }
          onClick={() => setStep(4)}
        >
          <p
            className={
              "font-semibold m-0" +
              (step == 4
                ? " text-[#000] border-b-[2px] border-[var(--primary)]"
                : " text-[#7C888D]/[0.5]")
            }
          >
            Tax Amount
          </p>
        </div>
      </div>

      {step == 1 && (
        <>
          <div className="mt-[20px]">
            <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
              <div className="content_box">
                <div className="header p-[10px] bg-[#E6F4FF]">
                  <p className="font-semibold m-0 text-[#000]/[0.6]">
                    Personal Details
                  </p>
                </div>
                <div className="body p-[10px]">
                  <PersonalInfo
                    data={data}
                    setData={setData}
                    edit={true}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    masterData={masterData}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[20px]">
            <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
              <div className="content_box">
                <div className="header p-[10px] bg-[#E6F4FF] border-[1px] ">
                  <p className="font-semibold m-0  text-[#000]/[0.6]">
                    Bank Details
                  </p>
                </div>
                <div className="body p-[10px]">
                  <BankInfo
                    data={data}
                    setData={setData}
                    edit={true}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    masterData={masterData}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[20px]">
            <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
              <div className="content_box">
                <div className="header p-[10px] bg-[#E6F4FF] border-[1px] ">
                  <p className="font-semibold m-0  text-[#000]/[0.6]">
                    Work Details
                  </p>
                </div>
                <div className="body p-[10px]">
                  <WorkInfo
                    data={data}
                    setData={setData}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    masterData={masterData}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[20px]">
            <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
              <div className="content_box">
                <div className="header p-[10px] bg-[#E6F4FF] border-[1px] ">
                  <p className="font-semibold m-0  text-[#000]/[0.6]">
                    Tax Details
                  </p>
                </div>
                <div className="body p-[10px]">
                  <TaxInfo
                    data={data}
                    setData={setData}
                    formErrors={formErrors}
                    setFormErrors={setFormErrors}
                    masterData={masterData}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="pb-[200px]"></div>

          <div className="relative ">
            <div className="footer border-[1px] max-width-[100%] md:w-[80%] w-[100%] fixed bottom-0 shadow-md bg-[#fff] right-[0px] ">
              <div className="flex justify-between gap-[10px] p-[10px]">
                <button
                  className=" text-black font-semibold rounded-[8px] px-[20px] py-[10px]"
                  onClick={(e) => {
                    navigate(EMPLOYEE);
                  }}
                >
                  Cancel
                </button>

                <button
                  className="bg-[var(--primary)] ml-auto text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                  onClick={(e) => {
                    updateEmployee();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* STEP 2 */}

      {step == 2 && (
        <>
          <div className="mt-[20px]">
            <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
              <div className="content_box">
                <div className="header flex justify-between items-center p-[10px] bg-[#E6F4FF]">
                  <p className="font-semibold m-0 text-[#000]/[0.6]">
                    $10.00/hour{" "}
                  </p>
                  <button
                    className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                    onClick={(e) => {
                      setAddCompensation(true);
                    }}
                  >
                    Change Salary
                  </button>
                </div>
                {addCompensation ? (
                  <div className="body p-[10px]">
                    <div className="grid grid-cols-2 gap-[10px]">
                      <div className="col-span-1">
                        <Input
                          type="text"
                          name={"amount"}
                          label={"Salary Amount"}
                          required
                          placeholder={"Enter Salary Amount"}
                          handleChange={(e: any) => {
                            setCompensationObj((prev: any) => ({
                              ...prev,
                              amount: e.target.value,
                            }));
                          }}
                          value={parseFloat(compensationObj?.amount)?.toFixed(
                            2
                          )}
                          errTxt={formErrors?.emp_code?.msg}
                        />
                      </div>

                      <div className="col-span-1 flex flex-col">
                        <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                          Salary Type <span className="text-[red]">*</span>
                        </label>
                        <Select
                          showSearch
                          className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                          placeholder="Search to Select"
                          options={master?.employment_type}
                          value={compensationObj?.emp_type}
                          onChange={(e) => {
                            setFormErrors({
                              ...formErrors,
                              emp_type: { error: false, msg: `` },
                            });
                            setCompensationObj((prev: any) => ({
                              ...prev,
                              emp_type: e,
                            }));
                          }}
                        />
                        {formErrors?.emp_type?.msg && (
                          <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                            {formErrors?.emp_type?.msg}
                          </label>
                        )}
                      </div>
                      <div className="col-span-1">
                        <Input
                          type="date"
                          name={"effective_date"}
                          label={"Effective Date"}
                          required
                          handleChange={(e: any) =>
                            setCompensationObj((prev: any) => ({
                              ...prev,
                              effective_date: e.target.value,
                            }))
                          }
                          value={compensationObj?.effective_date}
                          errTxt={formErrors?.emp_code?.msg}
                        />
                      </div>
                    </div>

                    <div className="col-span-2 flex justify-center gap-[20px] mt-[30px]">
                      <button
                        className="border-[1px] border-[var(--primary)]  text-[var(--primary)] font-semibold rounded-[8px] px-[20px] py-[10px]"
                        onClick={(e) => {
                          setAddCompensation(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                        onClick={(e) => {
                          createCompensation();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="grid grid-cols-4 mt-[45px]">
            <div className="col-span-4 mb-[20px]">
              <h2 className="text-[22px] font-bold">Salary History</h2>
            </div>
            <div className="col-span-4 grid grid-cols-4 bg-[#000]/[0.1] p-[10px]">
              <div className="col-span-2">
                <p className="font-bold">Amount</p>
              </div>
              <div className="col-span-2">
                <p className="font-bold text-right">Effective Date</p>
              </div>
            </div>

            {compensationData?.map((item: any, index: number) => (
              <div className="col-span-4 grid grid-cols-4 border-b-[1px] py-[20px] px-[15px]">
                <div className="col-span-2">
                  <p className="font-normal">${item?.amount?.toFixed(2)}</p>
                </div>
                <div className="col-span-2">
                  <p className="font-normal text-right">
                    {item?.effective_date?.split("T")[0]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      {step == 3 && (
        <>
          {benefitsState == "none" ? (
            <div className="flex justify-end gap-[10px] my-[30px]">
              <button
                className="bg-[var(--primary)] ml-auto text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                onClick={(e) => {
                  setBenefitsState("add");
                }}
              >
                Add to Pay
              </button>
              <button
                className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                onClick={(e) => {
                  setBenefitsState("deduct");
                }}
              >
                Deduct from Pay
              </button>
            </div>
          ) : null}

          {benefitsState !== "none" ? (
            <div className="my-[20px]">
              <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
                <div className="content_box">
                  <div className="header flex justify-between items-center p-[10px] bg-[#E6F4FF]">
                    <p className="font-semibold m-0 text-[#000]/[0.6]">
                      {benefitsState == "add"
                        ? "Add to Pay"
                        : "Deduct from Pay"}
                    </p>
                  </div>

                  <div className="body p-[10px]">
                    <div className="grid grid-cols-2 gap-[10px]">
                      <div className="col-span-1 flex flex-col">
                        <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                          {benefitsState == "add" ? "Add" : "Deduct"}{" "}
                          <span className="text-[red]">*</span>
                        </label>
                        <Select
                          showSearch
                          className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                          placeholder="Search to Select"
                          options={
                            benefitsState === "add"
                              ? master?.add_benefits_list
                              : master?.deductions_list
                          }
                          value={
                            benefitsState == "add"
                              ? benefitsObj?.add_benefits
                              : benefitsObj?.add_deduct
                          }
                          onChange={(e) => {
                            setFormErrors({
                              ...formErrors,
                              add_benefits: { error: false, msg: `` },
                            });
                            {
                              benefitsState == "add"
                                ? setBenefitsObj((prev: any) => ({
                                    ...prev,
                                    add_benefits: e,
                                  }))
                                : setBenefitsObj((prev: any) => ({
                                    ...prev,
                                    add_deduct: e,
                                  }));
                            }
                          }}
                        />
                        {formErrors?.add_benefits?.msg && (
                          <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                            {formErrors?.add_benefits?.msg}
                          </label>
                        )}
                      </div>
                      <div className="col-span-1">
                        <Input
                          type="text"
                          name={"pay_stub_label"}
                          label={"Pay stub label"}
                          required
                          placeholder={"Enter Pay stub label"}
                          handleChange={(e: any) => {
                            setBenefitsObj((prev: any) => ({
                              ...prev,
                              pay_stub_label: e.target.value,
                            }));
                          }}
                          value={benefitsObj?.pay_stub_label}
                          errTxt={formErrors?.pay_stub_label?.msg}
                        />
                      </div>

                      <div className="col-span-1">
                        <Input
                          type="number"
                          name={"amount"}
                          label={"Amount"}
                          required
                          placeholder={"Enter amount"}
                          handleChange={(e: any) => {
                            setBenefitsObj((prev: any) => ({
                              ...prev,
                              amount: e.target.value,
                            }));
                          }}
                          value={parseFloat(benefitsObj?.amount)?.toFixed(2)}
                          errTxt={formErrors?.pay_stub_label?.msg}
                        />
                      </div>

                      <div className="col-span-1 flex flex-col">
                        <label className="text-[#333333] opacity-70 font-semibold text-[14px]">
                          Occurs <span className="text-[red]">*</span>
                        </label>
                        <Select
                          showSearch
                          className="border-[1px] text-[14px] h-[100%] mt-[5px] rounded-[4px] p-[5px]"
                          placeholder="Search to Select"
                          options={master?.occurs_list}
                          value={benefitsObj?.occurs}
                          onChange={(e) => {
                            setFormErrors({
                              ...formErrors,
                              occurs: { error: false, msg: `` },
                            });
                            setBenefitsObj((prev: any) => ({
                              ...prev,
                              occurs: e,
                            }));
                          }}
                        />
                        {formErrors?.occurs?.msg && (
                          <label className=" mt-[3px] animate-shake-fast font-sfTextSemiBold text-sm text-red-600">
                            {formErrors?.occurs?.msg}
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="col-span-2 flex justify-center gap-[20px] mt-[30px]">
                      <button
                        className="border-[1px] border-[var(--primary)]  text-[var(--primary)] font-semibold rounded-[8px] px-[20px] py-[10px]"
                        onClick={(e) => {
                          setBenefitsState("none");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                        onClick={(e) => {
                          addBenefits();
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {/* <div className="mt-[20px]">
            <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
              <div className="content_box">
                <div className="header p-[10px] bg-[#E6F4FF]">
                  <p className="font-semibold m-0 text-[#000]/[0.6]">
                    $10.00/hour{" "}
                  </p>
                  <button
                    className="bg-[var(--primary)]  text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                    onClick={(e) => {
                      // handleSubmit(e);
                    }}
                  >
                    Chnage Salary
                  </button>
                </div>
                <div className="body p-[10px]"></div>
              </div>
            </div>
          </div> */}
          <div className="grid grid-cols-4">
            <div className="col-span-4 grid grid-cols-4 bg-[#000]/[0.1] p-[10px]">
              <div className="col-span-2">
                <p className="font-bold">Benefits & Deductions</p>
              </div>
              <div className="col-span-1">
                <p className="font-bold">Amount</p>
              </div>
              <div className="col-span-1">
                <p className="font-bold">Frequency</p>
              </div>
            </div>

            {benefitsData?.map((item: any, index: number) => {
              return (
                <div className="col-span-4 grid grid-cols-4 border-b-[1px] py-[20px] px-[15px]">
                  <div className="col-span-2">
                    <p className="font-normal capitalize">
                      {item?.pay_stub_label}
                    </p>
                  </div>
                  <div className="col-span-1">
                    <p className="font-normal">${item?.amount?.toFixed(4)}</p>
                  </div>
                  <div className="col-span-1">
                    <p className="font-normal">{item?.occurs}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      {
        // STEP 4
        step == 4 && (
          <>
            <div className="mt-[20px]">
              <div className="content mt-[20px] border-[#E9EAEC] border-[1px] rounded-[8px] overflow-hidden">
                <div className="content_box">
                  <div className="header p-[10px] bg-[#E6F4FF] border-[1px] ">
                    <p className="font-semibold m-0  text-[#000]/[0.6]">
                      Tax Details
                    </p>
                  </div>
                  <div className="body p-[10px]">
                    <div className="grid grid-cols-2 gap-[10px]">
                      <div className="col-span-1 relative">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"regular_pay"}
                          label={"Regular Pay"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Regular Pay"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.regular_pay)?.toFixed(4)}
                          errTxt={formErrors?.regular_pay?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"overtime_pay"}
                          label={"Overtime Pay"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Overtime Pay"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.overtime_pay)?.toFixed(4)}
                          errTxt={formErrors?.overtime_pay?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"doubletime_pay"}
                          label={"Doubletime Pay"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Doubletime Pay"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.doubletime_pay)?.toFixed(
                            2
                          )}
                          errTxt={formErrors?.doubletime_pay?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"vacation_pay"}
                          label={"Vacation Pay"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Vacation Pay"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.vacation_pay)?.toFixed(4)}
                          errTxt={formErrors?.vacation_pay?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"sictime_pay"}
                          label={"Sicktime Pay"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Sicktime Pay"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.sictime_pay)?.toFixed(4)}
                          errTxt={formErrors?.sictime_pay?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"taxable_pay"}
                          label={"Taxable Pay"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Taxable Pay"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.taxable_pay)?.toFixed(4)}
                          errTxt={formErrors?.taxable_pay?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"fedral_income_tax"}
                          label={"Fedral Income Tax"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Fedral Income Tax"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(
                            taxData?.fedral_income_tax
                          )?.toFixed(2)}
                          errTxt={formErrors?.fedral_income_tax?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"medicare"}
                          label={"Medicare"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Medicare"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.medicare)?.toFixed(4)}
                          errTxt={formErrors?.medicare?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"state_tax"}
                          label={"State Tax"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter State Tax"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.state_tax)?.toFixed(4)}
                          errTxt={formErrors?.state_tax?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"city_tax"}
                          label={"City Tax"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter City Tax"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.city_tax)?.toFixed(4)}
                          errTxt={formErrors?.city_tax?.msg}
                        />
                      </div>
                      {/* <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"comp_city_tax"}
                          label={"Comp City Tax"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Comp City Tax"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.comp_city_tax)?.toFixed(4)}
                          errTxt={formErrors?.comp_city_tax?.msg}
                        />
                      </div> */}
                      <div className="col-sapn-1 realtive">
                        <p className="absolute left-[5px] top-[52%] text-[#000]/[0.5]">
                          $
                        </p>
                        <Input
                          type="number"
                          name={"social_security"}
                          label={"Social Security"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Social Security"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.social_security)?.toFixed(
                            4
                          )}
                          errTxt={formErrors?.social_security?.msg}
                        />
                      </div>
                      <div className="col-sapn-1 realtive">
                        <Input
                          type="number"
                          name={"non_taxable_pay"}
                          label={"Non Taxable Pay"}
                          required
                          className={"pl-[20px]"}
                          placeholder={"Enter Non Taxable Pay"}
                          handleChange={handleChangeTaxAmount}
                          value={parseFloat(taxData?.non_taxable_pay)?.toFixed(
                            4
                          )}
                          errTxt={formErrors?.non_taxable_pay?.msg}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-[200px]"></div>
            <div className="relative">
              <div className="footer border-[1px] max-width-[100%] md:w-[80%] w-[100%] fixed bottom-0 shadow-md bg-[#fff] right-[0px] ">
                <div className="flex justify-between gap-[10px] p-[10px]">
                  <button
                    className=" text-black font-semibold rounded-[8px] px-[20px] py-[10px]"
                    onClick={(e) => {
                      navigate(EMPLOYEE);
                    }}
                  >
                    Cancel
                  </button>

                  <button
                    className="bg-[var(--primary)] ml-auto text-[#fff] font-semibold rounded-[8px] px-[20px] py-[10px]"
                    onClick={(e) => {
                      updateEmployee();
                    }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </>
        )
      }
    </div>
  );
};

export default EditEmployee;
