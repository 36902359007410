import React, { useEffect, useState } from 'react'
import { useLocation, useOutletContext } from 'react-router-dom';
import Loader from '../../../components/Loader';
import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import { DashboardAPI } from '../../../apis/dashboardApi';
import { BASE_URL } from '../../../constants/apiConstant';

interface Payroll {
    pay_day: string;
    regular: number;
    overtime: number;
    doubletime: number;
    vacation: number;
    payroll_period: string;
}
type Props = {
    company: any
}

function WagesTaxEmplist({ company }: Props) {
    const [loading, setLoading] = useState<boolean>(false);
    const { selectedCompany } = useOutletContext<any>();
    const location = useLocation();
    const { state, startDate, endDate } = location.state;
    console.log(state);
    const [data, setData] = useState<any>({ start_date: startDate, end_date: endDate });
    const result = state?.result;
    console.log(result);
    const employeeEntries = Object.entries(result || {});
    const employeeCount = employeeEntries.length;

    const [empData, setEmpData] = useState<any>(null);
    const [showEmployeeDetails, setShowEmployeeDetails] = useState<boolean>(false);
    const [showSummary, setShowSummary] = useState<boolean>(true);



    const postWagesTaxEmpSummary = async () => {
        try {
            setLoading(true);
            const res = await DashboardAPI?.postWagesTaxEmpSummary(selectedCompany, {
                start_date: startDate,
                end_date: endDate,
            });
            console.log(res);
            if (res.success) {
                setData(res?.data);
            }
        } catch (err: any) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const postWagesTaxEmpData = async (employeeId: number) => {
        try {
            setLoading(true);
            const res = await DashboardAPI?.postWagesTaxEmpData(selectedCompany, {
                emp_id: employeeId,
                start_date: startDate,
                end_date: endDate,
            });
            console.log(res);
            if (res.success) {
                setEmpData(res?.data);
                setShowEmployeeDetails(true);
                setShowSummary(false);
            }
        } catch (err: any) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (startDate && endDate && selectedCompany) {
            postWagesTaxEmpSummary();
        }
    }, [startDate, endDate, selectedCompany]);

    const payrollsData = empData?.emp_data?.payrolls as { [key: string]: Payroll };

    const payrollElements = payrollsData ? Object.values(payrollsData).map((payroll, index) => (
        <div key={index} className='grid gap-6 grid-cols-5 text-[black] border-b pb-2'>
            <div className='text-[14px] font-medium text-[var(--primary)]'>{payroll.pay_day} <br />
                <span>Pay Period: <br /> {payroll.payroll_period}</span>
            </div>
            <div>{payroll.regular}</div>
            <div>{payroll.overtime}</div>
            <div>{payroll.doubletime}</div>
            <div>{payroll.vacation}</div>
        </div>
    )) : null;

    const handleDownloadPDF = async () => {

        try {
            const response = await fetch(BASE_URL + `payroll/wages_tax_report_pdf/${selectedCompany}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    start_date: startDate,
                    end_date: endDate
                })
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            window.open(url, '_blank');
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleShowSummary = () => {
        setShowSummary(true);
        setShowEmployeeDetails(false);
    };


    return (
        <div className='mx-auto container p-[15px] mb-[60px]'>
            <ToastContainer />
            {loading && <Loader />}
            <div className='font-bold text-[18px] text-[var(--secondary)]'>
                Wage & Tax Report
            </div>
            <div className='flex mt-[32px] border-b pb-4 items-center justify-between'>
                <div className={`text-[var(--secondary)] text-[16px] font-medium cursor-pointer py-[7px] px-[20px] transition duration-300 ease-in-out hover:text-black hover:bg-[#e6f4ff] rounded-[4px] ${showSummary ? 'bg-[#e6f4ff] text-black' : ''}`}
                    onClick={handleShowSummary}
                >
                    Employee Summary
                </div>
                <div>
                    <p className='text-[var(--secondary)] text-[14px] font-medium'>For all paydays during</p>
                    <p className='text-[black] text-[14px] font-medium'>
                        {data?.wages_data?.from_payday}
                        <span className='px-[3px]'>to</span>
                        {data?.wages_data?.to_payday}
                    </p>
                </div>
                <div>
                    <p className='text-[var(--secondary)] text-[14px] font-medium'>Federal Employer ID Number (EIN)</p>
                    <p className='text-[black] text-[14px] font-medium'>12-3456789</p>
                </div>
                <div>
                    <button className='rounded-[4px] text-[14px] border-[1px] border-[var(--primary)] text-[var(--primary)] py-[7px] px-[20px] transition duration-300 ease-in-out hover:bg-[var(--primary)] hover:text-white'
                        onClick={handleDownloadPDF}
                    >
                        Download PDF
                    </button>
                </div>
            </div>
            {/* emplyee summaryyy */}
            {showSummary ? (
                <div className='grid grid-cols-10 border mt-4 shadow-sm rounded-[6px]'>
                    <div className='col-span-2 border-r px-4 pt-2'>
                        <div>
                            <p className='text-[black] text-[16px] font-medium'>Employee ({employeeCount})</p>
                        </div>
                        <div className={`text-[var(--primary)] mt-2 text-[16px] font-medium cursor-pointer`}>
                            <ul>
                                {employeeEntries.map(([employeeId, employeeName]) => (
                                    <li
                                        onClick={() => postWagesTaxEmpData(parseInt(employeeId))}
                                        className='cursor-pointer mb-[4px]' key={employeeId}
                                    >
                                        {typeof employeeName === 'string' ? employeeName : 'Unknown'}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='col-span-8 px-2 pt-2'>
                        <div className='grid gap-4 grid-cols-6 text-[var(--secondary)] border-b pb-2'>
                            <div></div>
                            <div>Wages</div>
                            <div>Taxed Wages</div>
                            <div>Employee Amount</div>
                            <div>Employer Amount</div>
                            <div>Tax Liability</div>
                        </div>
                        <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                            <div>Federal Income Tax</div>
                            <div>${data?.wages_data?.fedral_income_summ_wages}</div>
                            <div>${data?.wages_data?.fedral_income_summ_taxed}</div>
                            <div>${data?.wages_data?.fedral_income_summ_emplyee}</div>
                            <div>${data?.wages_data?.fedral_income_summ_emplyer}</div>
                            <div>${data?.wages_data?.fedral_income_summ_liab}</div>
                        </div>
                        <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                            <div>Federal Unemployment Tax</div>
                            <div>${data?.wages_data?.fedral_unemp_summ_wages}</div>
                            <div>${data?.wages_data?.fedral_unemp_summ_taxed}</div>
                            <div>${data?.wages_data?.fedral_unemp_summ_emplyee}</div>
                            <div>${data?.wages_data?.fedral_unemp_summ_emplyer}</div>
                            <div>${data?.wages_data?.fedral_unemp_summ_liab}</div>
                        </div>
                        <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                            <div>Medicare</div>
                            <div>${data?.wages_data?.medicare_summ_wages}</div>
                            <div>${data?.wages_data?.medicare_summ_taxed}</div>
                            <div>${data?.wages_data?.medicare_summ_emplyee}</div>
                            <div>${data?.wages_data?.medicare_summ_emplyer}	</div>
                            <div>${data?.wages_data?.medicare_summ_liab}</div>
                        </div>
                        <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                            <div>State Tax</div>
                            <div>${data?.wages_data?.state_income_summ_wages}</div>
                            <div>${data?.wages_data?.state_income_summ_taxed}</div>
                            <div>{data?.wages_data?.state_income_summ_emplyee.toFixed(4)}</div>
                            <div>${data?.wages_data?.state_income_summ_emplyer}</div>
                            <div>${data?.wages_data?.state_income_summ_liab}</div>
                        </div>
                        <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                            <div>State Unemployment Tax</div>
                            <div>${data?.wages_data?.state_unemp_summ_wages}</div>
                            <div>${data?.wages_data?.state_unemp_summ_taxed}</div>
                            <div>${data?.wages_data?.state_unemp_summ_emplyee}</div>
                            <div>${data?.wages_data?.state_unemp_summ_emplyer}</div>
                            <div>${data?.wages_data?.state_unemp_summ_liab}</div>
                        </div>
                        <div className='grid gap-4 grid-cols-6 mt-2 pb-2'>
                            <div>Social Security</div>
                            <div>${data?.wages_data?.social_summ_wages}</div>
                            <div>${data?.wages_data?.social_summ_taxed}</div>
                            <div>${data?.wages_data?.social_summ_emplyee}</div>
                            <div>${data?.wages_data?.social_summ_emplyer}</div>
                            <div>${data?.wages_data?.social_summ_liab}</div>
                        </div>
                    </div>
                </div>
            ) : null}
            {/* each emplyee data */}
            {showEmployeeDetails ? (
                <div>
                    <div className='items-center mt-2 grid grid-cols-10'>
                        <div className='col-span-2'></div>
                        <div className='col-span-8 flex justify-between px-[4px]'>
                            <div className='text-[var(--secondary)] text-[16px] font-medium'>Name <br /><span className='text-[16px] text-[var(--primary)] font-semibold'>{empData.emp_data.name}</span></div>
                            <div className='text-[var(--secondary)] text-[16px] font-medium'>SSN <br /><span className='text-[16px] text-[var(--primary)] font-semibold'>365-35-7037</span></div>
                        </div>
                    </div>
                    <div className='grid grid-cols-10 my-4 border shadow-sm rounded-[6px]'>
                        <div className='col-span-2 border-r px-4 pt-2'>
                            <div>
                                {/* <div onClick={showSummary} className='text-[var(--secondary)] text-[16px] font-medium'>Employee Summay</div> */}
                                <p className='text-[black] text-[16px] font-medium'>Employee ({employeeCount})</p>
                            </div>
                            <div className={`text-[var(--primary)] mt-2 text-[16px] font-medium cursor-pointer`}>
                                <ul>
                                    {employeeEntries.map(([employeeId, employeeName]) => (
                                        <li
                                            onClick={() => postWagesTaxEmpData(parseInt(employeeId))}
                                            className='cursor-pointer mb-[4px]' key={employeeId}
                                        >
                                            {typeof employeeName === 'string' ? employeeName : 'Unknown'}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className='col-span-8 px-2 pt-2'>
                            <div className='grid gap-4 grid-cols-6 text-[var(--secondary)] border-b pb-2'>
                                <div></div>
                                <div>Wages</div>
                                <div>Taxed Wages</div>
                                <div>Employee Amount</div>
                                <div>Employer Amount</div>
                                <div>Tax Liability</div>
                            </div>
                            <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                                <div>Federal Income Tax</div>
                                <div>${empData?.emp_data?.fedral_income_wages}</div>
                                <div>${empData?.emp_data?.fedral_income_taxed}</div>
                                <div>${empData?.emp_data?.fedral_income_emplyee}</div>
                                <div>${empData?.emp_data?.fedral_income_emplyr}</div>
                                <div>${empData?.emp_data?.fedral_income_liab}</div>

                            </div>
                            <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                                <div>Federal Unemployment Tax</div>
                                <div>${empData?.emp_data?.fedral_unemployment_wages}</div>
                                <div>${empData?.emp_data?.fedral_unemployment_taxed}</div>
                                <div>${empData?.emp_data?.fedral_unemployment_emplyee}</div>
                                <div>${empData?.emp_data?.fedral_unemployment_emplyr}</div>
                                <div>${empData?.emp_data?.fedral_unemployment_liab}</div>
                            </div>
                            <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                                <div>Medicare</div>
                                <div>${empData?.emp_data?.medicare_wages}</div>
                                <div>${empData?.emp_data?.medicare_taxed}</div>
                                <div>${empData?.emp_data?.medicare_emplyee}</div>
                                <div>${empData?.emp_data?.medicare_emplyr}</div>
                                <div>${empData?.emp_data?.medicare_liab}</div>
                            </div>
                            <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                                <div>State Tax</div>
                                <div>${empData?.emp_data?.state_tax_wages}</div>
                                <div>${empData?.emp_data?.state_tax_taxed}</div>
                                <div>${empData?.emp_data?.state_tax_emplyee}</div>
                                <div>${empData?.emp_data?.state_tax_emplyr}</div>
                                <div>${empData?.emp_data?.state_tax_liab}</div>
                            </div>
                            <div className='grid gap-4 grid-cols-6 mt-2 border-b pb-2'>
                                <div>State Unemployment Tax</div>
                                <div>${empData?.emp_data?.state_unemployment_wages}</div>
                                <div>${empData?.emp_data?.state_unemployment_taxed}</div>
                                <div>${empData?.emp_data?.state_unemployment_emplyee}</div>
                                <div>${empData?.emp_data?.state_unemployment_emplyr}</div>
                                <div>${empData?.emp_data?.state_unemployment_liab}</div>
                            </div>
                            <div className='grid gap-4 grid-cols-6 mt-2 pb-2'>
                                <div>Social Security</div>
                                <div>${empData?.emp_data?.social_security_wages}</div>
                                <div>${empData?.emp_data?.social_security_taxed}</div>
                                <div>${empData?.emp_data?.social_security_emplyee}</div>
                                <div>${empData?.emp_data?.social_security_emplyr}</div>
                                <div>${empData?.emp_data?.social_security_liab}</div>
                            </div>
                        </div>
                    </div>
                    <div className='border mt-[32px] shadow-sm rounded-[6px]'>
                        <div className='p-2 grid grid-cols-10 border-b'>
                            {/* <div className='col-span-2'></div> */}
                            <div className='col-span-10'>
                                <div className='grid gap-4 grid-cols-5 text-[var(--secondary)] '>
                                    <div>Payday	</div>
                                    <div>Regular</div>
                                    <div>Overtime</div>
                                    <div>Double Time</div>
                                    <div>Vacation</div>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            {payrollElements}
                        </div>
                        <div className='grid grid-cols-10'>
                            <div className='col-span-10'>
                                <div className='grid gap-4 grid-cols-5 p-2'>
                                    <div className='font-bold text-[var(--secondary)]'>Total Hours</div>
                                    <div>{empData?.emp_data?.total_regular}</div>
                                    <div>{empData?.emp_data?.total_overtime}</div>
                                    <div>{empData?.emp_data?.total_doubletime}</div>
                                    <div>{empData?.emp_data?.total_vacation}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default WagesTaxEmplist